/* shadow */
/* sizing */
/* spacing */
.semi-input-number {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
}
.semi-input-number-suffix-btns {
  display: inline-flex;
  flex-direction: column;
  margin-left: 4px;
  border: 1px solid var(--semi-color-border);
  border-radius: var(--semi-border-radius-small);
  background-color: var(--semi-color-bg-2);
  box-sizing: border-box;
}
.semi-input-number-suffix-btns > .semi-input-number-button {
  height: 50%;
  width: 14px;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: 0;
  color: var(--semi-color-text-2);
}
.semi-input-number-suffix-btns > .semi-input-number-button-up:not(.semi-input-number-button-up-not-allowed):hover, .semi-input-number-suffix-btns > .semi-input-number-button-down:not(.semi-input-number-button-down-not-allowed):hover {
  cursor: pointer;
  background-color: var(--semi-color-fill-0);
}
.semi-input-number-suffix-btns > .semi-input-number-button-up:not(.semi-input-number-button-up-not-allowed):active, .semi-input-number-suffix-btns > .semi-input-number-button-down:not(.semi-input-number-button-down-not-allowed):active {
  cursor: pointer;
  background-color: var(--semi-color-fill-1);
}
.semi-input-number-suffix-btns > .semi-input-number-button-up.semi-input-number-button-up-disabled, .semi-input-number-suffix-btns > .semi-input-number-button-down.semi-input-number-button-down-disabled {
  background-color: var(--semi-color-disabled-fill);
  color: var(--semi-color-disabled-text);
}
.semi-input-number-suffix-btns > .semi-input-number-button-up.semi-input-number-button-up-not-allowed, .semi-input-number-suffix-btns > .semi-input-number-button-down.semi-input-number-button-down-not-allowed {
  cursor: not-allowed;
}
.semi-input-number-suffix-btns-inner-hover {
  border-color: var(--semi-color-fill-2);
}
.semi-input-number-suffix-btns-inner {
  margin-left: 8px;
}
.semi-input-number .semi-input-clearbtn + .semi-input-suffix {
  margin-left: -4px;
}
.semi-input-number .semi-input-clearbtn + .semi-input-suffix .semi-input-number-suffix-btns-inner {
  margin-left: 0;
}
.semi-input-number-size-default .semi-input-number-suffix-btns {
  height: 32px;
}
.semi-input-number-size-default .semi-input-number-suffix-btns-inner {
  height: 30px;
}
.semi-input-number-size-large .semi-input-number-suffix-btns {
  height: 40px;
}
.semi-input-number-size-large .semi-input-number-suffix-btns-inner {
  height: 38px;
}
.semi-input-number-size-small .semi-input-number-suffix-btns {
  height: 24px;
}
.semi-input-number-size-small .semi-input-number-suffix-btns-inner {
  height: 22px;
}

.semi-rtl .semi-input-number,
.semi-portal-rtl .semi-input-number {
  direction: rtl;
}
.semi-rtl .semi-input-number-suffix-btns,
.semi-portal-rtl .semi-input-number-suffix-btns {
  margin-left: auto;
  margin-right: 4px;
}
.semi-rtl .semi-input-number-suffix-btns-inner,
.semi-portal-rtl .semi-input-number-suffix-btns-inner {
  margin-left: auto;
  margin-right: 8px;
}
.semi-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix,
.semi-portal-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix {
  margin-left: auto;
  margin-right: -4px;
}
.semi-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix .semi-input-number-suffix-btns-inner,
.semi-portal-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix .semi-input-number-suffix-btns-inner {
  margin-left: auto;
  margin-right: 0;
}