/* shadow */
/* sizing */
/* spacing */
.semi-page {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
}
.semi-page-small {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: var(--semi-color-text-2);
  padding: 0 0;
}
.semi-page-item {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-width: 32px;
  border: 0px solid transparent;
  cursor: pointer;
  user-select: none;
  height: 32px;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 400;
  color: var(--semi-color-text-0);
  border-radius: var(--semi-border-radius-small);
  text-align: center;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.semi-page-item:hover {
  border-color: transparent;
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-0);
}
.semi-page-item-rest-opening {
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-0);
}
.semi-page-item:active {
  border-color: transparent;
  background-color: var(--semi-color-fill-1);
  color: var(--semi-color-text-0);
}
.semi-page-item-active {
  border-color: transparent;
  color: var(--semi-color-primary);
  font-weight: 600;
  background-color: var(--semi-color-primary-light-default);
}
.semi-page-item-active:hover {
  border-color: transparent;
  color: var(--semi-color-primary);
  background-color: var(--semi-color-primary-light-default);
}
.semi-page-item-disabled {
  border-color: transparent;
  color: var(--semi-color-disabled-text);
  background-color: transparent;
  cursor: not-allowed;
}
.semi-page-item-disabled:hover {
  background-color: transparent;
}
.semi-page-item-small {
  min-width: 44px;
  margin: 0;
}
.semi-page-total {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
}
.semi-page-prev, .semi-page-next {
  color: var(--semi-color-tertiary);
  cursor: pointer;
}
.semi-page-prev.semi-page-item-disabled, .semi-page-next.semi-page-item-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-page-quickjump {
  margin-left: 24px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--semi-color-text-0);
}
.semi-page-quickjump-input-number {
  max-width: 50px;
  margin-left: 4px;
  margin-right: 4px;
}
.semi-page-quickjump-disabled {
  color: var(--semi-color-disabled-text);
}
.semi-page .semi-select {
  user-select: none;
}

.semi-select-dropdown {
  user-select: none;
}

.semi-page-rest-list {
  padding-top: 4px;
  padding-bottom: 4px;
}
.semi-page-rest-list > div {
  position: relative;
}
.semi-page-rest-item {
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}
.semi-page-rest-item:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-page-rest-item:active {
  background-color: var(--semi-color-fill-1);
}

.semi-rtl .semi-page,
.semi-portal-rtl .semi-page {
  direction: rtl;
}
.semi-rtl .semi-page-item,
.semi-portal-rtl .semi-page-item {
  margin-right: 4px;
  margin-left: 4px;
}
.semi-rtl .semi-page-prev, .semi-rtl .semi-page-next,
.semi-portal-rtl .semi-page-prev,
.semi-portal-rtl .semi-page-next {
  transform: scaleX(-1);
}