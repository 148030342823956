html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

a, a:hover {
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

@font-face {
  font-family: HKGroteskBold;
  src: url("HKGrotesk-Bold.f9a9983e.otf");
}

@font-face {
  font-family: HKGroteskRegular;
  src: url("HKGrotesk-Regular.ca019046.otf");
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1eef7;
  margin: 0;
  font-family: HKGroteskRegular, -apple-system, Roboto;
  height: auto !important;
}

a {
  color: #16042f;
}

.text-center {
  text-align: center;
}

.container {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 1rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  background-color: #05193d;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  background-color: #05183c;
  border-radius: 0;
}

.pageWrap {
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: .2px;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-style: normal;
}

.box-title {
  text-align: center;
  color: #16042f;
  margin-bottom: 1rem;
  font-family: HKGroteskBold;
  font-size: 1.875rem;
  font-weight: bold;
}

.ic-btn {
  -webkit-tap-highlight-color: transparent;
  color: #16042f;
  width: 300px;
  height: 60px;
  border-radius: 40px;
  padding: 1px;
  font-size: 20px;
  font-weight: bold;
  transition: all .3s;
  display: inline-block;
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

.ic-btn:active {
  background-image: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
}

.ic-btn-inner {
  width: 100%;
  height: 100%;
  background-color: #fcfbfe;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.mask {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: #00000080;
  transition: all .3s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.mask.show {
  display: block;
}

.nav-item-link {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
  background-clip: text;
  margin-right: 2rem;
  transition: all .3s;
}

@media screen and (min-width: 769px) {
  .box-title {
    text-align: left;
    font-size: 3.75rem;
  }

  .ic-btn {
    height: 80px;
  }

  .ic-btn:hover {
    background-image: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
  }
}

.Vq92cG_footer {
  width: 100%;
  background: #f8f6fe;
  padding: 3rem 0;
}

.Vq92cG_social-media {
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Vq92cG_social-media .Vq92cG_icon {
  width: 2rem;
  height: 2rem;
  color: #16042f;
  background-color: #fff;
  background-repeat: no-repeat;
  border-radius: 100%;
  margin: 0 .5rem;
  padding: .45rem;
  font-size: 0;
  transition: color .3s;
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

.Vq92cG_social-media .Vq92cG_icon:hover {
  color: #820ff0;
}

._1sNSbG_header {
  height: 3rem;
  z-index: 9;
  background: #fff;
  position: sticky;
  top: 0;
}

._1sNSbG_header ._1sNSbG_container {
  max-width: 1260px;
  height: 100%;
  margin: auto;
}

._1sNSbG_navbar {
  height: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  display: flex;
  position: relative;
  flex-wrap: nowrap !important;
}

._1sNSbG_navbar ._1sNSbG_navbar-toggler {
  color: #00cfbb;
  box-shadow: none;
  width: 32px;
  height: 32px;
  border: 1px solid #00cfbb;
  border-radius: 8px;
  outline: none;
  padding: 0;
  font-size: 1rem;
}

._1sNSbG_header-logo {
  height: 2rem;
  width: 100%;
  background: url("logo.93657b5a.svg") center / contain no-repeat;
  margin-left: -2rem;
  font-size: 0;
}

._1sNSbG_go-dapp {
  -webkit-tap-highlight-color: transparent;
  color: #16042f;
  width: auto;
  height: auto;
  color: #0044d4;
  border-radius: 40px;
  padding: 1px;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
  display: inline-block;
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

._1sNSbG_go-dapp:active {
  background-image: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
}

._1sNSbG_go-dapp-inner {
  width: 100%;
  height: 100%;
  background-color: #fcfbfe;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 8px .75rem;
  display: block;
}

._1sNSbG_btn-menu, ._1sNSbG_btn-close {
  z-index: 99;
  width: 2rem;
  height: 2rem;
  background: url("icon-menu.717413c0.svg") center no-repeat;
  border: none;
  padding: 0;
  position: relative;
}

._1sNSbG_btn-close {
  background: url("icon-close.5a51972d.svg") center no-repeat;
}

._1sNSbG_mobile-menu {
  width: 80vw;
  height: 100vh;
  z-index: 999;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-105%);
}

._1sNSbG_mobile-menu ._1sNSbG_mobile-menu-main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

._1sNSbG_mobile-menu._1sNSbG_show {
  transform: translateX(0);
}

._1sNSbG_mobile-menu-main-head {
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 0;
  display: flex;
}

._1sNSbG_mobile-menu-main-head ._1sNSbG_header-logo {
  width: 8rem;
  margin: 0;
}

._1sNSbG_navbar-collapse {
  display: none;
}

._1sNSbG_navbar-collapse ._1sNSbG_nav-item-link {
  background-clip: text;
  margin-right: 2rem;
  transition: all .3s;
}

._1sNSbG_navbar-collapse ._1sNSbG_nav-item-link:hover {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
  -webkit-background-clip: text;
}

._1sNSbG_mobile-menu-main-body ._1sNSbG_navbar-collapse {
  flex-direction: column;
  padding: 3rem 0 0 10px;
  display: flex;
}

._1sNSbG_mobile-menu-main-body ._1sNSbG_navbar-collapse li {
  margin-bottom: .5rem;
  padding: 1px 0 1px 1px;
}

._1sNSbG_mobile-menu-main-body ._1sNSbG_navbar-collapse li ._1sNSbG_nav-item-link {
  color: #16042f;
  height: 3.75rem;
  margin: 0;
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 3.75rem;
  transition: none;
  display: block;
}

._1sNSbG_mobile-menu-main-body ._1sNSbG_navbar-collapse li ._1sNSbG_nav-item-link:hover {
  color: #7300e6;
  background: linear-gradient(270deg, #7300e600 0%, #7300e61a 100%);
  background-clip: initial;
  -webkit-text-fill-color: #7300e6;
  border-radius: 5rem 0 0 8px;
}

._1sNSbG_mobile-menu-main-body ._1sNSbG_navbar-collapse li:last-child {
  text-align: center;
  background: none;
  border: none;
  padding-top: 2rem;
}

._1sNSbG_mobile-menu-main-body ._1sNSbG_navbar-collapse ._1sNSbG_go-dapp {
  height: 48px;
  color: #820ff0;
  margin: 0 auto;
  padding: 0;
  line-height: 47px;
  box-shadow: 4px 4px 12px #c7bddb, -4px -4px 12px #fff;
}

._1sNSbG_mobile-menu-main-body ._1sNSbG_navbar-collapse ._1sNSbG_go-dapp ._1sNSbG_go-dapp-inner {
  padding: 0 2rem;
}

@media (min-width: 576px) {
  ._1sNSbG_navbar {
    justify-content: space-between;
    padding: 0 1rem;
  }

  ._1sNSbG_header {
    height: 80px;
    background: none;
    transition: all .3s;
  }

  ._1sNSbG_header._1sNSbG_has-bg {
    backdrop-filter: blur(8px);
    background: #fff9;
  }

  ._1sNSbG_header-logo {
    width: 180px;
    height: 40%;
    background: url("logo.93657b5a.svg") 0 / contain no-repeat;
    margin: 0;
  }

  ._1sNSbG_navbar-collapse {
    width: auto;
    height: auto;
    background-color: #0000;
    align-items: center;
    padding: 0;
    display: flex;
    position: static;
    transform: none;
  }

  ._1sNSbG_btn-menu, ._1sNSbG_mobile-menu {
    display: none;
  }
}

@media screen and (min-width: 789px) {
  ._1sNSbG_navbar-collapse ._1sNSbG_nav-item-link {
    margin-right: 4rem;
  }

  ._1sNSbG_go-dapp:hover {
    background-image: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
  }
}

.homepage_272b7c {
  height: 100%;
}

.jump_272b7c {
  height: auto;
  background: url("banner.d0067f54.webp") bottom / contain no-repeat;
  padding-top: 3.75rem;
  padding-bottom: 25rem;
  padding-left: 1.875rem;
}

.jump_272b7c h1 {
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.875rem;
}

.jump_272b7c h1:after {
  content: "";
  width: 125px;
  height: 2px;
  background: linear-gradient(270deg, #22eef900 0%, #820ff0 100%);
  margin-top: .675rem;
  display: block;
}

.jump_272b7c .jump-desc_272b7c dt {
  color: #16042f;
  margin-bottom: .5rem;
  font-family: HKGroteskBold;
  font-weight: bold;
}

.jump_272b7c .jump-desc_272b7c dd {
  color: #585063;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
}

.jump_272b7c .jump-desc_272b7c dd:before {
  content: "";
  width: 2px;
  height: 10px;
  background: #820ff0;
  margin-right: 4px;
  display: inline-block;
}

.bg-box_272b7c {
  background: #f8f6fe;
  padding: 2.5rem 0 3.75rem;
}

.bg-box_272b7c .browsers-row_272b7c {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bg-box_272b7c .browsers-row_272b7c .link_272b7c {
  width: 180px;
  height: 180px;
  color: #16042f;
  -webkit-tap-highlight-color: transparent;
  background: #f8f6fe;
  border: 1px solid #eee;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-family: HKGroteskBold;
  font-weight: bold;
  transition: all .3s;
  display: inline-flex;
}

.bg-box_272b7c .browsers-row_272b7c .link_272b7c:active {
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

.bg-box_272b7c .browsers-row_272b7c .link_272b7c:last-child {
  margin-bottom: 2.5rem;
}

.browsers_272b7c .icon_272b7c {
  width: 4.375rem;
  height: 4.375rem;
  background: url("css_sprites.0658d9d2.png") 0 0 / 240px 182.5px no-repeat;
  margin-bottom: 12px;
  display: block;
}

.browsers_272b7c .icon_272b7c.icon-chrome_272b7c {
  background-position: -85px -5px;
}

.browsers_272b7c .icon_272b7c.icon-firefox_272b7c {
  height: 4.5rem;
  background-position: -5px -5px;
}

.browsers_272b7c .icon_272b7c.icon-edge_272b7c {
  background-position: -165px -5px;
}

.browsers_272b7c .extension_272b7c {
  align-items: center;
  display: flex;
}

.browsers_272b7c .extension_272b7c .icon-chrome_272b7c {
  width: 2.1875rem;
  height: 2.1875rem;
  background-position: -42.5px -2.5px;
  background-size: 120px 91.25px;
  margin-bottom: 0;
  margin-right: .5rem;
}

.apps_272b7c {
  padding: 5rem 0 2.5rem;
}

.apps-header_272b7c {
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.875rem;
  display: flex;
}

.apps-header-left_272b7c {
  margin-bottom: 1.875rem;
}

.apps-header-btn-group_272b7c {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.apps-header-btn-group_272b7c > a {
  height: 2.5rem;
  width: 48%;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  font-size: .75rem;
  font-weight: normal;
}

.apps-header-btn-group_272b7c .ic-btn-inner {
  align-items: center;
  display: flex;
}

.icon-circle_272b7c {
  height: 12px;
  width: 12px;
  margin-right: .5rem;
  display: block;
  position: relative;
}

.icon-circle_272b7c:before, .icon-circle_272b7c:after, .icon-circle_272b7c b, .icon-circle_272b7c b:after {
  content: "";
  box-sizing: border-box;
  width: 4.5px;
  height: 4.5px;
  border: 1px solid #16042f;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.icon-circle_272b7c:after {
  right: 0;
}

.icon-circle_272b7c b {
  bottom: 0;
  left: 0;
}

.icon-circle_272b7c b:after {
  top: -1px;
  left: 6.5px;
}

.icon-plus_272b7c {
  height: 14px;
  width: 14px;
  border: 1px solid #16042f;
  border-radius: 4px;
  margin-right: .5rem;
  display: block;
  position: relative;
  transform: rotate(45deg);
}

.icon-plus_272b7c:before, .icon-plus_272b7c:after {
  content: "";
  height: 10px;
  width: 1px;
  background: #16042f;
  display: block;
  position: absolute;
  top: 1px;
  left: 5px;
  transform: rotate(45deg);
}

.icon-plus_272b7c:after {
  transform: rotate(135deg);
}

.app-list_272b7c {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.app-list_272b7c .app-list-item_272b7c {
  width: 48%;
  min-height: 7.8rem;
  color: #585063;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background: #f9f8fc;
  border-radius: .75rem;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 12px;
  transition: all .3s;
}

.app-list_272b7c .app-list-item_272b7c:active {
  background: #fcfbfe;
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

.app-list_272b7c .app-list-item_272b7c dt {
  margin-bottom: .5rem;
  font-family: HKGroteskBold;
  font-size: 15px;
  font-weight: bold;
}

.app-list_272b7c .app-list-item_272b7c .icon_272b7c {
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("css_sprites.0658d9d2.png");
  background-repeat: no-repeat;
  background-size: 240px 182.5px;
  margin-bottom: .75rem;
  display: block;
}

.app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-astrox_272b7c {
  background-position: -5px -87.5px;
}

.app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-stoic_272b7c {
  background-position: -55px -137.5px;
}

.app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-icpbox_272b7c {
  background-position: -105px -87.5px;
}

.app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-icpswap_272b7c {
  background-position: -55px -87.5px;
}

.app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-plug_272b7c {
  background-position: -5px -137.5px;
}

.app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-infinity_272b7c {
  background-image: url("infinity.8e5320fc.svg");
  background-size: 80%;
  background-position: center !important;
}

.app-list_272b7c .app-list-item_272b7c .icon-mora_272b7c {
  background-image: url("mora.9e577095.png");
  background-position: center;
  background-size: 80% 80% !important;
}

.app-list_272b7c .app-list-item_272b7c .icon-relation_272b7c {
  background-image: url("relation.ab266609.png");
  background-position: center;
  background-size: 80% 80% !important;
}

.app-list_272b7c .app-list-item_272b7c .icon-yumi_272b7c {
  background-image: url("yumi.1fd3a820.png");
  background-position: center;
  background-size: 80% 80% !important;
}

.faq-tutorials_272b7c .semi-tabs-bar-line.semi-tabs-bar-top {
  border: none;
  margin-bottom: 2.5rem;
}

.faq-tutorials_272b7c .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab {
  color: #585063;
  outline: none;
  font-size: 1.5rem;
  font-weight: 600;
}

.faq-tutorials_272b7c .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:hover {
  border: none;
}

.faq-tutorials_272b7c .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab.semi-tabs-tab-active {
  color: #16042f;
  border-bottom: 2px solid #820ff0;
}

.faq-tutorials_272b7c .semi-tabs-pane {
  overflow: visible;
}

.faq-tutorials_272b7c .semi-list-item {
  height: 4rem;
  background-color: #fff;
  border: none;
  border-radius: 12px;
  margin-bottom: 1rem;
  padding: .8rem 1rem;
  font-family: HKGroteskBold;
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

.faq-tutorials_272b7c .semi-list-item a {
  width: 100%;
  font-size: 1.2rem;
  transition: color .3s;
  display: block;
}

.faq-tutorials_272b7c .semi-list-item a:hover {
  color: #820ff0;
}

@media screen and (min-width: 576px) {
  .jump_272b7c {
    height: 800px;
    background-position: 100%;
    background-size: contain;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    display: flex;
  }

  .jump_272b7c h1 {
    margin-bottom: 20px;
    font-size: 60px;
    line-height: 3.75rem;
  }

  .jump_272b7c h1:after {
    content: "";
    width: 250px;
    height: 4px;
    background: linear-gradient(270deg, #22eef900 0%, #820ff0 100%);
    margin-top: .675rem;
    display: block;
  }

  .jump_272b7c .jump-desc_272b7c dt, .jump_272b7c .jump-desc_272b7c dd {
    margin-bottom: 1rem;
    font-size: 20px;
  }

  .jump_272b7c .jump-desc_272b7c dd:before {
    width: 4px;
    height: 16px;
    margin-right: 8px;
  }

  .app-list_272b7c .app-list-item_272b7c:hover {
    background: #fcfbfe;
    box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
  }
}

@media screen and (min-width: 769px) {
  .bg-box_272b7c {
    padding: 80px 0 140px;
  }

  .bg-box_272b7c .browsers-row_272b7c {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .bg-box_272b7c .browsers-row_272b7c .link_272b7c {
    width: 300px;
    height: 320px;
    border: none;
    border-radius: 12px;
    margin-bottom: 80px;
    font-size: 20px;
  }

  .bg-box_272b7c .browsers-row_272b7c .link_272b7c:hover {
    box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
  }

  .browsers_272b7c .icon_272b7c {
    width: 140px;
    height: 140px;
    background-size: 480px 365px;
    margin-bottom: 30px;
    display: block;
  }

  .browsers_272b7c .icon_272b7c.icon-chrome_272b7c {
    background-position: -170px -10px;
  }

  .browsers_272b7c .icon_272b7c.icon-firefox_272b7c {
    height: 145px;
    background-position: -10px -10px;
  }

  .browsers_272b7c .icon_272b7c.icon-edge_272b7c {
    background-position: -330px -10px;
  }

  .browsers_272b7c .extension_272b7c .icon-chrome_272b7c {
    width: 2.1875rem;
    height: 2.1875rem;
    background-position: -42.5px -2.5px;
    background-size: 120px 91.25px;
  }

  .apps-header_272b7c {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.875rem;
  }

  .apps-header-btn-group_272b7c {
    width: auto;
    justify-content: space-between;
  }

  .apps-header-btn-group_272b7c > a {
    height: 60px;
    width: auto;
    font-size: 20px;
    font-weight: 400;
  }

  .apps-header-btn-group_272b7c > a:last-child {
    margin-left: 1.5rem;
  }

  .apps-header-btn-group_272b7c > a span {
    padding: 0 1rem;
  }

  .app-list_272b7c .app-list-item_272b7c {
    min-height: 240px;
    width: 32%;
    margin-bottom: 1.8rem;
    padding: 1.5rem 1.75rem;
    font-size: 20px;
    line-height: 140%;
  }

  .app-list_272b7c .app-list-item_272b7c dt {
    font-size: 1.875rem;
  }

  .app-list_272b7c .app-list-item_272b7c .icon_272b7c {
    width: 5rem;
    height: 5rem;
    background-size: auto;
  }

  .app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-astrox_272b7c {
    background-position: -10px -175px;
  }

  .app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-stoic_272b7c {
    background-position: -110px -275px;
  }

  .app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-icpbox_272b7c {
    background-position: -210px -175px;
  }

  .app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-icpswap_272b7c {
    background-position: -110px -175px;
  }

  .app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-plug_272b7c {
    background-position: -10px -275px;
  }

  .app-list_272b7c .app-list-item_272b7c .icon_272b7c.icon-infinity_272b7c {
    background-position: -310px -175px;
  }

  .icon-plus_272b7c {
    height: 20px;
    width: 20px;
    border: 2px solid #16042f;
  }

  .icon-plus_272b7c:before, .icon-plus_272b7c:after {
    width: 2px;
    top: 3px;
    left: 7px;
  }

  .icon-circle_272b7c {
    height: 20px;
    width: 20px;
  }

  .icon-circle_272b7c:before, .icon-circle_272b7c:after, .icon-circle_272b7c b, .icon-circle_272b7c b:after {
    content: "";
    box-sizing: border-box;
    width: 9px;
    height: 9px;
    border: 2px solid #16042f;
  }

  .icon-circle_272b7c b:after {
    top: -2px;
    left: 9px;
  }

  .faq-tutorials_272b7c .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab {
    padding: 1rem 0 1.5rem;
    font-size: 2.5rem;
  }

  .faq-tutorials_272b7c .semi-list-item {
    padding: .8rem 2rem;
  }
}

@media screen and (min-width: 992px) {
  .apps-header-btn-group_272b7c > a:last-child {
    margin-left: 3.75rem;
  }
}

.faqs_272b7c {
  padding-bottom: 0;
}

body, body .semi-always-light {
  --semi-amber-0: 254, 251, 235;
  --semi-amber-1: 252, 245, 206;
  --semi-amber-2: 249, 232, 158;
  --semi-amber-3: 246, 216, 111;
  --semi-amber-4: 243, 198, 65;
  --semi-amber-5: 240, 177, 20;
  --semi-amber-6: 200, 138, 15;
  --semi-amber-7: 160, 102, 10;
  --semi-amber-8: 120, 70, 6;
  --semi-amber-9: 80, 43, 3;
  --semi-black: 0, 0, 0;
  --semi-blue-0: 234, 245, 255;
  --semi-blue-1: 203, 231, 254;
  --semi-blue-2: 152, 205, 253;
  --semi-blue-3: 101, 178, 252;
  --semi-blue-4: 50, 149, 251;
  --semi-blue-5: 0, 100, 250;
  --semi-blue-6: 0, 98, 214;
  --semi-blue-7: 0, 79, 179;
  --semi-blue-8: 0, 61, 143;
  --semi-blue-9: 0, 44, 107;
  --semi-cyan-0: 229, 247, 248;
  --semi-cyan-1: 194, 239, 240;
  --semi-cyan-2: 138, 221, 226;
  --semi-cyan-3: 88, 203, 211;
  --semi-cyan-4: 44, 184, 197;
  --semi-cyan-5: 5, 164, 182;
  --semi-cyan-6: 3, 134, 152;
  --semi-cyan-7: 1, 105, 121;
  --semi-cyan-8: 0, 77, 91;
  --semi-cyan-9: 0, 50, 61;
  --semi-green-0: 236, 247, 236;
  --semi-green-1: 208, 240, 209;
  --semi-green-2: 164, 224, 167;
  --semi-green-3: 125, 209, 130;
  --semi-green-4: 90, 194, 98;
  --semi-green-5: 59, 179, 70;
  --semi-green-6: 48, 149, 59;
  --semi-green-7: 37, 119, 47;
  --semi-green-8: 27, 89, 36;
  --semi-green-9: 17, 60, 24;
  --semi-grey-0: 249, 249, 249;
  --semi-grey-1: 230, 232, 234;
  --semi-grey-2: 198, 202, 205;
  --semi-grey-3: 167, 171, 176;
  --semi-grey-4: 136, 141, 146;
  --semi-grey-5: 107, 112, 117;
  --semi-grey-6: 85, 91, 97;
  --semi-grey-7: 65, 70, 76;
  --semi-grey-8: 46, 50, 56;
  --semi-grey-9: 28, 31, 35;
  --semi-indigo-0: 236, 239, 248;
  --semi-indigo-1: 209, 216, 240;
  --semi-indigo-2: 167, 179, 225;
  --semi-indigo-3: 128, 144, 211;
  --semi-indigo-4: 94, 111, 196;
  --semi-indigo-5: 63, 81, 181;
  --semi-indigo-6: 51, 66, 161;
  --semi-indigo-7: 40, 52, 140;
  --semi-indigo-8: 31, 40, 120;
  --semi-indigo-9: 23, 29, 99;
  --semi-light-blue-0: 233, 247, 253;
  --semi-light-blue-1: 201, 236, 252;
  --semi-light-blue-2: 149, 216, 248;
  --semi-light-blue-3: 98, 195, 245;
  --semi-light-blue-4: 48, 172, 241;
  --semi-light-blue-5: 0, 149, 238;
  --semi-light-blue-6: 0, 123, 202;
  --semi-light-blue-7: 0, 99, 167;
  --semi-light-blue-8: 0, 75, 131;
  --semi-light-blue-9: 0, 53, 95;
  --semi-light-green-0: 243, 248, 236;
  --semi-light-green-1: 227, 240, 208;
  --semi-light-green-2: 200, 226, 165;
  --semi-light-green-3: 173, 211, 126;
  --semi-light-green-4: 147, 197, 91;
  --semi-light-green-5: 123, 182, 60;
  --semi-light-green-6: 100, 152, 48;
  --semi-light-green-7: 78, 121, 38;
  --semi-light-green-8: 57, 91, 27;
  --semi-light-green-9: 37, 61, 18;
  --semi-lime-0: 242, 250, 230;
  --semi-lime-1: 227, 246, 197;
  --semi-lime-2: 203, 237, 142;
  --semi-lime-3: 183, 227, 91;
  --semi-lime-4: 167, 218, 44;
  --semi-lime-5: 155, 209, 0;
  --semi-lime-6: 126, 174, 0;
  --semi-lime-7: 99, 139, 0;
  --semi-lime-8: 72, 104, 0;
  --semi-lime-9: 47, 70, 0;
  --semi-orange-0: 255, 248, 234;
  --semi-orange-1: 254, 238, 204;
  --semi-orange-2: 254, 217, 152;
  --semi-orange-3: 253, 193, 101;
  --semi-orange-4: 253, 166, 51;
  --semi-orange-5: 252, 136, 0;
  --semi-orange-6: 210, 103, 0;
  --semi-orange-7: 168, 74, 0;
  --semi-orange-8: 126, 49, 0;
  --semi-orange-9: 84, 29, 0;
  --semi-pink-0: 253, 236, 239;
  --semi-pink-1: 251, 207, 216;
  --semi-pink-2: 246, 160, 181;
  --semi-pink-3: 242, 115, 150;
  --semi-pink-4: 237, 72, 123;
  --semi-pink-5: 233, 30, 99;
  --semi-pink-6: 197, 19, 86;
  --semi-pink-7: 162, 11, 72;
  --semi-pink-8: 126, 5, 58;
  --semi-pink-9: 90, 1, 43;
  --semi-purple-0: 247, 233, 247;
  --semi-purple-1: 239, 202, 240;
  --semi-purple-2: 221, 155, 224;
  --semi-purple-3: 201, 111, 209;
  --semi-purple-4: 180, 73, 194;
  --semi-purple-5: 158, 40, 179;
  --semi-purple-6: 135, 30, 158;
  --semi-purple-7: 113, 22, 138;
  --semi-purple-8: 92, 15, 117;
  --semi-purple-9: 73, 10, 97;
  --semi-red-0: 254, 242, 237;
  --semi-red-1: 254, 221, 210;
  --semi-red-2: 253, 183, 165;
  --semi-red-3: 251, 144, 120;
  --semi-red-4: 250, 102, 76;
  --semi-red-5: 249, 57, 32;
  --semi-red-6: 213, 37, 21;
  --semi-red-7: 178, 20, 12;
  --semi-red-8: 142, 8, 5;
  --semi-red-9: 106, 1, 3;
  --semi-teal-0: 228, 247, 244;
  --semi-teal-1: 192, 240, 232;
  --semi-teal-2: 135, 224, 211;
  --semi-teal-3: 84, 209, 193;
  --semi-teal-4: 39, 194, 176;
  --semi-teal-5: 0, 179, 161;
  --semi-teal-6: 0, 149, 137;
  --semi-teal-7: 0, 119, 111;
  --semi-teal-8: 0, 89, 85;
  --semi-teal-9: 0, 60, 58;
  --semi-violet-0: 243, 237, 249;
  --semi-violet-1: 226, 209, 244;
  --semi-violet-2: 196, 167, 233;
  --semi-violet-3: 166, 127, 221;
  --semi-violet-4: 136, 91, 210;
  --semi-violet-5: 106, 58, 199;
  --semi-violet-6: 87, 47, 179;
  --semi-violet-7: 70, 37, 158;
  --semi-violet-8: 54, 28, 138;
  --semi-violet-9: 40, 20, 117;
  --semi-white: 255, 255, 255;
  --semi-yellow-0: 255, 253, 234;
  --semi-yellow-1: 254, 251, 203;
  --semi-yellow-2: 253, 243, 152;
  --semi-yellow-3: 252, 232, 101;
  --semi-yellow-4: 251, 218, 50;
  --semi-yellow-5: 250, 200, 0;
  --semi-yellow-6: 208, 170, 0;
  --semi-yellow-7: 167, 139, 0;
  --semi-yellow-8: 125, 106, 0;
  --semi-yellow-9: 83, 72, 0;
}

body[theme-mode="dark"], body .semi-always-dark {
  --semi-red-0: 108, 9, 11;
  --semi-red-1: 144, 17, 16;
  --semi-red-2: 180, 32, 25;
  --semi-red-3: 215, 51, 36;
  --semi-red-4: 251, 73, 50;
  --semi-red-5: 252, 114, 90;
  --semi-red-6: 253, 153, 131;
  --semi-red-7: 253, 190, 172;
  --semi-red-8: 254, 224, 213;
  --semi-red-9: 255, 243, 239;
  --semi-pink-0: 92, 7, 48;
  --semi-pink-1: 128, 14, 65;
  --semi-pink-2: 164, 23, 81;
  --semi-pink-3: 199, 34, 97;
  --semi-pink-4: 235, 47, 113;
  --semi-pink-5: 239, 86, 134;
  --semi-pink-6: 243, 126, 159;
  --semi-pink-7: 247, 168, 188;
  --semi-pink-8: 251, 211, 220;
  --semi-pink-9: 253, 238, 241;
  --semi-purple-0: 74, 16, 97;
  --semi-purple-1: 94, 23, 118;
  --semi-purple-2: 115, 31, 138;
  --semi-purple-3: 137, 40, 159;
  --semi-purple-4: 160, 51, 179;
  --semi-purple-5: 181, 83, 194;
  --semi-purple-6: 202, 120, 209;
  --semi-purple-7: 221, 160, 225;
  --semi-purple-8: 239, 206, 240;
  --semi-purple-9: 247, 235, 247;
  --semi-violet-0: 64, 27, 119;
  --semi-violet-1: 76, 36, 140;
  --semi-violet-2: 88, 46, 160;
  --semi-violet-3: 100, 57, 181;
  --semi-violet-4: 114, 70, 201;
  --semi-violet-5: 136, 101, 212;
  --semi-violet-6: 162, 136, 223;
  --semi-violet-7: 190, 173, 233;
  --semi-violet-8: 221, 212, 244;
  --semi-violet-9: 241, 238, 250;
  --semi-indigo-0: 23, 30, 101;
  --semi-indigo-1: 32, 41, 122;
  --semi-indigo-2: 41, 54, 142;
  --semi-indigo-3: 52, 68, 163;
  --semi-indigo-4: 64, 83, 183;
  --semi-indigo-5: 95, 113, 197;
  --semi-indigo-6: 129, 145, 212;
  --semi-indigo-7: 167, 180, 226;
  --semi-indigo-8: 209, 216, 241;
  --semi-indigo-9: 237, 239, 248;
  --semi-blue-0: 5, 49, 112;
  --semi-blue-1: 10, 70, 148;
  --semi-blue-2: 19, 92, 184;
  --semi-blue-3: 29, 117, 219;
  --semi-blue-4: 41, 144, 255;
  --semi-blue-5: 84, 169, 255;
  --semi-blue-6: 127, 193, 255;
  --semi-blue-7: 169, 215, 255;
  --semi-blue-8: 212, 236, 255;
  --semi-blue-9: 239, 248, 255;
  --semi-light-blue-0: 0, 55, 97;
  --semi-light-blue-1: 0, 77, 133;
  --semi-light-blue-2: 3, 102, 169;
  --semi-light-blue-3: 10, 129, 204;
  --semi-light-blue-4: 19, 159, 240;
  --semi-light-blue-5: 64, 180, 243;
  --semi-light-blue-6: 110, 200, 246;
  --semi-light-blue-7: 157, 220, 249;
  --semi-light-blue-8: 206, 238, 252;
  --semi-light-blue-9: 235, 248, 254;
  --semi-cyan-0: 4, 52, 61;
  --semi-cyan-1: 7, 79, 92;
  --semi-cyan-2: 10, 108, 123;
  --semi-cyan-3: 14, 137, 153;
  --semi-cyan-4: 19, 168, 184;
  --semi-cyan-5: 56, 187, 198;
  --semi-cyan-6: 98, 205, 212;
  --semi-cyan-7: 145, 223, 227;
  --semi-cyan-8: 198, 239, 241;
  --semi-cyan-9: 231, 247, 248;
  --semi-teal-0: 2, 60, 57;
  --semi-teal-1: 4, 90, 85;
  --semi-teal-2: 7, 119, 111;
  --semi-teal-3: 10, 149, 136;
  --semi-teal-4: 14, 179, 161;
  --semi-teal-5: 51, 194, 176;
  --semi-teal-6: 94, 209, 193;
  --semi-teal-7: 142, 225, 211;
  --semi-teal-8: 196, 240, 232;
  --semi-teal-9: 230, 247, 244;
  --semi-green-0: 18, 60, 25;
  --semi-green-1: 28, 90, 37;
  --semi-green-2: 39, 119, 49;
  --semi-green-3: 50, 149, 61;
  --semi-green-4: 62, 179, 73;
  --semi-green-5: 93, 194, 100;
  --semi-green-6: 127, 209, 132;
  --semi-green-7: 166, 225, 168;
  --semi-green-8: 208, 240, 209;
  --semi-green-9: 236, 247, 236;
  --semi-light-green-0: 38, 61, 19;
  --semi-light-green-1: 59, 92, 29;
  --semi-light-green-2: 81, 123, 40;
  --semi-light-green-3: 103, 153, 52;
  --semi-light-green-4: 127, 184, 64;
  --semi-light-green-5: 151, 198, 95;
  --semi-light-green-6: 176, 212, 129;
  --semi-light-green-7: 201, 227, 167;
  --semi-light-green-8: 228, 241, 209;
  --semi-light-green-9: 243, 248, 237;
  --semi-lime-0: 49, 70, 3;
  --semi-lime-1: 75, 105, 5;
  --semi-lime-2: 103, 141, 9;
  --semi-lime-3: 132, 176, 12;
  --semi-lime-4: 162, 211, 17;
  --semi-lime-5: 174, 220, 58;
  --semi-lime-6: 189, 229, 102;
  --semi-lime-7: 207, 237, 150;
  --semi-lime-8: 229, 246, 201;
  --semi-lime-9: 243, 251, 233;
  --semi-yellow-0: 84, 73, 3;
  --semi-yellow-1: 126, 108, 6;
  --semi-yellow-2: 168, 142, 10;
  --semi-yellow-3: 210, 175, 15;
  --semi-yellow-4: 252, 206, 20;
  --semi-yellow-5: 253, 222, 67;
  --semi-yellow-6: 253, 235, 113;
  --semi-yellow-7: 254, 245, 160;
  --semi-yellow-8: 254, 251, 208;
  --semi-yellow-9: 255, 254, 236;
  --semi-amber-0: 81, 46, 9;
  --semi-amber-1: 121, 75, 15;
  --semi-amber-2: 161, 107, 22;
  --semi-amber-3: 202, 143, 30;
  --semi-amber-4: 242, 183, 38;
  --semi-amber-5: 245, 202, 80;
  --semi-amber-6: 247, 219, 122;
  --semi-amber-7: 250, 234, 166;
  --semi-amber-8: 252, 246, 210;
  --semi-amber-9: 254, 251, 237;
  --semi-orange-0: 85, 31, 3;
  --semi-orange-1: 128, 53, 6;
  --semi-orange-2: 170, 80, 10;
  --semi-orange-3: 213, 111, 15;
  --semi-orange-4: 255, 146, 20;
  --semi-orange-5: 255, 174, 67;
  --semi-orange-6: 255, 199, 114;
  --semi-orange-7: 255, 221, 161;
  --semi-orange-8: 255, 239, 208;
  --semi-orange-9: 255, 249, 237;
  --semi-grey-0: 28, 31, 35;
  --semi-grey-1: 46, 50, 56;
  --semi-grey-2: 65, 70, 76;
  --semi-grey-3: 85, 91, 97;
  --semi-grey-4: 107, 112, 117;
  --semi-grey-5: 136, 141, 146;
  --semi-grey-6: 167, 171, 176;
  --semi-grey-7: 198, 202, 205;
  --semi-grey-8: 230, 232, 234;
  --semi-grey-9: 249, 249, 249;
  --semi-white: 255, 255, 255;
  --semi-black: 0, 0, 0;
}

body, body[theme-mode="dark"] .semi-always-light {
  -webkit-font-smoothing: antialiased;
  --semi-color-white: rgba(var(--semi-white), 1);
  --semi-color-black: rgba(var(--semi-black), 1);
  --semi-color-primary: rgba(var(--semi-blue-5), 1);
  --semi-color-primary-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-primary-active: rgba(var(--semi-blue-7), 1);
  --semi-color-primary-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-primary-light-default: rgba(var(--semi-blue-0), 1);
  --semi-color-primary-light-hover: rgba(var(--semi-blue-1), 1);
  --semi-color-primary-light-active: rgba(var(--semi-blue-2), 1);
  --semi-color-secondary: rgba(var(--semi-light-blue-5), 1);
  --semi-color-secondary-hover: rgba(var(--semi-light-blue-6), 1);
  --semi-color-secondary-active: rgba(var(--semi-light-blue-7), 1);
  --semi-color-secondary-disabled: rgba(var(--semi-light-blue-2), 1);
  --semi-color-secondary-light-default: rgba(var(--semi-light-blue-0), 1);
  --semi-color-secondary-light-hover: rgba(var(--semi-light-blue-1), 1);
  --semi-color-secondary-light-active: rgba(var(--semi-light-blue-2), 1);
  --semi-color-tertiary: rgba(var(--semi-grey-5), 1);
  --semi-color-tertiary-hover: rgba(var(--semi-grey-6), 1);
  --semi-color-tertiary-active: rgba(var(--semi-grey-7), 1);
  --semi-color-tertiary-light-default: rgba(var(--semi-grey-0), 1);
  --semi-color-tertiary-light-hover: rgba(var(--semi-grey-1), 1);
  --semi-color-tertiary-light-active: rgba(var(--semi-grey-2), 1);
  --semi-color-default: rgba(var(--semi-grey-0), 1);
  --semi-color-default-hover: rgba(var(--semi-grey-1), 1);
  --semi-color-default-active: rgba(var(--semi-grey-2), 1);
  --semi-color-info: rgba(var(--semi-blue-5), 1);
  --semi-color-info-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-info-active: rgba(var(--semi-blue-7), 1);
  --semi-color-info-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-info-light-default: rgba(var(--semi-blue-0), 1);
  --semi-color-info-light-hover: rgba(var(--semi-blue-1), 1);
  --semi-color-info-light-active: rgba(var(--semi-blue-2), 1);
  --semi-color-success: rgba(var(--semi-green-5), 1);
  --semi-color-success-hover: rgba(var(--semi-green-6), 1);
  --semi-color-success-active: rgba(var(--semi-green-7), 1);
  --semi-color-success-disabled: rgba(var(--semi-green-2), 1);
  --semi-color-success-light-default: rgba(var(--semi-green-0), 1);
  --semi-color-success-light-hover: rgba(var(--semi-green-1), 1);
  --semi-color-success-light-active: rgba(var(--semi-green-2), 1);
  --semi-color-danger: rgba(var(--semi-red-5), 1);
  --semi-color-danger-hover: rgba(var(--semi-red-6), 1);
  --semi-color-danger-active: rgba(var(--semi-red-7), 1);
  --semi-color-danger-light-default: rgba(var(--semi-red-0), 1);
  --semi-color-danger-light-hover: rgba(var(--semi-red-1), 1);
  --semi-color-danger-light-active: rgba(var(--semi-red-2), 1);
  --semi-color-warning: rgba(var(--semi-orange-5), 1);
  --semi-color-warning-hover: rgba(var(--semi-orange-6), 1);
  --semi-color-warning-active: rgba(var(--semi-orange-7), 1);
  --semi-color-warning-light-default: rgba(var(--semi-orange-0), 1);
  --semi-color-warning-light-hover: rgba(var(--semi-orange-1), 1);
  --semi-color-warning-light-active: rgba(var(--semi-orange-2), 1);
  --semi-color-focus-border: rgba(var(--semi-blue-5), 1);
  --semi-color-disabled-text: rgba(var(--semi-grey-9), .35);
  --semi-color-disabled-border: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-bg: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-fill: rgba(var(--semi-grey-8), .04);
  --semi-color-shadow: rgba(var(--semi-black), .04);
  --semi-color-link: rgba(var(--semi-blue-5), 1);
  --semi-color-link-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-link-active: rgba(var(--semi-blue-7), 1);
  --semi-color-link-visited: rgba(var(--semi-blue-5), 1);
  --semi-color-border: rgba(var(--semi-grey-9), .08);
  --semi-color-nav-bg: rgba(var(--semi-white), 1);
  --semi-color-overlay-bg: #16161a99;
  --semi-color-fill-0: rgba(var(--semi-grey-8), .05);
  --semi-color-fill-1: rgba(var(--semi-grey-8), .09);
  --semi-color-fill-2: rgba(var(--semi-grey-8), .13);
  --semi-color-bg-0: rgba(var(--semi-white), 1);
  --semi-color-bg-1: rgba(var(--semi-white), 1);
  --semi-color-bg-2: rgba(var(--semi-white), 1);
  --semi-color-bg-3: rgba(var(--semi-white), 1);
  --semi-color-bg-4: rgba(var(--semi-white), 1);
  --semi-color-text-0: rgba(var(--semi-grey-9), 1);
  --semi-color-text-1: rgba(var(--semi-grey-9), .8);
  --semi-color-text-2: rgba(var(--semi-grey-9), .62);
  --semi-color-text-3: rgba(var(--semi-grey-9), .35);
  --semi-shadow-elevated: 0 0 1px #0000004d, 0 4px 14px #0000001a;
  --semi-border-radius-extra-small: 3px;
  --semi-border-radius-small: 3px;
  --semi-border-radius-medium: 6px;
  --semi-border-radius-large: 12px;
  --semi-border-radius-circle: 50%;
  --semi-border-radius-full: 9999px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
}

body[theme-mode="dark"], body .semi-always-dark {
  -webkit-font-smoothing: antialiased;
  --semi-color-white: #e4e7f5;
  --semi-color-black: rgba(var(--semi-black), 1);
  --semi-color-primary: rgba(var(--semi-blue-5), 1);
  --semi-color-primary-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-primary-active: rgba(var(--semi-blue-7), 1);
  --semi-color-primary-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-primary-light-default: rgba(var(--semi-blue-5), .2);
  --semi-color-primary-light-hover: rgba(var(--semi-blue-5), .3);
  --semi-color-primary-light-active: rgba(var(--semi-blue-5), .4);
  --semi-color-secondary: rgba(var(--semi-light-blue-5), 1);
  --semi-color-secondary-hover: rgba(var(--semi-light-blue-6), 1);
  --semi-color-secondary-active: rgba(var(--semi-light-blue-7), 1);
  --semi-color-secondary-disabled: rgba(var(--semi-light-blue-2), 1);
  --semi-color-secondary-light-default: rgba(var(--semi-light-blue-5), .2);
  --semi-color-secondary-light-hover: rgba(var(--semi-light-blue-5), .3);
  --semi-color-secondary-light-active: rgba(var(--semi-light-blue-5), .4);
  --semi-color-tertiary: rgba(var(--semi-grey-5), 1);
  --semi-color-tertiary-hover: rgba(var(--semi-grey-6), 1);
  --semi-color-tertiary-active: rgba(var(--semi-grey-7), 1);
  --semi-color-tertiary-light-default: rgba(var(--semi-grey-5), .2);
  --semi-color-tertiary-light-hover: rgba(var(--semi-grey-5), .3);
  --semi-color-tertiary-light-active: rgba(var(--semi-grey-5), .4);
  --semi-color-default: rgba(var(--semi-grey-0), 1);
  --semi-color-default-hover: rgba(var(--semi-grey-1), 1);
  --semi-color-default-active: rgba(var(--semi-grey-2), 1);
  --semi-color-info: rgba(var(--semi-blue-5), 1);
  --semi-color-info-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-info-active: rgba(var(--semi-blue-7), 1);
  --semi-color-info-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-info-light-default: rgba(var(--semi-blue-5), .2);
  --semi-color-info-light-hover: rgba(var(--semi-blue-5), .3);
  --semi-color-info-light-active: rgba(var(--semi-blue-5), .4);
  --semi-color-success: rgba(var(--semi-green-5), 1);
  --semi-color-success-hover: rgba(var(--semi-green-6), 1);
  --semi-color-success-active: rgba(var(--semi-green-7), 1);
  --semi-color-success-disabled: rgba(var(--semi-green-2), 1);
  --semi-color-success-light-default: rgba(var(--semi-green-5), .2);
  --semi-color-success-light-hover: rgba(var(--semi-green-5), .3);
  --semi-color-success-light-active: rgba(var(--semi-green-5), .4);
  --semi-color-danger: rgba(var(--semi-red-5), 1);
  --semi-color-danger-hover: rgba(var(--semi-red-6), 1);
  --semi-color-danger-active: rgba(var(--semi-red-7), 1);
  --semi-color-danger-light-default: rgba(var(--semi-red-5), .2);
  --semi-color-danger-light-hover: rgba(var(--semi-red-5), .3);
  --semi-color-danger-light-active: rgba(var(--semi-red-5), .4);
  --semi-color-warning: rgba(var(--semi-orange-5), 1);
  --semi-color-warning-hover: rgba(var(--semi-orange-6), 1);
  --semi-color-warning-active: rgba(var(--semi-orange-7), 1);
  --semi-color-warning-light-default: rgba(var(--semi-orange-5), .2);
  --semi-color-warning-light-hover: rgba(var(--semi-orange-5), .3);
  --semi-color-warning-light-active: rgba(var(--semi-orange-5), .4);
  --semi-color-focus-border: rgba(var(--semi-blue-5), 1);
  --semi-color-disabled-text: rgba(var(--semi-grey-9), .35);
  --semi-color-disabled-border: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-bg: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-fill: rgba(var(--semi-grey-8), .04);
  --semi-color-link: rgba(var(--semi-blue-5), 1);
  --semi-color-link-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-link-active: rgba(var(--semi-blue-7), 1);
  --semi-color-link-visited: rgba(var(--semi-blue-5), 1);
  --semi-color-nav-bg: #232429;
  --semi-shadow-elevated: inset 0 0 0 1px #ffffff1a, 0 4px 14px #00000040;
  --semi-color-overlay-bg: #16161a99;
  --semi-color-fill-0: rgba(var(--semi-white), .12);
  --semi-color-fill-1: rgba(var(--semi-white), .16);
  --semi-color-fill-2: rgba(var(--semi-white), .2);
  --semi-color-border: rgba(var(--semi-white), .08);
  --semi-color-shadow: rgba(var(--semi-black), .04);
  --semi-color-bg-0: #16161a;
  --semi-color-bg-1: #232429;
  --semi-color-bg-2: #35363c;
  --semi-color-bg-3: #43444a;
  --semi-color-bg-4: #4f5159;
  --semi-color-text-0: rgba(var(--semi-grey-9), 1);
  --semi-color-text-1: rgba(var(--semi-grey-9), .8);
  --semi-color-text-2: rgba(var(--semi-grey-9), .6);
  --semi-color-text-3: rgba(var(--semi-grey-9), .35);
  --semi-border-radius-extra-small: 3px;
  --semi-border-radius-small: 3px;
  --semi-border-radius-medium: 6px;
  --semi-border-radius-large: 12px;
  --semi-border-radius-circle: 50%;
  --semi-border-radius-full: 9999px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.semi-light-scrollbar::-webkit-scrollbar, .semi-light-scrollbar ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.semi-light-scrollbar::-webkit-scrollbar-track, .semi-light-scrollbar ::-webkit-scrollbar-track {
  background: none;
}

.semi-light-scrollbar::-webkit-scrollbar-corner, .semi-light-scrollbar ::-webkit-scrollbar-corner {
  background-color: #0000;
}

.semi-light-scrollbar::-webkit-scrollbar-thumb, .semi-light-scrollbar ::-webkit-scrollbar-thumb {
  background: none;
  border-radius: 6px;
  transition: all 1s;
}

.semi-light-scrollbar:hover::-webkit-scrollbar-thumb, .semi-light-scrollbar :hover::-webkit-scrollbar-thumb {
  background: var(--semi-color-fill-2);
}

.semi-light-scrollbar::-webkit-scrollbar-thumb:hover, .semi-light-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: var(--semi-color-fill-1);
}

.semi-icon {
  text-align: center;
  text-transform: none;
  text-rendering: optimizelegibility;
  fill: currentColor;
  font-style: normal;
  line-height: 0;
  display: inline-block;
}

.semi-icon-extra-small {
  font-size: 8px;
}

.semi-icon-small {
  font-size: 12px;
}

.semi-icon-default {
  font-size: 16px;
}

.semi-icon-large {
  font-size: 20px;
}

.semi-icon-extra-large {
  font-size: 24px;
}

.semi-icon-spinning {
  animation: semi-icon-animation-rotate .6s linear infinite;
}

@keyframes semi-icon-animation-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.semi-collapse-item {
  border-bottom: 1px solid var(--semi-color-border);
}

.semi-collapse-header {
  border-radius: var(--semi-border-radius-small);
  cursor: pointer;
  color: var(--semi-color-text-0);
  outline: none;
  justify-content: space-between;
  align-items: center;
  margin: 4px 8px;
  padding: 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.semi-collapse-header-right {
  align-items: center;
  display: flex;
}

.semi-collapse-header-right span {
  padding-right: 8px;
  display: flex;
}

.semi-collapse-header-right span:last-child {
  padding-right: 0;
}

.semi-collapse-header-icon {
  width: 16px;
  height: 16px;
  color: var(--semi-color-text-2);
}

.semi-collapse-header-iconLeft {
  justify-content: flex-start;
}

.semi-collapse-header-iconLeft .semi-collapse-header-icon {
  margin-right: 8px;
}

.semi-collapse-header-iconDisabled {
  color: var(--semi-color-disabled-text);
}

.semi-collapse-header:hover {
  background-color: var(--semi-color-fill-0);
}

.semi-collapse-header:active {
  background-color: var(--semi-color-fill-1);
}

.semi-collapse-content {
  color: var(--semi-color-text-1);
  padding: 4px 16px 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.semi-collapse-content p {
  margin: 0;
}

.semi-rtl .semi-collapse, .semi-portal-rtl .semi-collapse {
  direction: rtl;
}

.semi-rtl .semi-collapse-header-right span, .semi-portal-rtl .semi-collapse-header-right span {
  padding-left: 8px;
  padding-right: 0;
  display: flex;
}

.semi-rtl .semi-collapse-header-right span:last-child, .semi-portal-rtl .semi-collapse-header-right span:last-child {
  padding-left: 0;
  padding-right: 0;
}

.semi-list {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.semi-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.semi-list-header, .semi-list-footer {
  padding: 12px 24px;
}

.semi-list-empty {
  color: var(--semi-color-text-2);
  width: 100%;
  text-align: center;
  padding: 12px 0;
}

.semi-list-item {
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 12px 24px;
  list-style: none;
  display: flex;
}

.semi-list-item-body {
  flex: 1;
  display: flex;
}

.semi-list-item-body-header {
  margin-right: 20px;
}

.semi-list-item-body-flex-start {
  align-items: flex-start;
}

.semi-list-item-body-flex-end {
  align-items: flex-end;
}

.semi-list-item-body-center {
  align-items: center;
}

.semi-list-item-body-stretch {
  align-items: stretch;
}

.semi-list-item-body-baseline {
  align-items: baseline;
}

.semi-list-item-extra {
  flex: none;
  margin-left: 40px;
}

.semi-list-split .semi-list-item {
  border-bottom: 1px solid var(--semi-color-border);
}

.semi-list-split .semi-list-item:last-child {
  border-bottom: none;
}

.semi-list-split .semi-list-header {
  border-bottom: 1px solid var(--semi-color-border);
}

.semi-list-split .semi-list-footer {
  border-top: 1px solid var(--semi-color-border);
}

.semi-list-small .semi-list-item, .semi-list-small .semi-list-header, .semi-list-small .semi-list-footer {
  padding: 8px 16px;
}

.semi-list-large .semi-list-item, .semi-list-large .semi-list-header, .semi-list-large .semi-list-footer {
  padding: 16px 24px;
}

.semi-list.semi-list-grid .semi-list-item {
  padding: 0;
}

.semi-list.semi-list-bordered {
  border: 1px solid var(--semi-color-border);
}

.semi-list.semi-list-flex .semi-list-items {
  display: flex;
}

.semi-list.semi-list-flex.semi-list-split .semi-list-item {
  border-bottom: none;
  border-right: 1px solid var(--semi-color-border);
}

.semi-list.semi-list-flex.semi-list-split .semi-list-item:last-child {
  border-right: none;
}

.semi-rtl .semi-list, .semi-portal-rtl .semi-list, .semi-rtl .semi-list-item, .semi-portal-rtl .semi-list-item {
  direction: rtl;
}

.semi-rtl .semi-list-item-body-header, .semi-portal-rtl .semi-list-item-body-header {
  margin-left: 20px;
  margin-right: auto;
}

.semi-rtl .semi-list-item-extra, .semi-portal-rtl .semi-list-item-extra {
  flex: none;
  margin-left: 0;
  margin-right: 40px;
}

.semi-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item, .semi-portal-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item {
  border-bottom: none;
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item:last-child, .semi-portal-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item:last-child {
  border-left: none;
}

.semi-row {
  height: auto;
  zoom: 1;
  box-sizing: border-box;
  margin-left: 0;
  margin-right: 0;
  display: block;
  position: relative;
}

.semi-row:before, .semi-row:after {
  content: "";
  display: table;
}

.semi-row:after {
  clear: both;
}

.semi-row-flex {
  flex-flow: wrap;
  display: flex;
}

.semi-row-flex:before, .semi-row-flex:after {
  display: flex;
}

.semi-col-0 {
  display: none;
}

.semi-row-flex-start {
  justify-content: flex-start;
}

.semi-row-flex-center {
  justify-content: center;
}

.semi-row-flex-end {
  justify-content: flex-end;
}

.semi-row-flex-space-between {
  justify-content: space-between;
}

.semi-row-flex-space-around {
  justify-content: space-around;
}

.semi-row-flex-top {
  align-items: flex-start;
}

.semi-row-flex-middle {
  align-items: center;
}

.semi-row-flex-bottom {
  align-items: flex-end;
}

.semi-col {
  position: relative;
}

.semi-col-1, .semi-col-xs-1, .semi-col-sm-1, .semi-col-md-1, .semi-col-lg-1, .semi-col-2, .semi-col-xs-2, .semi-col-sm-2, .semi-col-md-2, .semi-col-lg-2, .semi-col-3, .semi-col-xs-3, .semi-col-sm-3, .semi-col-md-3, .semi-col-lg-3, .semi-col-4, .semi-col-xs-4, .semi-col-sm-4, .semi-col-md-4, .semi-col-lg-4, .semi-col-5, .semi-col-xs-5, .semi-col-sm-5, .semi-col-md-5, .semi-col-lg-5, .semi-col-6, .semi-col-xs-6, .semi-col-sm-6, .semi-col-md-6, .semi-col-lg-6, .semi-col-7, .semi-col-xs-7, .semi-col-sm-7, .semi-col-md-7, .semi-col-lg-7, .semi-col-8, .semi-col-xs-8, .semi-col-sm-8, .semi-col-md-8, .semi-col-lg-8, .semi-col-9, .semi-col-xs-9, .semi-col-sm-9, .semi-col-md-9, .semi-col-lg-9, .semi-col-10, .semi-col-xs-10, .semi-col-sm-10, .semi-col-md-10, .semi-col-lg-10, .semi-col-11, .semi-col-xs-11, .semi-col-sm-11, .semi-col-md-11, .semi-col-lg-11, .semi-col-12, .semi-col-xs-12, .semi-col-sm-12, .semi-col-md-12, .semi-col-lg-12, .semi-col-13, .semi-col-xs-13, .semi-col-sm-13, .semi-col-md-13, .semi-col-lg-13, .semi-col-14, .semi-col-xs-14, .semi-col-sm-14, .semi-col-md-14, .semi-col-lg-14, .semi-col-15, .semi-col-xs-15, .semi-col-sm-15, .semi-col-md-15, .semi-col-lg-15, .semi-col-16, .semi-col-xs-16, .semi-col-sm-16, .semi-col-md-16, .semi-col-lg-16, .semi-col-17, .semi-col-xs-17, .semi-col-sm-17, .semi-col-md-17, .semi-col-lg-17, .semi-col-18, .semi-col-xs-18, .semi-col-sm-18, .semi-col-md-18, .semi-col-lg-18, .semi-col-19, .semi-col-xs-19, .semi-col-sm-19, .semi-col-md-19, .semi-col-lg-19, .semi-col-20, .semi-col-xs-20, .semi-col-sm-20, .semi-col-md-20, .semi-col-lg-20, .semi-col-21, .semi-col-xs-21, .semi-col-sm-21, .semi-col-md-21, .semi-col-lg-21, .semi-col-22, .semi-col-xs-22, .semi-col-sm-22, .semi-col-md-22, .semi-col-lg-22, .semi-col-23, .semi-col-xs-23, .semi-col-sm-23, .semi-col-md-23, .semi-col-lg-23, .semi-col-24, .semi-col-xs-24, .semi-col-sm-24, .semi-col-md-24, .semi-col-lg-24 {
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.semi-col-1, .semi-col-2, .semi-col-3, .semi-col-4, .semi-col-5, .semi-col-6, .semi-col-7, .semi-col-8, .semi-col-9, .semi-col-10, .semi-col-11, .semi-col-12, .semi-col-13, .semi-col-14, .semi-col-15, .semi-col-16, .semi-col-17, .semi-col-18, .semi-col-19, .semi-col-20, .semi-col-21, .semi-col-22, .semi-col-23, .semi-col-24 {
  float: left;
  flex: none;
}

.semi-col-1 {
  box-sizing: border-box;
  width: 4.16667%;
  display: block;
}

.semi-col-push-1 {
  left: 4.16667%;
}

.semi-col-pull-1 {
  right: 4.16667%;
}

.semi-col-offset-1 {
  margin-left: 4.16667%;
}

.semi-col-order-1 {
  order: 1;
}

.semi-col-2 {
  box-sizing: border-box;
  width: 8.33333%;
  display: block;
}

.semi-col-push-2 {
  left: 8.33333%;
}

.semi-col-pull-2 {
  right: 8.33333%;
}

.semi-col-offset-2 {
  margin-left: 8.33333%;
}

.semi-col-order-2 {
  order: 2;
}

.semi-col-3 {
  box-sizing: border-box;
  width: 12.5%;
  display: block;
}

.semi-col-push-3 {
  left: 12.5%;
}

.semi-col-pull-3 {
  right: 12.5%;
}

.semi-col-offset-3 {
  margin-left: 12.5%;
}

.semi-col-order-3 {
  order: 3;
}

.semi-col-4 {
  box-sizing: border-box;
  width: 16.6667%;
  display: block;
}

.semi-col-push-4 {
  left: 16.6667%;
}

.semi-col-pull-4 {
  right: 16.6667%;
}

.semi-col-offset-4 {
  margin-left: 16.6667%;
}

.semi-col-order-4 {
  order: 4;
}

.semi-col-5 {
  box-sizing: border-box;
  width: 20.8333%;
  display: block;
}

.semi-col-push-5 {
  left: 20.8333%;
}

.semi-col-pull-5 {
  right: 20.8333%;
}

.semi-col-offset-5 {
  margin-left: 20.8333%;
}

.semi-col-order-5 {
  order: 5;
}

.semi-col-6 {
  box-sizing: border-box;
  width: 25%;
  display: block;
}

.semi-col-push-6 {
  left: 25%;
}

.semi-col-pull-6 {
  right: 25%;
}

.semi-col-offset-6 {
  margin-left: 25%;
}

.semi-col-order-6 {
  order: 6;
}

.semi-col-7 {
  box-sizing: border-box;
  width: 29.1667%;
  display: block;
}

.semi-col-push-7 {
  left: 29.1667%;
}

.semi-col-pull-7 {
  right: 29.1667%;
}

.semi-col-offset-7 {
  margin-left: 29.1667%;
}

.semi-col-order-7 {
  order: 7;
}

.semi-col-8 {
  box-sizing: border-box;
  width: 33.3333%;
  display: block;
}

.semi-col-push-8 {
  left: 33.3333%;
}

.semi-col-pull-8 {
  right: 33.3333%;
}

.semi-col-offset-8 {
  margin-left: 33.3333%;
}

.semi-col-order-8 {
  order: 8;
}

.semi-col-9 {
  box-sizing: border-box;
  width: 37.5%;
  display: block;
}

.semi-col-push-9 {
  left: 37.5%;
}

.semi-col-pull-9 {
  right: 37.5%;
}

.semi-col-offset-9 {
  margin-left: 37.5%;
}

.semi-col-order-9 {
  order: 9;
}

.semi-col-10 {
  box-sizing: border-box;
  width: 41.6667%;
  display: block;
}

.semi-col-push-10 {
  left: 41.6667%;
}

.semi-col-pull-10 {
  right: 41.6667%;
}

.semi-col-offset-10 {
  margin-left: 41.6667%;
}

.semi-col-order-10 {
  order: 10;
}

.semi-col-11 {
  box-sizing: border-box;
  width: 45.8333%;
  display: block;
}

.semi-col-push-11 {
  left: 45.8333%;
}

.semi-col-pull-11 {
  right: 45.8333%;
}

.semi-col-offset-11 {
  margin-left: 45.8333%;
}

.semi-col-order-11 {
  order: 11;
}

.semi-col-12 {
  box-sizing: border-box;
  width: 50%;
  display: block;
}

.semi-col-push-12 {
  left: 50%;
}

.semi-col-pull-12 {
  right: 50%;
}

.semi-col-offset-12 {
  margin-left: 50%;
}

.semi-col-order-12 {
  order: 12;
}

.semi-col-13 {
  box-sizing: border-box;
  width: 54.1667%;
  display: block;
}

.semi-col-push-13 {
  left: 54.1667%;
}

.semi-col-pull-13 {
  right: 54.1667%;
}

.semi-col-offset-13 {
  margin-left: 54.1667%;
}

.semi-col-order-13 {
  order: 13;
}

.semi-col-14 {
  box-sizing: border-box;
  width: 58.3333%;
  display: block;
}

.semi-col-push-14 {
  left: 58.3333%;
}

.semi-col-pull-14 {
  right: 58.3333%;
}

.semi-col-offset-14 {
  margin-left: 58.3333%;
}

.semi-col-order-14 {
  order: 14;
}

.semi-col-15 {
  box-sizing: border-box;
  width: 62.5%;
  display: block;
}

.semi-col-push-15 {
  left: 62.5%;
}

.semi-col-pull-15 {
  right: 62.5%;
}

.semi-col-offset-15 {
  margin-left: 62.5%;
}

.semi-col-order-15 {
  order: 15;
}

.semi-col-16 {
  box-sizing: border-box;
  width: 66.6667%;
  display: block;
}

.semi-col-push-16 {
  left: 66.6667%;
}

.semi-col-pull-16 {
  right: 66.6667%;
}

.semi-col-offset-16 {
  margin-left: 66.6667%;
}

.semi-col-order-16 {
  order: 16;
}

.semi-col-17 {
  box-sizing: border-box;
  width: 70.8333%;
  display: block;
}

.semi-col-push-17 {
  left: 70.8333%;
}

.semi-col-pull-17 {
  right: 70.8333%;
}

.semi-col-offset-17 {
  margin-left: 70.8333%;
}

.semi-col-order-17 {
  order: 17;
}

.semi-col-18 {
  box-sizing: border-box;
  width: 75%;
  display: block;
}

.semi-col-push-18 {
  left: 75%;
}

.semi-col-pull-18 {
  right: 75%;
}

.semi-col-offset-18 {
  margin-left: 75%;
}

.semi-col-order-18 {
  order: 18;
}

.semi-col-19 {
  box-sizing: border-box;
  width: 79.1667%;
  display: block;
}

.semi-col-push-19 {
  left: 79.1667%;
}

.semi-col-pull-19 {
  right: 79.1667%;
}

.semi-col-offset-19 {
  margin-left: 79.1667%;
}

.semi-col-order-19 {
  order: 19;
}

.semi-col-20 {
  box-sizing: border-box;
  width: 83.3333%;
  display: block;
}

.semi-col-push-20 {
  left: 83.3333%;
}

.semi-col-pull-20 {
  right: 83.3333%;
}

.semi-col-offset-20 {
  margin-left: 83.3333%;
}

.semi-col-order-20 {
  order: 20;
}

.semi-col-21 {
  box-sizing: border-box;
  width: 87.5%;
  display: block;
}

.semi-col-push-21 {
  left: 87.5%;
}

.semi-col-pull-21 {
  right: 87.5%;
}

.semi-col-offset-21 {
  margin-left: 87.5%;
}

.semi-col-order-21 {
  order: 21;
}

.semi-col-22 {
  box-sizing: border-box;
  width: 91.6667%;
  display: block;
}

.semi-col-push-22 {
  left: 91.6667%;
}

.semi-col-pull-22 {
  right: 91.6667%;
}

.semi-col-offset-22 {
  margin-left: 91.6667%;
}

.semi-col-order-22 {
  order: 22;
}

.semi-col-23 {
  box-sizing: border-box;
  width: 95.8333%;
  display: block;
}

.semi-col-push-23 {
  left: 95.8333%;
}

.semi-col-pull-23 {
  right: 95.8333%;
}

.semi-col-offset-23 {
  margin-left: 95.8333%;
}

.semi-col-order-23 {
  order: 23;
}

.semi-col-24 {
  box-sizing: border-box;
  width: 100%;
  display: block;
}

.semi-col-push-24 {
  left: 100%;
}

.semi-col-pull-24 {
  right: 100%;
}

.semi-col-offset-24 {
  margin-left: 100%;
}

.semi-col-order-24 {
  order: 24;
}

.semi-rtl .semi-col-1, .semi-rtl .semi-col-2, .semi-rtl .semi-col-3, .semi-rtl .semi-col-4, .semi-rtl .semi-col-5, .semi-rtl .semi-col-6, .semi-rtl .semi-col-7, .semi-rtl .semi-col-8, .semi-rtl .semi-col-9, .semi-rtl .semi-col-10, .semi-rtl .semi-col-11, .semi-rtl .semi-col-12, .semi-rtl .semi-col-13, .semi-rtl .semi-col-14, .semi-rtl .semi-col-15, .semi-rtl .semi-col-16, .semi-rtl .semi-col-17, .semi-rtl .semi-col-18, .semi-rtl .semi-col-19, .semi-rtl .semi-col-20, .semi-rtl .semi-col-21, .semi-rtl .semi-col-22, .semi-rtl .semi-col-23, .semi-rtl .semi-col-24 {
  float: right;
}

.semi-rtl .semi-col-offset-1 {
  margin-left: auto;
  margin-right: 4.16667%;
}

.semi-rtl .semi-col-offset-2 {
  margin-left: auto;
  margin-right: 8.33333%;
}

.semi-rtl .semi-col-offset-3 {
  margin-left: auto;
  margin-right: 12.5%;
}

.semi-rtl .semi-col-offset-4 {
  margin-left: auto;
  margin-right: 16.6667%;
}

.semi-rtl .semi-col-offset-5 {
  margin-left: auto;
  margin-right: 20.8333%;
}

.semi-rtl .semi-col-offset-6 {
  margin-left: auto;
  margin-right: 25%;
}

.semi-rtl .semi-col-offset-7 {
  margin-left: auto;
  margin-right: 29.1667%;
}

.semi-rtl .semi-col-offset-8 {
  margin-left: auto;
  margin-right: 33.3333%;
}

.semi-rtl .semi-col-offset-9 {
  margin-left: auto;
  margin-right: 37.5%;
}

.semi-rtl .semi-col-offset-10 {
  margin-left: auto;
  margin-right: 41.6667%;
}

.semi-rtl .semi-col-offset-11 {
  margin-left: auto;
  margin-right: 45.8333%;
}

.semi-rtl .semi-col-offset-12 {
  margin-left: auto;
  margin-right: 50%;
}

.semi-rtl .semi-col-offset-13 {
  margin-left: auto;
  margin-right: 54.1667%;
}

.semi-rtl .semi-col-offset-14 {
  margin-left: auto;
  margin-right: 58.3333%;
}

.semi-rtl .semi-col-offset-15 {
  margin-left: auto;
  margin-right: 62.5%;
}

.semi-rtl .semi-col-offset-16 {
  margin-left: auto;
  margin-right: 66.6667%;
}

.semi-rtl .semi-col-offset-17 {
  margin-left: auto;
  margin-right: 70.8333%;
}

.semi-rtl .semi-col-offset-18 {
  margin-left: auto;
  margin-right: 75%;
}

.semi-rtl .semi-col-offset-19 {
  margin-left: auto;
  margin-right: 79.1667%;
}

.semi-rtl .semi-col-offset-20 {
  margin-left: auto;
  margin-right: 83.3333%;
}

.semi-rtl .semi-col-offset-21 {
  margin-left: auto;
  margin-right: 87.5%;
}

.semi-rtl .semi-col-offset-22 {
  margin-left: auto;
  margin-right: 91.6667%;
}

.semi-rtl .semi-col-offset-23 {
  margin-left: auto;
  margin-right: 95.8333%;
}

.semi-rtl .semi-col-offset-24 {
  margin-left: auto;
  margin-right: 100%;
}

.semi-col-xs-1, .semi-col-xs-2, .semi-col-xs-3, .semi-col-xs-4, .semi-col-xs-5, .semi-col-xs-6, .semi-col-xs-7, .semi-col-xs-8, .semi-col-xs-9, .semi-col-xs-10, .semi-col-xs-11, .semi-col-xs-12, .semi-col-xs-13, .semi-col-xs-14, .semi-col-xs-15, .semi-col-xs-16, .semi-col-xs-17, .semi-col-xs-18, .semi-col-xs-19, .semi-col-xs-20, .semi-col-xs-21, .semi-col-xs-22, .semi-col-xs-23, .semi-col-xs-24 {
  float: left;
  flex: none;
}

.semi-col-xs-1 {
  box-sizing: border-box;
  width: 4.16667%;
  display: block;
}

.semi-col-xs-push-1 {
  left: 4.16667%;
}

.semi-col-xs-pull-1 {
  right: 4.16667%;
}

.semi-col-xs-offset-1 {
  margin-left: 4.16667%;
}

.semi-col-xs-order-1 {
  order: 1;
}

.semi-col-xs-2 {
  box-sizing: border-box;
  width: 8.33333%;
  display: block;
}

.semi-col-xs-push-2 {
  left: 8.33333%;
}

.semi-col-xs-pull-2 {
  right: 8.33333%;
}

.semi-col-xs-offset-2 {
  margin-left: 8.33333%;
}

.semi-col-xs-order-2 {
  order: 2;
}

.semi-col-xs-3 {
  box-sizing: border-box;
  width: 12.5%;
  display: block;
}

.semi-col-xs-push-3 {
  left: 12.5%;
}

.semi-col-xs-pull-3 {
  right: 12.5%;
}

.semi-col-xs-offset-3 {
  margin-left: 12.5%;
}

.semi-col-xs-order-3 {
  order: 3;
}

.semi-col-xs-4 {
  box-sizing: border-box;
  width: 16.6667%;
  display: block;
}

.semi-col-xs-push-4 {
  left: 16.6667%;
}

.semi-col-xs-pull-4 {
  right: 16.6667%;
}

.semi-col-xs-offset-4 {
  margin-left: 16.6667%;
}

.semi-col-xs-order-4 {
  order: 4;
}

.semi-col-xs-5 {
  box-sizing: border-box;
  width: 20.8333%;
  display: block;
}

.semi-col-xs-push-5 {
  left: 20.8333%;
}

.semi-col-xs-pull-5 {
  right: 20.8333%;
}

.semi-col-xs-offset-5 {
  margin-left: 20.8333%;
}

.semi-col-xs-order-5 {
  order: 5;
}

.semi-col-xs-6 {
  box-sizing: border-box;
  width: 25%;
  display: block;
}

.semi-col-xs-push-6 {
  left: 25%;
}

.semi-col-xs-pull-6 {
  right: 25%;
}

.semi-col-xs-offset-6 {
  margin-left: 25%;
}

.semi-col-xs-order-6 {
  order: 6;
}

.semi-col-xs-7 {
  box-sizing: border-box;
  width: 29.1667%;
  display: block;
}

.semi-col-xs-push-7 {
  left: 29.1667%;
}

.semi-col-xs-pull-7 {
  right: 29.1667%;
}

.semi-col-xs-offset-7 {
  margin-left: 29.1667%;
}

.semi-col-xs-order-7 {
  order: 7;
}

.semi-col-xs-8 {
  box-sizing: border-box;
  width: 33.3333%;
  display: block;
}

.semi-col-xs-push-8 {
  left: 33.3333%;
}

.semi-col-xs-pull-8 {
  right: 33.3333%;
}

.semi-col-xs-offset-8 {
  margin-left: 33.3333%;
}

.semi-col-xs-order-8 {
  order: 8;
}

.semi-col-xs-9 {
  box-sizing: border-box;
  width: 37.5%;
  display: block;
}

.semi-col-xs-push-9 {
  left: 37.5%;
}

.semi-col-xs-pull-9 {
  right: 37.5%;
}

.semi-col-xs-offset-9 {
  margin-left: 37.5%;
}

.semi-col-xs-order-9 {
  order: 9;
}

.semi-col-xs-10 {
  box-sizing: border-box;
  width: 41.6667%;
  display: block;
}

.semi-col-xs-push-10 {
  left: 41.6667%;
}

.semi-col-xs-pull-10 {
  right: 41.6667%;
}

.semi-col-xs-offset-10 {
  margin-left: 41.6667%;
}

.semi-col-xs-order-10 {
  order: 10;
}

.semi-col-xs-11 {
  box-sizing: border-box;
  width: 45.8333%;
  display: block;
}

.semi-col-xs-push-11 {
  left: 45.8333%;
}

.semi-col-xs-pull-11 {
  right: 45.8333%;
}

.semi-col-xs-offset-11 {
  margin-left: 45.8333%;
}

.semi-col-xs-order-11 {
  order: 11;
}

.semi-col-xs-12 {
  box-sizing: border-box;
  width: 50%;
  display: block;
}

.semi-col-xs-push-12 {
  left: 50%;
}

.semi-col-xs-pull-12 {
  right: 50%;
}

.semi-col-xs-offset-12 {
  margin-left: 50%;
}

.semi-col-xs-order-12 {
  order: 12;
}

.semi-col-xs-13 {
  box-sizing: border-box;
  width: 54.1667%;
  display: block;
}

.semi-col-xs-push-13 {
  left: 54.1667%;
}

.semi-col-xs-pull-13 {
  right: 54.1667%;
}

.semi-col-xs-offset-13 {
  margin-left: 54.1667%;
}

.semi-col-xs-order-13 {
  order: 13;
}

.semi-col-xs-14 {
  box-sizing: border-box;
  width: 58.3333%;
  display: block;
}

.semi-col-xs-push-14 {
  left: 58.3333%;
}

.semi-col-xs-pull-14 {
  right: 58.3333%;
}

.semi-col-xs-offset-14 {
  margin-left: 58.3333%;
}

.semi-col-xs-order-14 {
  order: 14;
}

.semi-col-xs-15 {
  box-sizing: border-box;
  width: 62.5%;
  display: block;
}

.semi-col-xs-push-15 {
  left: 62.5%;
}

.semi-col-xs-pull-15 {
  right: 62.5%;
}

.semi-col-xs-offset-15 {
  margin-left: 62.5%;
}

.semi-col-xs-order-15 {
  order: 15;
}

.semi-col-xs-16 {
  box-sizing: border-box;
  width: 66.6667%;
  display: block;
}

.semi-col-xs-push-16 {
  left: 66.6667%;
}

.semi-col-xs-pull-16 {
  right: 66.6667%;
}

.semi-col-xs-offset-16 {
  margin-left: 66.6667%;
}

.semi-col-xs-order-16 {
  order: 16;
}

.semi-col-xs-17 {
  box-sizing: border-box;
  width: 70.8333%;
  display: block;
}

.semi-col-xs-push-17 {
  left: 70.8333%;
}

.semi-col-xs-pull-17 {
  right: 70.8333%;
}

.semi-col-xs-offset-17 {
  margin-left: 70.8333%;
}

.semi-col-xs-order-17 {
  order: 17;
}

.semi-col-xs-18 {
  box-sizing: border-box;
  width: 75%;
  display: block;
}

.semi-col-xs-push-18 {
  left: 75%;
}

.semi-col-xs-pull-18 {
  right: 75%;
}

.semi-col-xs-offset-18 {
  margin-left: 75%;
}

.semi-col-xs-order-18 {
  order: 18;
}

.semi-col-xs-19 {
  box-sizing: border-box;
  width: 79.1667%;
  display: block;
}

.semi-col-xs-push-19 {
  left: 79.1667%;
}

.semi-col-xs-pull-19 {
  right: 79.1667%;
}

.semi-col-xs-offset-19 {
  margin-left: 79.1667%;
}

.semi-col-xs-order-19 {
  order: 19;
}

.semi-col-xs-20 {
  box-sizing: border-box;
  width: 83.3333%;
  display: block;
}

.semi-col-xs-push-20 {
  left: 83.3333%;
}

.semi-col-xs-pull-20 {
  right: 83.3333%;
}

.semi-col-xs-offset-20 {
  margin-left: 83.3333%;
}

.semi-col-xs-order-20 {
  order: 20;
}

.semi-col-xs-21 {
  box-sizing: border-box;
  width: 87.5%;
  display: block;
}

.semi-col-xs-push-21 {
  left: 87.5%;
}

.semi-col-xs-pull-21 {
  right: 87.5%;
}

.semi-col-xs-offset-21 {
  margin-left: 87.5%;
}

.semi-col-xs-order-21 {
  order: 21;
}

.semi-col-xs-22 {
  box-sizing: border-box;
  width: 91.6667%;
  display: block;
}

.semi-col-xs-push-22 {
  left: 91.6667%;
}

.semi-col-xs-pull-22 {
  right: 91.6667%;
}

.semi-col-xs-offset-22 {
  margin-left: 91.6667%;
}

.semi-col-xs-order-22 {
  order: 22;
}

.semi-col-xs-23 {
  box-sizing: border-box;
  width: 95.8333%;
  display: block;
}

.semi-col-xs-push-23 {
  left: 95.8333%;
}

.semi-col-xs-pull-23 {
  right: 95.8333%;
}

.semi-col-xs-offset-23 {
  margin-left: 95.8333%;
}

.semi-col-xs-order-23 {
  order: 23;
}

.semi-col-xs-24 {
  box-sizing: border-box;
  width: 100%;
  display: block;
}

.semi-col-xs-push-24 {
  left: 100%;
}

.semi-col-xs-pull-24 {
  right: 100%;
}

.semi-col-xs-offset-24 {
  margin-left: 100%;
}

.semi-col-xs-order-24 {
  order: 24;
}

.semi-rtl .semi-col-xs-1, .semi-rtl .semi-col-xs-2, .semi-rtl .semi-col-xs-3, .semi-rtl .semi-col-xs-4, .semi-rtl .semi-col-xs-5, .semi-rtl .semi-col-xs-6, .semi-rtl .semi-col-xs-7, .semi-rtl .semi-col-xs-8, .semi-rtl .semi-col-xs-9, .semi-rtl .semi-col-xs-10, .semi-rtl .semi-col-xs-11, .semi-rtl .semi-col-xs-12, .semi-rtl .semi-col-xs-13, .semi-rtl .semi-col-xs-14, .semi-rtl .semi-col-xs-15, .semi-rtl .semi-col-xs-16, .semi-rtl .semi-col-xs-17, .semi-rtl .semi-col-xs-18, .semi-rtl .semi-col-xs-19, .semi-rtl .semi-col-xs-20, .semi-rtl .semi-col-xs-21, .semi-rtl .semi-col-xs-22, .semi-rtl .semi-col-xs-23, .semi-rtl .semi-col-xs-24 {
  float: right;
}

.semi-rtl .semi-col-xs-offset-1 {
  margin-left: auto;
  margin-right: 4.16667%;
}

.semi-rtl .semi-col-xs-offset-2 {
  margin-left: auto;
  margin-right: 8.33333%;
}

.semi-rtl .semi-col-xs-offset-3 {
  margin-left: auto;
  margin-right: 12.5%;
}

.semi-rtl .semi-col-xs-offset-4 {
  margin-left: auto;
  margin-right: 16.6667%;
}

.semi-rtl .semi-col-xs-offset-5 {
  margin-left: auto;
  margin-right: 20.8333%;
}

.semi-rtl .semi-col-xs-offset-6 {
  margin-left: auto;
  margin-right: 25%;
}

.semi-rtl .semi-col-xs-offset-7 {
  margin-left: auto;
  margin-right: 29.1667%;
}

.semi-rtl .semi-col-xs-offset-8 {
  margin-left: auto;
  margin-right: 33.3333%;
}

.semi-rtl .semi-col-xs-offset-9 {
  margin-left: auto;
  margin-right: 37.5%;
}

.semi-rtl .semi-col-xs-offset-10 {
  margin-left: auto;
  margin-right: 41.6667%;
}

.semi-rtl .semi-col-xs-offset-11 {
  margin-left: auto;
  margin-right: 45.8333%;
}

.semi-rtl .semi-col-xs-offset-12 {
  margin-left: auto;
  margin-right: 50%;
}

.semi-rtl .semi-col-xs-offset-13 {
  margin-left: auto;
  margin-right: 54.1667%;
}

.semi-rtl .semi-col-xs-offset-14 {
  margin-left: auto;
  margin-right: 58.3333%;
}

.semi-rtl .semi-col-xs-offset-15 {
  margin-left: auto;
  margin-right: 62.5%;
}

.semi-rtl .semi-col-xs-offset-16 {
  margin-left: auto;
  margin-right: 66.6667%;
}

.semi-rtl .semi-col-xs-offset-17 {
  margin-left: auto;
  margin-right: 70.8333%;
}

.semi-rtl .semi-col-xs-offset-18 {
  margin-left: auto;
  margin-right: 75%;
}

.semi-rtl .semi-col-xs-offset-19 {
  margin-left: auto;
  margin-right: 79.1667%;
}

.semi-rtl .semi-col-xs-offset-20 {
  margin-left: auto;
  margin-right: 83.3333%;
}

.semi-rtl .semi-col-xs-offset-21 {
  margin-left: auto;
  margin-right: 87.5%;
}

.semi-rtl .semi-col-xs-offset-22 {
  margin-left: auto;
  margin-right: 91.6667%;
}

.semi-rtl .semi-col-xs-offset-23 {
  margin-left: auto;
  margin-right: 95.8333%;
}

.semi-rtl .semi-col-xs-offset-24 {
  margin-left: auto;
  margin-right: 100%;
}

@media (min-width: 576px) {
  .semi-col-sm-1, .semi-col-sm-2, .semi-col-sm-3, .semi-col-sm-4, .semi-col-sm-5, .semi-col-sm-6, .semi-col-sm-7, .semi-col-sm-8, .semi-col-sm-9, .semi-col-sm-10, .semi-col-sm-11, .semi-col-sm-12, .semi-col-sm-13, .semi-col-sm-14, .semi-col-sm-15, .semi-col-sm-16, .semi-col-sm-17, .semi-col-sm-18, .semi-col-sm-19, .semi-col-sm-20, .semi-col-sm-21, .semi-col-sm-22, .semi-col-sm-23, .semi-col-sm-24 {
    float: left;
    flex: none;
  }

  .semi-col-sm-1 {
    box-sizing: border-box;
    width: 4.16667%;
    display: block;
  }

  .semi-col-sm-push-1 {
    left: 4.16667%;
  }

  .semi-col-sm-pull-1 {
    right: 4.16667%;
  }

  .semi-col-sm-offset-1 {
    margin-left: 4.16667%;
  }

  .semi-col-sm-order-1 {
    order: 1;
  }

  .semi-col-sm-2 {
    box-sizing: border-box;
    width: 8.33333%;
    display: block;
  }

  .semi-col-sm-push-2 {
    left: 8.33333%;
  }

  .semi-col-sm-pull-2 {
    right: 8.33333%;
  }

  .semi-col-sm-offset-2 {
    margin-left: 8.33333%;
  }

  .semi-col-sm-order-2 {
    order: 2;
  }

  .semi-col-sm-3 {
    box-sizing: border-box;
    width: 12.5%;
    display: block;
  }

  .semi-col-sm-push-3 {
    left: 12.5%;
  }

  .semi-col-sm-pull-3 {
    right: 12.5%;
  }

  .semi-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .semi-col-sm-order-3 {
    order: 3;
  }

  .semi-col-sm-4 {
    box-sizing: border-box;
    width: 16.6667%;
    display: block;
  }

  .semi-col-sm-push-4 {
    left: 16.6667%;
  }

  .semi-col-sm-pull-4 {
    right: 16.6667%;
  }

  .semi-col-sm-offset-4 {
    margin-left: 16.6667%;
  }

  .semi-col-sm-order-4 {
    order: 4;
  }

  .semi-col-sm-5 {
    box-sizing: border-box;
    width: 20.8333%;
    display: block;
  }

  .semi-col-sm-push-5 {
    left: 20.8333%;
  }

  .semi-col-sm-pull-5 {
    right: 20.8333%;
  }

  .semi-col-sm-offset-5 {
    margin-left: 20.8333%;
  }

  .semi-col-sm-order-5 {
    order: 5;
  }

  .semi-col-sm-6 {
    box-sizing: border-box;
    width: 25%;
    display: block;
  }

  .semi-col-sm-push-6 {
    left: 25%;
  }

  .semi-col-sm-pull-6 {
    right: 25%;
  }

  .semi-col-sm-offset-6 {
    margin-left: 25%;
  }

  .semi-col-sm-order-6 {
    order: 6;
  }

  .semi-col-sm-7 {
    box-sizing: border-box;
    width: 29.1667%;
    display: block;
  }

  .semi-col-sm-push-7 {
    left: 29.1667%;
  }

  .semi-col-sm-pull-7 {
    right: 29.1667%;
  }

  .semi-col-sm-offset-7 {
    margin-left: 29.1667%;
  }

  .semi-col-sm-order-7 {
    order: 7;
  }

  .semi-col-sm-8 {
    box-sizing: border-box;
    width: 33.3333%;
    display: block;
  }

  .semi-col-sm-push-8 {
    left: 33.3333%;
  }

  .semi-col-sm-pull-8 {
    right: 33.3333%;
  }

  .semi-col-sm-offset-8 {
    margin-left: 33.3333%;
  }

  .semi-col-sm-order-8 {
    order: 8;
  }

  .semi-col-sm-9 {
    box-sizing: border-box;
    width: 37.5%;
    display: block;
  }

  .semi-col-sm-push-9 {
    left: 37.5%;
  }

  .semi-col-sm-pull-9 {
    right: 37.5%;
  }

  .semi-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .semi-col-sm-order-9 {
    order: 9;
  }

  .semi-col-sm-10 {
    box-sizing: border-box;
    width: 41.6667%;
    display: block;
  }

  .semi-col-sm-push-10 {
    left: 41.6667%;
  }

  .semi-col-sm-pull-10 {
    right: 41.6667%;
  }

  .semi-col-sm-offset-10 {
    margin-left: 41.6667%;
  }

  .semi-col-sm-order-10 {
    order: 10;
  }

  .semi-col-sm-11 {
    box-sizing: border-box;
    width: 45.8333%;
    display: block;
  }

  .semi-col-sm-push-11 {
    left: 45.8333%;
  }

  .semi-col-sm-pull-11 {
    right: 45.8333%;
  }

  .semi-col-sm-offset-11 {
    margin-left: 45.8333%;
  }

  .semi-col-sm-order-11 {
    order: 11;
  }

  .semi-col-sm-12 {
    box-sizing: border-box;
    width: 50%;
    display: block;
  }

  .semi-col-sm-push-12 {
    left: 50%;
  }

  .semi-col-sm-pull-12 {
    right: 50%;
  }

  .semi-col-sm-offset-12 {
    margin-left: 50%;
  }

  .semi-col-sm-order-12 {
    order: 12;
  }

  .semi-col-sm-13 {
    box-sizing: border-box;
    width: 54.1667%;
    display: block;
  }

  .semi-col-sm-push-13 {
    left: 54.1667%;
  }

  .semi-col-sm-pull-13 {
    right: 54.1667%;
  }

  .semi-col-sm-offset-13 {
    margin-left: 54.1667%;
  }

  .semi-col-sm-order-13 {
    order: 13;
  }

  .semi-col-sm-14 {
    box-sizing: border-box;
    width: 58.3333%;
    display: block;
  }

  .semi-col-sm-push-14 {
    left: 58.3333%;
  }

  .semi-col-sm-pull-14 {
    right: 58.3333%;
  }

  .semi-col-sm-offset-14 {
    margin-left: 58.3333%;
  }

  .semi-col-sm-order-14 {
    order: 14;
  }

  .semi-col-sm-15 {
    box-sizing: border-box;
    width: 62.5%;
    display: block;
  }

  .semi-col-sm-push-15 {
    left: 62.5%;
  }

  .semi-col-sm-pull-15 {
    right: 62.5%;
  }

  .semi-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .semi-col-sm-order-15 {
    order: 15;
  }

  .semi-col-sm-16 {
    box-sizing: border-box;
    width: 66.6667%;
    display: block;
  }

  .semi-col-sm-push-16 {
    left: 66.6667%;
  }

  .semi-col-sm-pull-16 {
    right: 66.6667%;
  }

  .semi-col-sm-offset-16 {
    margin-left: 66.6667%;
  }

  .semi-col-sm-order-16 {
    order: 16;
  }

  .semi-col-sm-17 {
    box-sizing: border-box;
    width: 70.8333%;
    display: block;
  }

  .semi-col-sm-push-17 {
    left: 70.8333%;
  }

  .semi-col-sm-pull-17 {
    right: 70.8333%;
  }

  .semi-col-sm-offset-17 {
    margin-left: 70.8333%;
  }

  .semi-col-sm-order-17 {
    order: 17;
  }

  .semi-col-sm-18 {
    box-sizing: border-box;
    width: 75%;
    display: block;
  }

  .semi-col-sm-push-18 {
    left: 75%;
  }

  .semi-col-sm-pull-18 {
    right: 75%;
  }

  .semi-col-sm-offset-18 {
    margin-left: 75%;
  }

  .semi-col-sm-order-18 {
    order: 18;
  }

  .semi-col-sm-19 {
    box-sizing: border-box;
    width: 79.1667%;
    display: block;
  }

  .semi-col-sm-push-19 {
    left: 79.1667%;
  }

  .semi-col-sm-pull-19 {
    right: 79.1667%;
  }

  .semi-col-sm-offset-19 {
    margin-left: 79.1667%;
  }

  .semi-col-sm-order-19 {
    order: 19;
  }

  .semi-col-sm-20 {
    box-sizing: border-box;
    width: 83.3333%;
    display: block;
  }

  .semi-col-sm-push-20 {
    left: 83.3333%;
  }

  .semi-col-sm-pull-20 {
    right: 83.3333%;
  }

  .semi-col-sm-offset-20 {
    margin-left: 83.3333%;
  }

  .semi-col-sm-order-20 {
    order: 20;
  }

  .semi-col-sm-21 {
    box-sizing: border-box;
    width: 87.5%;
    display: block;
  }

  .semi-col-sm-push-21 {
    left: 87.5%;
  }

  .semi-col-sm-pull-21 {
    right: 87.5%;
  }

  .semi-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .semi-col-sm-order-21 {
    order: 21;
  }

  .semi-col-sm-22 {
    box-sizing: border-box;
    width: 91.6667%;
    display: block;
  }

  .semi-col-sm-push-22 {
    left: 91.6667%;
  }

  .semi-col-sm-pull-22 {
    right: 91.6667%;
  }

  .semi-col-sm-offset-22 {
    margin-left: 91.6667%;
  }

  .semi-col-sm-order-22 {
    order: 22;
  }

  .semi-col-sm-23 {
    box-sizing: border-box;
    width: 95.8333%;
    display: block;
  }

  .semi-col-sm-push-23 {
    left: 95.8333%;
  }

  .semi-col-sm-pull-23 {
    right: 95.8333%;
  }

  .semi-col-sm-offset-23 {
    margin-left: 95.8333%;
  }

  .semi-col-sm-order-23 {
    order: 23;
  }

  .semi-col-sm-24 {
    box-sizing: border-box;
    width: 100%;
    display: block;
  }

  .semi-col-sm-push-24 {
    left: 100%;
  }

  .semi-col-sm-pull-24 {
    right: 100%;
  }

  .semi-col-sm-offset-24 {
    margin-left: 100%;
  }

  .semi-col-sm-order-24 {
    order: 24;
  }

  .semi-rtl .semi-col-sm-1, .semi-rtl .semi-col-sm-2, .semi-rtl .semi-col-sm-3, .semi-rtl .semi-col-sm-4, .semi-rtl .semi-col-sm-5, .semi-rtl .semi-col-sm-6, .semi-rtl .semi-col-sm-7, .semi-rtl .semi-col-sm-8, .semi-rtl .semi-col-sm-9, .semi-rtl .semi-col-sm-10, .semi-rtl .semi-col-sm-11, .semi-rtl .semi-col-sm-12, .semi-rtl .semi-col-sm-13, .semi-rtl .semi-col-sm-14, .semi-rtl .semi-col-sm-15, .semi-rtl .semi-col-sm-16, .semi-rtl .semi-col-sm-17, .semi-rtl .semi-col-sm-18, .semi-rtl .semi-col-sm-19, .semi-rtl .semi-col-sm-20, .semi-rtl .semi-col-sm-21, .semi-rtl .semi-col-sm-22, .semi-rtl .semi-col-sm-23, .semi-rtl .semi-col-sm-24 {
    float: right;
  }

  .semi-rtl .semi-col-sm-offset-1 {
    margin-left: auto;
    margin-right: 4.16667%;
  }

  .semi-rtl .semi-col-sm-offset-2 {
    margin-left: auto;
    margin-right: 8.33333%;
  }

  .semi-rtl .semi-col-sm-offset-3 {
    margin-left: auto;
    margin-right: 12.5%;
  }

  .semi-rtl .semi-col-sm-offset-4 {
    margin-left: auto;
    margin-right: 16.6667%;
  }

  .semi-rtl .semi-col-sm-offset-5 {
    margin-left: auto;
    margin-right: 20.8333%;
  }

  .semi-rtl .semi-col-sm-offset-6 {
    margin-left: auto;
    margin-right: 25%;
  }

  .semi-rtl .semi-col-sm-offset-7 {
    margin-left: auto;
    margin-right: 29.1667%;
  }

  .semi-rtl .semi-col-sm-offset-8 {
    margin-left: auto;
    margin-right: 33.3333%;
  }

  .semi-rtl .semi-col-sm-offset-9 {
    margin-left: auto;
    margin-right: 37.5%;
  }

  .semi-rtl .semi-col-sm-offset-10 {
    margin-left: auto;
    margin-right: 41.6667%;
  }

  .semi-rtl .semi-col-sm-offset-11 {
    margin-left: auto;
    margin-right: 45.8333%;
  }

  .semi-rtl .semi-col-sm-offset-12 {
    margin-left: auto;
    margin-right: 50%;
  }

  .semi-rtl .semi-col-sm-offset-13 {
    margin-left: auto;
    margin-right: 54.1667%;
  }

  .semi-rtl .semi-col-sm-offset-14 {
    margin-left: auto;
    margin-right: 58.3333%;
  }

  .semi-rtl .semi-col-sm-offset-15 {
    margin-left: auto;
    margin-right: 62.5%;
  }

  .semi-rtl .semi-col-sm-offset-16 {
    margin-left: auto;
    margin-right: 66.6667%;
  }

  .semi-rtl .semi-col-sm-offset-17 {
    margin-left: auto;
    margin-right: 70.8333%;
  }

  .semi-rtl .semi-col-sm-offset-18 {
    margin-left: auto;
    margin-right: 75%;
  }

  .semi-rtl .semi-col-sm-offset-19 {
    margin-left: auto;
    margin-right: 79.1667%;
  }

  .semi-rtl .semi-col-sm-offset-20 {
    margin-left: auto;
    margin-right: 83.3333%;
  }

  .semi-rtl .semi-col-sm-offset-21 {
    margin-left: auto;
    margin-right: 87.5%;
  }

  .semi-rtl .semi-col-sm-offset-22 {
    margin-left: auto;
    margin-right: 91.6667%;
  }

  .semi-rtl .semi-col-sm-offset-23 {
    margin-left: auto;
    margin-right: 95.8333%;
  }

  .semi-rtl .semi-col-sm-offset-24 {
    margin-left: auto;
    margin-right: 100%;
  }
}

@media (min-width: 768px) {
  .semi-col-md-1, .semi-col-md-2, .semi-col-md-3, .semi-col-md-4, .semi-col-md-5, .semi-col-md-6, .semi-col-md-7, .semi-col-md-8, .semi-col-md-9, .semi-col-md-10, .semi-col-md-11, .semi-col-md-12, .semi-col-md-13, .semi-col-md-14, .semi-col-md-15, .semi-col-md-16, .semi-col-md-17, .semi-col-md-18, .semi-col-md-19, .semi-col-md-20, .semi-col-md-21, .semi-col-md-22, .semi-col-md-23, .semi-col-md-24 {
    float: left;
    flex: none;
  }

  .semi-col-md-1 {
    box-sizing: border-box;
    width: 4.16667%;
    display: block;
  }

  .semi-col-md-push-1 {
    left: 4.16667%;
  }

  .semi-col-md-pull-1 {
    right: 4.16667%;
  }

  .semi-col-md-offset-1 {
    margin-left: 4.16667%;
  }

  .semi-col-md-order-1 {
    order: 1;
  }

  .semi-col-md-2 {
    box-sizing: border-box;
    width: 8.33333%;
    display: block;
  }

  .semi-col-md-push-2 {
    left: 8.33333%;
  }

  .semi-col-md-pull-2 {
    right: 8.33333%;
  }

  .semi-col-md-offset-2 {
    margin-left: 8.33333%;
  }

  .semi-col-md-order-2 {
    order: 2;
  }

  .semi-col-md-3 {
    box-sizing: border-box;
    width: 12.5%;
    display: block;
  }

  .semi-col-md-push-3 {
    left: 12.5%;
  }

  .semi-col-md-pull-3 {
    right: 12.5%;
  }

  .semi-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .semi-col-md-order-3 {
    order: 3;
  }

  .semi-col-md-4 {
    box-sizing: border-box;
    width: 16.6667%;
    display: block;
  }

  .semi-col-md-push-4 {
    left: 16.6667%;
  }

  .semi-col-md-pull-4 {
    right: 16.6667%;
  }

  .semi-col-md-offset-4 {
    margin-left: 16.6667%;
  }

  .semi-col-md-order-4 {
    order: 4;
  }

  .semi-col-md-5 {
    box-sizing: border-box;
    width: 20.8333%;
    display: block;
  }

  .semi-col-md-push-5 {
    left: 20.8333%;
  }

  .semi-col-md-pull-5 {
    right: 20.8333%;
  }

  .semi-col-md-offset-5 {
    margin-left: 20.8333%;
  }

  .semi-col-md-order-5 {
    order: 5;
  }

  .semi-col-md-6 {
    box-sizing: border-box;
    width: 25%;
    display: block;
  }

  .semi-col-md-push-6 {
    left: 25%;
  }

  .semi-col-md-pull-6 {
    right: 25%;
  }

  .semi-col-md-offset-6 {
    margin-left: 25%;
  }

  .semi-col-md-order-6 {
    order: 6;
  }

  .semi-col-md-7 {
    box-sizing: border-box;
    width: 29.1667%;
    display: block;
  }

  .semi-col-md-push-7 {
    left: 29.1667%;
  }

  .semi-col-md-pull-7 {
    right: 29.1667%;
  }

  .semi-col-md-offset-7 {
    margin-left: 29.1667%;
  }

  .semi-col-md-order-7 {
    order: 7;
  }

  .semi-col-md-8 {
    box-sizing: border-box;
    width: 33.3333%;
    display: block;
  }

  .semi-col-md-push-8 {
    left: 33.3333%;
  }

  .semi-col-md-pull-8 {
    right: 33.3333%;
  }

  .semi-col-md-offset-8 {
    margin-left: 33.3333%;
  }

  .semi-col-md-order-8 {
    order: 8;
  }

  .semi-col-md-9 {
    box-sizing: border-box;
    width: 37.5%;
    display: block;
  }

  .semi-col-md-push-9 {
    left: 37.5%;
  }

  .semi-col-md-pull-9 {
    right: 37.5%;
  }

  .semi-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .semi-col-md-order-9 {
    order: 9;
  }

  .semi-col-md-10 {
    box-sizing: border-box;
    width: 41.6667%;
    display: block;
  }

  .semi-col-md-push-10 {
    left: 41.6667%;
  }

  .semi-col-md-pull-10 {
    right: 41.6667%;
  }

  .semi-col-md-offset-10 {
    margin-left: 41.6667%;
  }

  .semi-col-md-order-10 {
    order: 10;
  }

  .semi-col-md-11 {
    box-sizing: border-box;
    width: 45.8333%;
    display: block;
  }

  .semi-col-md-push-11 {
    left: 45.8333%;
  }

  .semi-col-md-pull-11 {
    right: 45.8333%;
  }

  .semi-col-md-offset-11 {
    margin-left: 45.8333%;
  }

  .semi-col-md-order-11 {
    order: 11;
  }

  .semi-col-md-12 {
    box-sizing: border-box;
    width: 50%;
    display: block;
  }

  .semi-col-md-push-12 {
    left: 50%;
  }

  .semi-col-md-pull-12 {
    right: 50%;
  }

  .semi-col-md-offset-12 {
    margin-left: 50%;
  }

  .semi-col-md-order-12 {
    order: 12;
  }

  .semi-col-md-13 {
    box-sizing: border-box;
    width: 54.1667%;
    display: block;
  }

  .semi-col-md-push-13 {
    left: 54.1667%;
  }

  .semi-col-md-pull-13 {
    right: 54.1667%;
  }

  .semi-col-md-offset-13 {
    margin-left: 54.1667%;
  }

  .semi-col-md-order-13 {
    order: 13;
  }

  .semi-col-md-14 {
    box-sizing: border-box;
    width: 58.3333%;
    display: block;
  }

  .semi-col-md-push-14 {
    left: 58.3333%;
  }

  .semi-col-md-pull-14 {
    right: 58.3333%;
  }

  .semi-col-md-offset-14 {
    margin-left: 58.3333%;
  }

  .semi-col-md-order-14 {
    order: 14;
  }

  .semi-col-md-15 {
    box-sizing: border-box;
    width: 62.5%;
    display: block;
  }

  .semi-col-md-push-15 {
    left: 62.5%;
  }

  .semi-col-md-pull-15 {
    right: 62.5%;
  }

  .semi-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .semi-col-md-order-15 {
    order: 15;
  }

  .semi-col-md-16 {
    box-sizing: border-box;
    width: 66.6667%;
    display: block;
  }

  .semi-col-md-push-16 {
    left: 66.6667%;
  }

  .semi-col-md-pull-16 {
    right: 66.6667%;
  }

  .semi-col-md-offset-16 {
    margin-left: 66.6667%;
  }

  .semi-col-md-order-16 {
    order: 16;
  }

  .semi-col-md-17 {
    box-sizing: border-box;
    width: 70.8333%;
    display: block;
  }

  .semi-col-md-push-17 {
    left: 70.8333%;
  }

  .semi-col-md-pull-17 {
    right: 70.8333%;
  }

  .semi-col-md-offset-17 {
    margin-left: 70.8333%;
  }

  .semi-col-md-order-17 {
    order: 17;
  }

  .semi-col-md-18 {
    box-sizing: border-box;
    width: 75%;
    display: block;
  }

  .semi-col-md-push-18 {
    left: 75%;
  }

  .semi-col-md-pull-18 {
    right: 75%;
  }

  .semi-col-md-offset-18 {
    margin-left: 75%;
  }

  .semi-col-md-order-18 {
    order: 18;
  }

  .semi-col-md-19 {
    box-sizing: border-box;
    width: 79.1667%;
    display: block;
  }

  .semi-col-md-push-19 {
    left: 79.1667%;
  }

  .semi-col-md-pull-19 {
    right: 79.1667%;
  }

  .semi-col-md-offset-19 {
    margin-left: 79.1667%;
  }

  .semi-col-md-order-19 {
    order: 19;
  }

  .semi-col-md-20 {
    box-sizing: border-box;
    width: 83.3333%;
    display: block;
  }

  .semi-col-md-push-20 {
    left: 83.3333%;
  }

  .semi-col-md-pull-20 {
    right: 83.3333%;
  }

  .semi-col-md-offset-20 {
    margin-left: 83.3333%;
  }

  .semi-col-md-order-20 {
    order: 20;
  }

  .semi-col-md-21 {
    box-sizing: border-box;
    width: 87.5%;
    display: block;
  }

  .semi-col-md-push-21 {
    left: 87.5%;
  }

  .semi-col-md-pull-21 {
    right: 87.5%;
  }

  .semi-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .semi-col-md-order-21 {
    order: 21;
  }

  .semi-col-md-22 {
    box-sizing: border-box;
    width: 91.6667%;
    display: block;
  }

  .semi-col-md-push-22 {
    left: 91.6667%;
  }

  .semi-col-md-pull-22 {
    right: 91.6667%;
  }

  .semi-col-md-offset-22 {
    margin-left: 91.6667%;
  }

  .semi-col-md-order-22 {
    order: 22;
  }

  .semi-col-md-23 {
    box-sizing: border-box;
    width: 95.8333%;
    display: block;
  }

  .semi-col-md-push-23 {
    left: 95.8333%;
  }

  .semi-col-md-pull-23 {
    right: 95.8333%;
  }

  .semi-col-md-offset-23 {
    margin-left: 95.8333%;
  }

  .semi-col-md-order-23 {
    order: 23;
  }

  .semi-col-md-24 {
    box-sizing: border-box;
    width: 100%;
    display: block;
  }

  .semi-col-md-push-24 {
    left: 100%;
  }

  .semi-col-md-pull-24 {
    right: 100%;
  }

  .semi-col-md-offset-24 {
    margin-left: 100%;
  }

  .semi-col-md-order-24 {
    order: 24;
  }

  .semi-rtl .semi-col-md-1, .semi-rtl .semi-col-md-2, .semi-rtl .semi-col-md-3, .semi-rtl .semi-col-md-4, .semi-rtl .semi-col-md-5, .semi-rtl .semi-col-md-6, .semi-rtl .semi-col-md-7, .semi-rtl .semi-col-md-8, .semi-rtl .semi-col-md-9, .semi-rtl .semi-col-md-10, .semi-rtl .semi-col-md-11, .semi-rtl .semi-col-md-12, .semi-rtl .semi-col-md-13, .semi-rtl .semi-col-md-14, .semi-rtl .semi-col-md-15, .semi-rtl .semi-col-md-16, .semi-rtl .semi-col-md-17, .semi-rtl .semi-col-md-18, .semi-rtl .semi-col-md-19, .semi-rtl .semi-col-md-20, .semi-rtl .semi-col-md-21, .semi-rtl .semi-col-md-22, .semi-rtl .semi-col-md-23, .semi-rtl .semi-col-md-24 {
    float: right;
  }

  .semi-rtl .semi-col-md-offset-1 {
    margin-left: auto;
    margin-right: 4.16667%;
  }

  .semi-rtl .semi-col-md-offset-2 {
    margin-left: auto;
    margin-right: 8.33333%;
  }

  .semi-rtl .semi-col-md-offset-3 {
    margin-left: auto;
    margin-right: 12.5%;
  }

  .semi-rtl .semi-col-md-offset-4 {
    margin-left: auto;
    margin-right: 16.6667%;
  }

  .semi-rtl .semi-col-md-offset-5 {
    margin-left: auto;
    margin-right: 20.8333%;
  }

  .semi-rtl .semi-col-md-offset-6 {
    margin-left: auto;
    margin-right: 25%;
  }

  .semi-rtl .semi-col-md-offset-7 {
    margin-left: auto;
    margin-right: 29.1667%;
  }

  .semi-rtl .semi-col-md-offset-8 {
    margin-left: auto;
    margin-right: 33.3333%;
  }

  .semi-rtl .semi-col-md-offset-9 {
    margin-left: auto;
    margin-right: 37.5%;
  }

  .semi-rtl .semi-col-md-offset-10 {
    margin-left: auto;
    margin-right: 41.6667%;
  }

  .semi-rtl .semi-col-md-offset-11 {
    margin-left: auto;
    margin-right: 45.8333%;
  }

  .semi-rtl .semi-col-md-offset-12 {
    margin-left: auto;
    margin-right: 50%;
  }

  .semi-rtl .semi-col-md-offset-13 {
    margin-left: auto;
    margin-right: 54.1667%;
  }

  .semi-rtl .semi-col-md-offset-14 {
    margin-left: auto;
    margin-right: 58.3333%;
  }

  .semi-rtl .semi-col-md-offset-15 {
    margin-left: auto;
    margin-right: 62.5%;
  }

  .semi-rtl .semi-col-md-offset-16 {
    margin-left: auto;
    margin-right: 66.6667%;
  }

  .semi-rtl .semi-col-md-offset-17 {
    margin-left: auto;
    margin-right: 70.8333%;
  }

  .semi-rtl .semi-col-md-offset-18 {
    margin-left: auto;
    margin-right: 75%;
  }

  .semi-rtl .semi-col-md-offset-19 {
    margin-left: auto;
    margin-right: 79.1667%;
  }

  .semi-rtl .semi-col-md-offset-20 {
    margin-left: auto;
    margin-right: 83.3333%;
  }

  .semi-rtl .semi-col-md-offset-21 {
    margin-left: auto;
    margin-right: 87.5%;
  }

  .semi-rtl .semi-col-md-offset-22 {
    margin-left: auto;
    margin-right: 91.6667%;
  }

  .semi-rtl .semi-col-md-offset-23 {
    margin-left: auto;
    margin-right: 95.8333%;
  }

  .semi-rtl .semi-col-md-offset-24 {
    margin-left: auto;
    margin-right: 100%;
  }
}

@media (min-width: 992px) {
  .semi-col-lg-1, .semi-col-lg-2, .semi-col-lg-3, .semi-col-lg-4, .semi-col-lg-5, .semi-col-lg-6, .semi-col-lg-7, .semi-col-lg-8, .semi-col-lg-9, .semi-col-lg-10, .semi-col-lg-11, .semi-col-lg-12, .semi-col-lg-13, .semi-col-lg-14, .semi-col-lg-15, .semi-col-lg-16, .semi-col-lg-17, .semi-col-lg-18, .semi-col-lg-19, .semi-col-lg-20, .semi-col-lg-21, .semi-col-lg-22, .semi-col-lg-23, .semi-col-lg-24 {
    float: left;
    flex: none;
  }

  .semi-col-lg-1 {
    box-sizing: border-box;
    width: 4.16667%;
    display: block;
  }

  .semi-col-lg-push-1 {
    left: 4.16667%;
  }

  .semi-col-lg-pull-1 {
    right: 4.16667%;
  }

  .semi-col-lg-offset-1 {
    margin-left: 4.16667%;
  }

  .semi-col-lg-order-1 {
    order: 1;
  }

  .semi-col-lg-2 {
    box-sizing: border-box;
    width: 8.33333%;
    display: block;
  }

  .semi-col-lg-push-2 {
    left: 8.33333%;
  }

  .semi-col-lg-pull-2 {
    right: 8.33333%;
  }

  .semi-col-lg-offset-2 {
    margin-left: 8.33333%;
  }

  .semi-col-lg-order-2 {
    order: 2;
  }

  .semi-col-lg-3 {
    box-sizing: border-box;
    width: 12.5%;
    display: block;
  }

  .semi-col-lg-push-3 {
    left: 12.5%;
  }

  .semi-col-lg-pull-3 {
    right: 12.5%;
  }

  .semi-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .semi-col-lg-order-3 {
    order: 3;
  }

  .semi-col-lg-4 {
    box-sizing: border-box;
    width: 16.6667%;
    display: block;
  }

  .semi-col-lg-push-4 {
    left: 16.6667%;
  }

  .semi-col-lg-pull-4 {
    right: 16.6667%;
  }

  .semi-col-lg-offset-4 {
    margin-left: 16.6667%;
  }

  .semi-col-lg-order-4 {
    order: 4;
  }

  .semi-col-lg-5 {
    box-sizing: border-box;
    width: 20.8333%;
    display: block;
  }

  .semi-col-lg-push-5 {
    left: 20.8333%;
  }

  .semi-col-lg-pull-5 {
    right: 20.8333%;
  }

  .semi-col-lg-offset-5 {
    margin-left: 20.8333%;
  }

  .semi-col-lg-order-5 {
    order: 5;
  }

  .semi-col-lg-6 {
    box-sizing: border-box;
    width: 25%;
    display: block;
  }

  .semi-col-lg-push-6 {
    left: 25%;
  }

  .semi-col-lg-pull-6 {
    right: 25%;
  }

  .semi-col-lg-offset-6 {
    margin-left: 25%;
  }

  .semi-col-lg-order-6 {
    order: 6;
  }

  .semi-col-lg-7 {
    box-sizing: border-box;
    width: 29.1667%;
    display: block;
  }

  .semi-col-lg-push-7 {
    left: 29.1667%;
  }

  .semi-col-lg-pull-7 {
    right: 29.1667%;
  }

  .semi-col-lg-offset-7 {
    margin-left: 29.1667%;
  }

  .semi-col-lg-order-7 {
    order: 7;
  }

  .semi-col-lg-8 {
    box-sizing: border-box;
    width: 33.3333%;
    display: block;
  }

  .semi-col-lg-push-8 {
    left: 33.3333%;
  }

  .semi-col-lg-pull-8 {
    right: 33.3333%;
  }

  .semi-col-lg-offset-8 {
    margin-left: 33.3333%;
  }

  .semi-col-lg-order-8 {
    order: 8;
  }

  .semi-col-lg-9 {
    box-sizing: border-box;
    width: 37.5%;
    display: block;
  }

  .semi-col-lg-push-9 {
    left: 37.5%;
  }

  .semi-col-lg-pull-9 {
    right: 37.5%;
  }

  .semi-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .semi-col-lg-order-9 {
    order: 9;
  }

  .semi-col-lg-10 {
    box-sizing: border-box;
    width: 41.6667%;
    display: block;
  }

  .semi-col-lg-push-10 {
    left: 41.6667%;
  }

  .semi-col-lg-pull-10 {
    right: 41.6667%;
  }

  .semi-col-lg-offset-10 {
    margin-left: 41.6667%;
  }

  .semi-col-lg-order-10 {
    order: 10;
  }

  .semi-col-lg-11 {
    box-sizing: border-box;
    width: 45.8333%;
    display: block;
  }

  .semi-col-lg-push-11 {
    left: 45.8333%;
  }

  .semi-col-lg-pull-11 {
    right: 45.8333%;
  }

  .semi-col-lg-offset-11 {
    margin-left: 45.8333%;
  }

  .semi-col-lg-order-11 {
    order: 11;
  }

  .semi-col-lg-12 {
    box-sizing: border-box;
    width: 50%;
    display: block;
  }

  .semi-col-lg-push-12 {
    left: 50%;
  }

  .semi-col-lg-pull-12 {
    right: 50%;
  }

  .semi-col-lg-offset-12 {
    margin-left: 50%;
  }

  .semi-col-lg-order-12 {
    order: 12;
  }

  .semi-col-lg-13 {
    box-sizing: border-box;
    width: 54.1667%;
    display: block;
  }

  .semi-col-lg-push-13 {
    left: 54.1667%;
  }

  .semi-col-lg-pull-13 {
    right: 54.1667%;
  }

  .semi-col-lg-offset-13 {
    margin-left: 54.1667%;
  }

  .semi-col-lg-order-13 {
    order: 13;
  }

  .semi-col-lg-14 {
    box-sizing: border-box;
    width: 58.3333%;
    display: block;
  }

  .semi-col-lg-push-14 {
    left: 58.3333%;
  }

  .semi-col-lg-pull-14 {
    right: 58.3333%;
  }

  .semi-col-lg-offset-14 {
    margin-left: 58.3333%;
  }

  .semi-col-lg-order-14 {
    order: 14;
  }

  .semi-col-lg-15 {
    box-sizing: border-box;
    width: 62.5%;
    display: block;
  }

  .semi-col-lg-push-15 {
    left: 62.5%;
  }

  .semi-col-lg-pull-15 {
    right: 62.5%;
  }

  .semi-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .semi-col-lg-order-15 {
    order: 15;
  }

  .semi-col-lg-16 {
    box-sizing: border-box;
    width: 66.6667%;
    display: block;
  }

  .semi-col-lg-push-16 {
    left: 66.6667%;
  }

  .semi-col-lg-pull-16 {
    right: 66.6667%;
  }

  .semi-col-lg-offset-16 {
    margin-left: 66.6667%;
  }

  .semi-col-lg-order-16 {
    order: 16;
  }

  .semi-col-lg-17 {
    box-sizing: border-box;
    width: 70.8333%;
    display: block;
  }

  .semi-col-lg-push-17 {
    left: 70.8333%;
  }

  .semi-col-lg-pull-17 {
    right: 70.8333%;
  }

  .semi-col-lg-offset-17 {
    margin-left: 70.8333%;
  }

  .semi-col-lg-order-17 {
    order: 17;
  }

  .semi-col-lg-18 {
    box-sizing: border-box;
    width: 75%;
    display: block;
  }

  .semi-col-lg-push-18 {
    left: 75%;
  }

  .semi-col-lg-pull-18 {
    right: 75%;
  }

  .semi-col-lg-offset-18 {
    margin-left: 75%;
  }

  .semi-col-lg-order-18 {
    order: 18;
  }

  .semi-col-lg-19 {
    box-sizing: border-box;
    width: 79.1667%;
    display: block;
  }

  .semi-col-lg-push-19 {
    left: 79.1667%;
  }

  .semi-col-lg-pull-19 {
    right: 79.1667%;
  }

  .semi-col-lg-offset-19 {
    margin-left: 79.1667%;
  }

  .semi-col-lg-order-19 {
    order: 19;
  }

  .semi-col-lg-20 {
    box-sizing: border-box;
    width: 83.3333%;
    display: block;
  }

  .semi-col-lg-push-20 {
    left: 83.3333%;
  }

  .semi-col-lg-pull-20 {
    right: 83.3333%;
  }

  .semi-col-lg-offset-20 {
    margin-left: 83.3333%;
  }

  .semi-col-lg-order-20 {
    order: 20;
  }

  .semi-col-lg-21 {
    box-sizing: border-box;
    width: 87.5%;
    display: block;
  }

  .semi-col-lg-push-21 {
    left: 87.5%;
  }

  .semi-col-lg-pull-21 {
    right: 87.5%;
  }

  .semi-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .semi-col-lg-order-21 {
    order: 21;
  }

  .semi-col-lg-22 {
    box-sizing: border-box;
    width: 91.6667%;
    display: block;
  }

  .semi-col-lg-push-22 {
    left: 91.6667%;
  }

  .semi-col-lg-pull-22 {
    right: 91.6667%;
  }

  .semi-col-lg-offset-22 {
    margin-left: 91.6667%;
  }

  .semi-col-lg-order-22 {
    order: 22;
  }

  .semi-col-lg-23 {
    box-sizing: border-box;
    width: 95.8333%;
    display: block;
  }

  .semi-col-lg-push-23 {
    left: 95.8333%;
  }

  .semi-col-lg-pull-23 {
    right: 95.8333%;
  }

  .semi-col-lg-offset-23 {
    margin-left: 95.8333%;
  }

  .semi-col-lg-order-23 {
    order: 23;
  }

  .semi-col-lg-24 {
    box-sizing: border-box;
    width: 100%;
    display: block;
  }

  .semi-col-lg-push-24 {
    left: 100%;
  }

  .semi-col-lg-pull-24 {
    right: 100%;
  }

  .semi-col-lg-offset-24 {
    margin-left: 100%;
  }

  .semi-col-lg-order-24 {
    order: 24;
  }

  .semi-rtl .semi-col-lg-1, .semi-rtl .semi-col-lg-2, .semi-rtl .semi-col-lg-3, .semi-rtl .semi-col-lg-4, .semi-rtl .semi-col-lg-5, .semi-rtl .semi-col-lg-6, .semi-rtl .semi-col-lg-7, .semi-rtl .semi-col-lg-8, .semi-rtl .semi-col-lg-9, .semi-rtl .semi-col-lg-10, .semi-rtl .semi-col-lg-11, .semi-rtl .semi-col-lg-12, .semi-rtl .semi-col-lg-13, .semi-rtl .semi-col-lg-14, .semi-rtl .semi-col-lg-15, .semi-rtl .semi-col-lg-16, .semi-rtl .semi-col-lg-17, .semi-rtl .semi-col-lg-18, .semi-rtl .semi-col-lg-19, .semi-rtl .semi-col-lg-20, .semi-rtl .semi-col-lg-21, .semi-rtl .semi-col-lg-22, .semi-rtl .semi-col-lg-23, .semi-rtl .semi-col-lg-24 {
    float: right;
  }

  .semi-rtl .semi-col-lg-offset-1 {
    margin-left: auto;
    margin-right: 4.16667%;
  }

  .semi-rtl .semi-col-lg-offset-2 {
    margin-left: auto;
    margin-right: 8.33333%;
  }

  .semi-rtl .semi-col-lg-offset-3 {
    margin-left: auto;
    margin-right: 12.5%;
  }

  .semi-rtl .semi-col-lg-offset-4 {
    margin-left: auto;
    margin-right: 16.6667%;
  }

  .semi-rtl .semi-col-lg-offset-5 {
    margin-left: auto;
    margin-right: 20.8333%;
  }

  .semi-rtl .semi-col-lg-offset-6 {
    margin-left: auto;
    margin-right: 25%;
  }

  .semi-rtl .semi-col-lg-offset-7 {
    margin-left: auto;
    margin-right: 29.1667%;
  }

  .semi-rtl .semi-col-lg-offset-8 {
    margin-left: auto;
    margin-right: 33.3333%;
  }

  .semi-rtl .semi-col-lg-offset-9 {
    margin-left: auto;
    margin-right: 37.5%;
  }

  .semi-rtl .semi-col-lg-offset-10 {
    margin-left: auto;
    margin-right: 41.6667%;
  }

  .semi-rtl .semi-col-lg-offset-11 {
    margin-left: auto;
    margin-right: 45.8333%;
  }

  .semi-rtl .semi-col-lg-offset-12 {
    margin-left: auto;
    margin-right: 50%;
  }

  .semi-rtl .semi-col-lg-offset-13 {
    margin-left: auto;
    margin-right: 54.1667%;
  }

  .semi-rtl .semi-col-lg-offset-14 {
    margin-left: auto;
    margin-right: 58.3333%;
  }

  .semi-rtl .semi-col-lg-offset-15 {
    margin-left: auto;
    margin-right: 62.5%;
  }

  .semi-rtl .semi-col-lg-offset-16 {
    margin-left: auto;
    margin-right: 66.6667%;
  }

  .semi-rtl .semi-col-lg-offset-17 {
    margin-left: auto;
    margin-right: 70.8333%;
  }

  .semi-rtl .semi-col-lg-offset-18 {
    margin-left: auto;
    margin-right: 75%;
  }

  .semi-rtl .semi-col-lg-offset-19 {
    margin-left: auto;
    margin-right: 79.1667%;
  }

  .semi-rtl .semi-col-lg-offset-20 {
    margin-left: auto;
    margin-right: 83.3333%;
  }

  .semi-rtl .semi-col-lg-offset-21 {
    margin-left: auto;
    margin-right: 87.5%;
  }

  .semi-rtl .semi-col-lg-offset-22 {
    margin-left: auto;
    margin-right: 91.6667%;
  }

  .semi-rtl .semi-col-lg-offset-23 {
    margin-left: auto;
    margin-right: 95.8333%;
  }

  .semi-rtl .semi-col-lg-offset-24 {
    margin-left: auto;
    margin-right: 100%;
  }
}

@media (min-width: 1200px) {
  .semi-col-xl-1, .semi-col-xl-2, .semi-col-xl-3, .semi-col-xl-4, .semi-col-xl-5, .semi-col-xl-6, .semi-col-xl-7, .semi-col-xl-8, .semi-col-xl-9, .semi-col-xl-10, .semi-col-xl-11, .semi-col-xl-12, .semi-col-xl-13, .semi-col-xl-14, .semi-col-xl-15, .semi-col-xl-16, .semi-col-xl-17, .semi-col-xl-18, .semi-col-xl-19, .semi-col-xl-20, .semi-col-xl-21, .semi-col-xl-22, .semi-col-xl-23, .semi-col-xl-24 {
    float: left;
    flex: none;
  }

  .semi-col-xl-1 {
    box-sizing: border-box;
    width: 4.16667%;
    display: block;
  }

  .semi-col-xl-push-1 {
    left: 4.16667%;
  }

  .semi-col-xl-pull-1 {
    right: 4.16667%;
  }

  .semi-col-xl-offset-1 {
    margin-left: 4.16667%;
  }

  .semi-col-xl-order-1 {
    order: 1;
  }

  .semi-col-xl-2 {
    box-sizing: border-box;
    width: 8.33333%;
    display: block;
  }

  .semi-col-xl-push-2 {
    left: 8.33333%;
  }

  .semi-col-xl-pull-2 {
    right: 8.33333%;
  }

  .semi-col-xl-offset-2 {
    margin-left: 8.33333%;
  }

  .semi-col-xl-order-2 {
    order: 2;
  }

  .semi-col-xl-3 {
    box-sizing: border-box;
    width: 12.5%;
    display: block;
  }

  .semi-col-xl-push-3 {
    left: 12.5%;
  }

  .semi-col-xl-pull-3 {
    right: 12.5%;
  }

  .semi-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .semi-col-xl-order-3 {
    order: 3;
  }

  .semi-col-xl-4 {
    box-sizing: border-box;
    width: 16.6667%;
    display: block;
  }

  .semi-col-xl-push-4 {
    left: 16.6667%;
  }

  .semi-col-xl-pull-4 {
    right: 16.6667%;
  }

  .semi-col-xl-offset-4 {
    margin-left: 16.6667%;
  }

  .semi-col-xl-order-4 {
    order: 4;
  }

  .semi-col-xl-5 {
    box-sizing: border-box;
    width: 20.8333%;
    display: block;
  }

  .semi-col-xl-push-5 {
    left: 20.8333%;
  }

  .semi-col-xl-pull-5 {
    right: 20.8333%;
  }

  .semi-col-xl-offset-5 {
    margin-left: 20.8333%;
  }

  .semi-col-xl-order-5 {
    order: 5;
  }

  .semi-col-xl-6 {
    box-sizing: border-box;
    width: 25%;
    display: block;
  }

  .semi-col-xl-push-6 {
    left: 25%;
  }

  .semi-col-xl-pull-6 {
    right: 25%;
  }

  .semi-col-xl-offset-6 {
    margin-left: 25%;
  }

  .semi-col-xl-order-6 {
    order: 6;
  }

  .semi-col-xl-7 {
    box-sizing: border-box;
    width: 29.1667%;
    display: block;
  }

  .semi-col-xl-push-7 {
    left: 29.1667%;
  }

  .semi-col-xl-pull-7 {
    right: 29.1667%;
  }

  .semi-col-xl-offset-7 {
    margin-left: 29.1667%;
  }

  .semi-col-xl-order-7 {
    order: 7;
  }

  .semi-col-xl-8 {
    box-sizing: border-box;
    width: 33.3333%;
    display: block;
  }

  .semi-col-xl-push-8 {
    left: 33.3333%;
  }

  .semi-col-xl-pull-8 {
    right: 33.3333%;
  }

  .semi-col-xl-offset-8 {
    margin-left: 33.3333%;
  }

  .semi-col-xl-order-8 {
    order: 8;
  }

  .semi-col-xl-9 {
    box-sizing: border-box;
    width: 37.5%;
    display: block;
  }

  .semi-col-xl-push-9 {
    left: 37.5%;
  }

  .semi-col-xl-pull-9 {
    right: 37.5%;
  }

  .semi-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .semi-col-xl-order-9 {
    order: 9;
  }

  .semi-col-xl-10 {
    box-sizing: border-box;
    width: 41.6667%;
    display: block;
  }

  .semi-col-xl-push-10 {
    left: 41.6667%;
  }

  .semi-col-xl-pull-10 {
    right: 41.6667%;
  }

  .semi-col-xl-offset-10 {
    margin-left: 41.6667%;
  }

  .semi-col-xl-order-10 {
    order: 10;
  }

  .semi-col-xl-11 {
    box-sizing: border-box;
    width: 45.8333%;
    display: block;
  }

  .semi-col-xl-push-11 {
    left: 45.8333%;
  }

  .semi-col-xl-pull-11 {
    right: 45.8333%;
  }

  .semi-col-xl-offset-11 {
    margin-left: 45.8333%;
  }

  .semi-col-xl-order-11 {
    order: 11;
  }

  .semi-col-xl-12 {
    box-sizing: border-box;
    width: 50%;
    display: block;
  }

  .semi-col-xl-push-12 {
    left: 50%;
  }

  .semi-col-xl-pull-12 {
    right: 50%;
  }

  .semi-col-xl-offset-12 {
    margin-left: 50%;
  }

  .semi-col-xl-order-12 {
    order: 12;
  }

  .semi-col-xl-13 {
    box-sizing: border-box;
    width: 54.1667%;
    display: block;
  }

  .semi-col-xl-push-13 {
    left: 54.1667%;
  }

  .semi-col-xl-pull-13 {
    right: 54.1667%;
  }

  .semi-col-xl-offset-13 {
    margin-left: 54.1667%;
  }

  .semi-col-xl-order-13 {
    order: 13;
  }

  .semi-col-xl-14 {
    box-sizing: border-box;
    width: 58.3333%;
    display: block;
  }

  .semi-col-xl-push-14 {
    left: 58.3333%;
  }

  .semi-col-xl-pull-14 {
    right: 58.3333%;
  }

  .semi-col-xl-offset-14 {
    margin-left: 58.3333%;
  }

  .semi-col-xl-order-14 {
    order: 14;
  }

  .semi-col-xl-15 {
    box-sizing: border-box;
    width: 62.5%;
    display: block;
  }

  .semi-col-xl-push-15 {
    left: 62.5%;
  }

  .semi-col-xl-pull-15 {
    right: 62.5%;
  }

  .semi-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .semi-col-xl-order-15 {
    order: 15;
  }

  .semi-col-xl-16 {
    box-sizing: border-box;
    width: 66.6667%;
    display: block;
  }

  .semi-col-xl-push-16 {
    left: 66.6667%;
  }

  .semi-col-xl-pull-16 {
    right: 66.6667%;
  }

  .semi-col-xl-offset-16 {
    margin-left: 66.6667%;
  }

  .semi-col-xl-order-16 {
    order: 16;
  }

  .semi-col-xl-17 {
    box-sizing: border-box;
    width: 70.8333%;
    display: block;
  }

  .semi-col-xl-push-17 {
    left: 70.8333%;
  }

  .semi-col-xl-pull-17 {
    right: 70.8333%;
  }

  .semi-col-xl-offset-17 {
    margin-left: 70.8333%;
  }

  .semi-col-xl-order-17 {
    order: 17;
  }

  .semi-col-xl-18 {
    box-sizing: border-box;
    width: 75%;
    display: block;
  }

  .semi-col-xl-push-18 {
    left: 75%;
  }

  .semi-col-xl-pull-18 {
    right: 75%;
  }

  .semi-col-xl-offset-18 {
    margin-left: 75%;
  }

  .semi-col-xl-order-18 {
    order: 18;
  }

  .semi-col-xl-19 {
    box-sizing: border-box;
    width: 79.1667%;
    display: block;
  }

  .semi-col-xl-push-19 {
    left: 79.1667%;
  }

  .semi-col-xl-pull-19 {
    right: 79.1667%;
  }

  .semi-col-xl-offset-19 {
    margin-left: 79.1667%;
  }

  .semi-col-xl-order-19 {
    order: 19;
  }

  .semi-col-xl-20 {
    box-sizing: border-box;
    width: 83.3333%;
    display: block;
  }

  .semi-col-xl-push-20 {
    left: 83.3333%;
  }

  .semi-col-xl-pull-20 {
    right: 83.3333%;
  }

  .semi-col-xl-offset-20 {
    margin-left: 83.3333%;
  }

  .semi-col-xl-order-20 {
    order: 20;
  }

  .semi-col-xl-21 {
    box-sizing: border-box;
    width: 87.5%;
    display: block;
  }

  .semi-col-xl-push-21 {
    left: 87.5%;
  }

  .semi-col-xl-pull-21 {
    right: 87.5%;
  }

  .semi-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .semi-col-xl-order-21 {
    order: 21;
  }

  .semi-col-xl-22 {
    box-sizing: border-box;
    width: 91.6667%;
    display: block;
  }

  .semi-col-xl-push-22 {
    left: 91.6667%;
  }

  .semi-col-xl-pull-22 {
    right: 91.6667%;
  }

  .semi-col-xl-offset-22 {
    margin-left: 91.6667%;
  }

  .semi-col-xl-order-22 {
    order: 22;
  }

  .semi-col-xl-23 {
    box-sizing: border-box;
    width: 95.8333%;
    display: block;
  }

  .semi-col-xl-push-23 {
    left: 95.8333%;
  }

  .semi-col-xl-pull-23 {
    right: 95.8333%;
  }

  .semi-col-xl-offset-23 {
    margin-left: 95.8333%;
  }

  .semi-col-xl-order-23 {
    order: 23;
  }

  .semi-col-xl-24 {
    box-sizing: border-box;
    width: 100%;
    display: block;
  }

  .semi-col-xl-push-24 {
    left: 100%;
  }

  .semi-col-xl-pull-24 {
    right: 100%;
  }

  .semi-col-xl-offset-24 {
    margin-left: 100%;
  }

  .semi-col-xl-order-24 {
    order: 24;
  }

  .semi-rtl .semi-col-xl-1, .semi-rtl .semi-col-xl-2, .semi-rtl .semi-col-xl-3, .semi-rtl .semi-col-xl-4, .semi-rtl .semi-col-xl-5, .semi-rtl .semi-col-xl-6, .semi-rtl .semi-col-xl-7, .semi-rtl .semi-col-xl-8, .semi-rtl .semi-col-xl-9, .semi-rtl .semi-col-xl-10, .semi-rtl .semi-col-xl-11, .semi-rtl .semi-col-xl-12, .semi-rtl .semi-col-xl-13, .semi-rtl .semi-col-xl-14, .semi-rtl .semi-col-xl-15, .semi-rtl .semi-col-xl-16, .semi-rtl .semi-col-xl-17, .semi-rtl .semi-col-xl-18, .semi-rtl .semi-col-xl-19, .semi-rtl .semi-col-xl-20, .semi-rtl .semi-col-xl-21, .semi-rtl .semi-col-xl-22, .semi-rtl .semi-col-xl-23, .semi-rtl .semi-col-xl-24 {
    float: right;
  }

  .semi-rtl .semi-col-xl-offset-1 {
    margin-left: auto;
    margin-right: 4.16667%;
  }

  .semi-rtl .semi-col-xl-offset-2 {
    margin-left: auto;
    margin-right: 8.33333%;
  }

  .semi-rtl .semi-col-xl-offset-3 {
    margin-left: auto;
    margin-right: 12.5%;
  }

  .semi-rtl .semi-col-xl-offset-4 {
    margin-left: auto;
    margin-right: 16.6667%;
  }

  .semi-rtl .semi-col-xl-offset-5 {
    margin-left: auto;
    margin-right: 20.8333%;
  }

  .semi-rtl .semi-col-xl-offset-6 {
    margin-left: auto;
    margin-right: 25%;
  }

  .semi-rtl .semi-col-xl-offset-7 {
    margin-left: auto;
    margin-right: 29.1667%;
  }

  .semi-rtl .semi-col-xl-offset-8 {
    margin-left: auto;
    margin-right: 33.3333%;
  }

  .semi-rtl .semi-col-xl-offset-9 {
    margin-left: auto;
    margin-right: 37.5%;
  }

  .semi-rtl .semi-col-xl-offset-10 {
    margin-left: auto;
    margin-right: 41.6667%;
  }

  .semi-rtl .semi-col-xl-offset-11 {
    margin-left: auto;
    margin-right: 45.8333%;
  }

  .semi-rtl .semi-col-xl-offset-12 {
    margin-left: auto;
    margin-right: 50%;
  }

  .semi-rtl .semi-col-xl-offset-13 {
    margin-left: auto;
    margin-right: 54.1667%;
  }

  .semi-rtl .semi-col-xl-offset-14 {
    margin-left: auto;
    margin-right: 58.3333%;
  }

  .semi-rtl .semi-col-xl-offset-15 {
    margin-left: auto;
    margin-right: 62.5%;
  }

  .semi-rtl .semi-col-xl-offset-16 {
    margin-left: auto;
    margin-right: 66.6667%;
  }

  .semi-rtl .semi-col-xl-offset-17 {
    margin-left: auto;
    margin-right: 70.8333%;
  }

  .semi-rtl .semi-col-xl-offset-18 {
    margin-left: auto;
    margin-right: 75%;
  }

  .semi-rtl .semi-col-xl-offset-19 {
    margin-left: auto;
    margin-right: 79.1667%;
  }

  .semi-rtl .semi-col-xl-offset-20 {
    margin-left: auto;
    margin-right: 83.3333%;
  }

  .semi-rtl .semi-col-xl-offset-21 {
    margin-left: auto;
    margin-right: 87.5%;
  }

  .semi-rtl .semi-col-xl-offset-22 {
    margin-left: auto;
    margin-right: 91.6667%;
  }

  .semi-rtl .semi-col-xl-offset-23 {
    margin-left: auto;
    margin-right: 95.8333%;
  }

  .semi-rtl .semi-col-xl-offset-24 {
    margin-left: auto;
    margin-right: 100%;
  }
}

@media (min-width: 1600px) {
  .semi-col-xxl-1, .semi-col-xxl-2, .semi-col-xxl-3, .semi-col-xxl-4, .semi-col-xxl-5, .semi-col-xxl-6, .semi-col-xxl-7, .semi-col-xxl-8, .semi-col-xxl-9, .semi-col-xxl-10, .semi-col-xxl-11, .semi-col-xxl-12, .semi-col-xxl-13, .semi-col-xxl-14, .semi-col-xxl-15, .semi-col-xxl-16, .semi-col-xxl-17, .semi-col-xxl-18, .semi-col-xxl-19, .semi-col-xxl-20, .semi-col-xxl-21, .semi-col-xxl-22, .semi-col-xxl-23, .semi-col-xxl-24 {
    float: left;
    flex: none;
  }

  .semi-col-xxl-1 {
    box-sizing: border-box;
    width: 4.16667%;
    display: block;
  }

  .semi-col-xxl-push-1 {
    left: 4.16667%;
  }

  .semi-col-xxl-pull-1 {
    right: 4.16667%;
  }

  .semi-col-xxl-offset-1 {
    margin-left: 4.16667%;
  }

  .semi-col-xxl-order-1 {
    order: 1;
  }

  .semi-col-xxl-2 {
    box-sizing: border-box;
    width: 8.33333%;
    display: block;
  }

  .semi-col-xxl-push-2 {
    left: 8.33333%;
  }

  .semi-col-xxl-pull-2 {
    right: 8.33333%;
  }

  .semi-col-xxl-offset-2 {
    margin-left: 8.33333%;
  }

  .semi-col-xxl-order-2 {
    order: 2;
  }

  .semi-col-xxl-3 {
    box-sizing: border-box;
    width: 12.5%;
    display: block;
  }

  .semi-col-xxl-push-3 {
    left: 12.5%;
  }

  .semi-col-xxl-pull-3 {
    right: 12.5%;
  }

  .semi-col-xxl-offset-3 {
    margin-left: 12.5%;
  }

  .semi-col-xxl-order-3 {
    order: 3;
  }

  .semi-col-xxl-4 {
    box-sizing: border-box;
    width: 16.6667%;
    display: block;
  }

  .semi-col-xxl-push-4 {
    left: 16.6667%;
  }

  .semi-col-xxl-pull-4 {
    right: 16.6667%;
  }

  .semi-col-xxl-offset-4 {
    margin-left: 16.6667%;
  }

  .semi-col-xxl-order-4 {
    order: 4;
  }

  .semi-col-xxl-5 {
    box-sizing: border-box;
    width: 20.8333%;
    display: block;
  }

  .semi-col-xxl-push-5 {
    left: 20.8333%;
  }

  .semi-col-xxl-pull-5 {
    right: 20.8333%;
  }

  .semi-col-xxl-offset-5 {
    margin-left: 20.8333%;
  }

  .semi-col-xxl-order-5 {
    order: 5;
  }

  .semi-col-xxl-6 {
    box-sizing: border-box;
    width: 25%;
    display: block;
  }

  .semi-col-xxl-push-6 {
    left: 25%;
  }

  .semi-col-xxl-pull-6 {
    right: 25%;
  }

  .semi-col-xxl-offset-6 {
    margin-left: 25%;
  }

  .semi-col-xxl-order-6 {
    order: 6;
  }

  .semi-col-xxl-7 {
    box-sizing: border-box;
    width: 29.1667%;
    display: block;
  }

  .semi-col-xxl-push-7 {
    left: 29.1667%;
  }

  .semi-col-xxl-pull-7 {
    right: 29.1667%;
  }

  .semi-col-xxl-offset-7 {
    margin-left: 29.1667%;
  }

  .semi-col-xxl-order-7 {
    order: 7;
  }

  .semi-col-xxl-8 {
    box-sizing: border-box;
    width: 33.3333%;
    display: block;
  }

  .semi-col-xxl-push-8 {
    left: 33.3333%;
  }

  .semi-col-xxl-pull-8 {
    right: 33.3333%;
  }

  .semi-col-xxl-offset-8 {
    margin-left: 33.3333%;
  }

  .semi-col-xxl-order-8 {
    order: 8;
  }

  .semi-col-xxl-9 {
    box-sizing: border-box;
    width: 37.5%;
    display: block;
  }

  .semi-col-xxl-push-9 {
    left: 37.5%;
  }

  .semi-col-xxl-pull-9 {
    right: 37.5%;
  }

  .semi-col-xxl-offset-9 {
    margin-left: 37.5%;
  }

  .semi-col-xxl-order-9 {
    order: 9;
  }

  .semi-col-xxl-10 {
    box-sizing: border-box;
    width: 41.6667%;
    display: block;
  }

  .semi-col-xxl-push-10 {
    left: 41.6667%;
  }

  .semi-col-xxl-pull-10 {
    right: 41.6667%;
  }

  .semi-col-xxl-offset-10 {
    margin-left: 41.6667%;
  }

  .semi-col-xxl-order-10 {
    order: 10;
  }

  .semi-col-xxl-11 {
    box-sizing: border-box;
    width: 45.8333%;
    display: block;
  }

  .semi-col-xxl-push-11 {
    left: 45.8333%;
  }

  .semi-col-xxl-pull-11 {
    right: 45.8333%;
  }

  .semi-col-xxl-offset-11 {
    margin-left: 45.8333%;
  }

  .semi-col-xxl-order-11 {
    order: 11;
  }

  .semi-col-xxl-12 {
    box-sizing: border-box;
    width: 50%;
    display: block;
  }

  .semi-col-xxl-push-12 {
    left: 50%;
  }

  .semi-col-xxl-pull-12 {
    right: 50%;
  }

  .semi-col-xxl-offset-12 {
    margin-left: 50%;
  }

  .semi-col-xxl-order-12 {
    order: 12;
  }

  .semi-col-xxl-13 {
    box-sizing: border-box;
    width: 54.1667%;
    display: block;
  }

  .semi-col-xxl-push-13 {
    left: 54.1667%;
  }

  .semi-col-xxl-pull-13 {
    right: 54.1667%;
  }

  .semi-col-xxl-offset-13 {
    margin-left: 54.1667%;
  }

  .semi-col-xxl-order-13 {
    order: 13;
  }

  .semi-col-xxl-14 {
    box-sizing: border-box;
    width: 58.3333%;
    display: block;
  }

  .semi-col-xxl-push-14 {
    left: 58.3333%;
  }

  .semi-col-xxl-pull-14 {
    right: 58.3333%;
  }

  .semi-col-xxl-offset-14 {
    margin-left: 58.3333%;
  }

  .semi-col-xxl-order-14 {
    order: 14;
  }

  .semi-col-xxl-15 {
    box-sizing: border-box;
    width: 62.5%;
    display: block;
  }

  .semi-col-xxl-push-15 {
    left: 62.5%;
  }

  .semi-col-xxl-pull-15 {
    right: 62.5%;
  }

  .semi-col-xxl-offset-15 {
    margin-left: 62.5%;
  }

  .semi-col-xxl-order-15 {
    order: 15;
  }

  .semi-col-xxl-16 {
    box-sizing: border-box;
    width: 66.6667%;
    display: block;
  }

  .semi-col-xxl-push-16 {
    left: 66.6667%;
  }

  .semi-col-xxl-pull-16 {
    right: 66.6667%;
  }

  .semi-col-xxl-offset-16 {
    margin-left: 66.6667%;
  }

  .semi-col-xxl-order-16 {
    order: 16;
  }

  .semi-col-xxl-17 {
    box-sizing: border-box;
    width: 70.8333%;
    display: block;
  }

  .semi-col-xxl-push-17 {
    left: 70.8333%;
  }

  .semi-col-xxl-pull-17 {
    right: 70.8333%;
  }

  .semi-col-xxl-offset-17 {
    margin-left: 70.8333%;
  }

  .semi-col-xxl-order-17 {
    order: 17;
  }

  .semi-col-xxl-18 {
    box-sizing: border-box;
    width: 75%;
    display: block;
  }

  .semi-col-xxl-push-18 {
    left: 75%;
  }

  .semi-col-xxl-pull-18 {
    right: 75%;
  }

  .semi-col-xxl-offset-18 {
    margin-left: 75%;
  }

  .semi-col-xxl-order-18 {
    order: 18;
  }

  .semi-col-xxl-19 {
    box-sizing: border-box;
    width: 79.1667%;
    display: block;
  }

  .semi-col-xxl-push-19 {
    left: 79.1667%;
  }

  .semi-col-xxl-pull-19 {
    right: 79.1667%;
  }

  .semi-col-xxl-offset-19 {
    margin-left: 79.1667%;
  }

  .semi-col-xxl-order-19 {
    order: 19;
  }

  .semi-col-xxl-20 {
    box-sizing: border-box;
    width: 83.3333%;
    display: block;
  }

  .semi-col-xxl-push-20 {
    left: 83.3333%;
  }

  .semi-col-xxl-pull-20 {
    right: 83.3333%;
  }

  .semi-col-xxl-offset-20 {
    margin-left: 83.3333%;
  }

  .semi-col-xxl-order-20 {
    order: 20;
  }

  .semi-col-xxl-21 {
    box-sizing: border-box;
    width: 87.5%;
    display: block;
  }

  .semi-col-xxl-push-21 {
    left: 87.5%;
  }

  .semi-col-xxl-pull-21 {
    right: 87.5%;
  }

  .semi-col-xxl-offset-21 {
    margin-left: 87.5%;
  }

  .semi-col-xxl-order-21 {
    order: 21;
  }

  .semi-col-xxl-22 {
    box-sizing: border-box;
    width: 91.6667%;
    display: block;
  }

  .semi-col-xxl-push-22 {
    left: 91.6667%;
  }

  .semi-col-xxl-pull-22 {
    right: 91.6667%;
  }

  .semi-col-xxl-offset-22 {
    margin-left: 91.6667%;
  }

  .semi-col-xxl-order-22 {
    order: 22;
  }

  .semi-col-xxl-23 {
    box-sizing: border-box;
    width: 95.8333%;
    display: block;
  }

  .semi-col-xxl-push-23 {
    left: 95.8333%;
  }

  .semi-col-xxl-pull-23 {
    right: 95.8333%;
  }

  .semi-col-xxl-offset-23 {
    margin-left: 95.8333%;
  }

  .semi-col-xxl-order-23 {
    order: 23;
  }

  .semi-col-xxl-24 {
    box-sizing: border-box;
    width: 100%;
    display: block;
  }

  .semi-col-xxl-push-24 {
    left: 100%;
  }

  .semi-col-xxl-pull-24 {
    right: 100%;
  }

  .semi-col-xxl-offset-24 {
    margin-left: 100%;
  }

  .semi-col-xxl-order-24 {
    order: 24;
  }

  .semi-rtl .semi-col-xxl-1, .semi-rtl .semi-col-xxl-2, .semi-rtl .semi-col-xxl-3, .semi-rtl .semi-col-xxl-4, .semi-rtl .semi-col-xxl-5, .semi-rtl .semi-col-xxl-6, .semi-rtl .semi-col-xxl-7, .semi-rtl .semi-col-xxl-8, .semi-rtl .semi-col-xxl-9, .semi-rtl .semi-col-xxl-10, .semi-rtl .semi-col-xxl-11, .semi-rtl .semi-col-xxl-12, .semi-rtl .semi-col-xxl-13, .semi-rtl .semi-col-xxl-14, .semi-rtl .semi-col-xxl-15, .semi-rtl .semi-col-xxl-16, .semi-rtl .semi-col-xxl-17, .semi-rtl .semi-col-xxl-18, .semi-rtl .semi-col-xxl-19, .semi-rtl .semi-col-xxl-20, .semi-rtl .semi-col-xxl-21, .semi-rtl .semi-col-xxl-22, .semi-rtl .semi-col-xxl-23, .semi-rtl .semi-col-xxl-24 {
    float: right;
  }

  .semi-rtl .semi-col-xxl-offset-1 {
    margin-left: auto;
    margin-right: 4.16667%;
  }

  .semi-rtl .semi-col-xxl-offset-2 {
    margin-left: auto;
    margin-right: 8.33333%;
  }

  .semi-rtl .semi-col-xxl-offset-3 {
    margin-left: auto;
    margin-right: 12.5%;
  }

  .semi-rtl .semi-col-xxl-offset-4 {
    margin-left: auto;
    margin-right: 16.6667%;
  }

  .semi-rtl .semi-col-xxl-offset-5 {
    margin-left: auto;
    margin-right: 20.8333%;
  }

  .semi-rtl .semi-col-xxl-offset-6 {
    margin-left: auto;
    margin-right: 25%;
  }

  .semi-rtl .semi-col-xxl-offset-7 {
    margin-left: auto;
    margin-right: 29.1667%;
  }

  .semi-rtl .semi-col-xxl-offset-8 {
    margin-left: auto;
    margin-right: 33.3333%;
  }

  .semi-rtl .semi-col-xxl-offset-9 {
    margin-left: auto;
    margin-right: 37.5%;
  }

  .semi-rtl .semi-col-xxl-offset-10 {
    margin-left: auto;
    margin-right: 41.6667%;
  }

  .semi-rtl .semi-col-xxl-offset-11 {
    margin-left: auto;
    margin-right: 45.8333%;
  }

  .semi-rtl .semi-col-xxl-offset-12 {
    margin-left: auto;
    margin-right: 50%;
  }

  .semi-rtl .semi-col-xxl-offset-13 {
    margin-left: auto;
    margin-right: 54.1667%;
  }

  .semi-rtl .semi-col-xxl-offset-14 {
    margin-left: auto;
    margin-right: 58.3333%;
  }

  .semi-rtl .semi-col-xxl-offset-15 {
    margin-left: auto;
    margin-right: 62.5%;
  }

  .semi-rtl .semi-col-xxl-offset-16 {
    margin-left: auto;
    margin-right: 66.6667%;
  }

  .semi-rtl .semi-col-xxl-offset-17 {
    margin-left: auto;
    margin-right: 70.8333%;
  }

  .semi-rtl .semi-col-xxl-offset-18 {
    margin-left: auto;
    margin-right: 75%;
  }

  .semi-rtl .semi-col-xxl-offset-19 {
    margin-left: auto;
    margin-right: 79.1667%;
  }

  .semi-rtl .semi-col-xxl-offset-20 {
    margin-left: auto;
    margin-right: 83.3333%;
  }

  .semi-rtl .semi-col-xxl-offset-21 {
    margin-left: auto;
    margin-right: 87.5%;
  }

  .semi-rtl .semi-col-xxl-offset-22 {
    margin-left: auto;
    margin-right: 91.6667%;
  }

  .semi-rtl .semi-col-xxl-offset-23 {
    margin-left: auto;
    margin-right: 95.8333%;
  }

  .semi-rtl .semi-col-xxl-offset-24 {
    margin-left: auto;
    margin-right: 100%;
  }
}

.semi-rtl .semi-row, .semi-rtl .semi-row-flex, .semi-portal-rtl .semi-row, .semi-portal-rtl .semi-row-flex {
  direction: rtl;
}

@keyframes semi-animation-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.semi-spin {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.semi-spin-wrapper {
  text-align: center;
  width: 100%;
  color: var(--semi-color-primary);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.semi-spin-wrapper > svg {
  width: 20px;
  height: 20px;
  animation: semi-animation-rotate .6s linear infinite;
}

.semi-spin-animate {
  animation: semi-animation-rotate 1.6s linear infinite;
  display: inline-flex;
}

.semi-spin-children {
  opacity: .5;
  user-select: none;
}

.semi-spin-block {
  display: block;
}

.semi-spin-block:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-spin-block .semi-spin-wrapper {
  display: block;
}

.semi-spin-block.semi-spin {
  height: auto;
  width: auto;
}

.semi-spin-hidden:after {
  content: none;
}

.semi-spin-hidden > .semi-spin-children {
  opacity: 1;
  user-select: auto;
}

.semi-spin-small, .semi-spin-small > .semi-spin-wrapper svg {
  width: 14px;
  height: 14px;
}

.semi-spin-middle, .semi-spin-middle > .semi-spin-wrapper svg {
  width: 20px;
  height: 20px;
}

.semi-spin-large, .semi-spin-large > .semi-spin-wrapper svg {
  width: 32px;
  height: 32px;
}

.semi-spin-container {
  overflow: hidden;
}

.semi-rtl .semi-spin, .semi-portal-rtl .semi-spin, .semi-rtl .semi-spin-container, .semi-portal-rtl .semi-spin-container {
  direction: rtl;
}

.semi-table-panel-operation {
  background-color: var(--semi-color-primary);
  color: var(--semi-color-text-2);
  justify-content: space-between;
  padding: 8px 16px;
  display: flex;
}

.semi-table-panel-operation-right, .semi-table-panel-operation-left {
  justify-content: space-between;
  display: flex;
}

.semi-table-panel-operation-selected {
  color: var(--semi-color-primary-light-active);
}

.semi-table-pagination-info {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.semi-table-pagination-outer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.semi-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  font-size: inherit;
  display: table;
}

.semi-table-wrapper {
  zoom: 1;
  clear: both;
  box-sizing: border-box;
  color: var(--semi-color-text-0);
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.semi-table-wrapper[data-column-fixed="true"] {
  z-index: 1;
}

.semi-table-middle .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  padding-top: 12px;
  padding-bottom: 12px;
}

.semi-table-small .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  padding-top: 8px;
  padding-bottom: 8px;
}

.semi-table-title {
  padding: 16px 0;
  position: relative;
}

.semi-table-container {
  position: relative;
}

.semi-table-header {
  scrollbar-base-color: transparent;
  overflow: hidden;
}

.semi-table-header::-webkit-scrollbar {
  border-bottom: 2px solid var(--semi-color-border);
  background-color: #0000;
}

.semi-table-body {
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.semi-table-colgroup {
  display: table-column-group;
}

.semi-table-colgroup .semi-table-col {
  display: table-column;
}

.semi-table-colgroup .semi-table-column-expand, .semi-table-colgroup .semi-table-column-selection {
  width: 48px;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head {
  color: var(--semi-color-text-2);
  text-align: left;
  border-bottom: 2px solid var(--semi-color-border);
  vertical-align: middle;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #0000;
  padding: 8px 16px;
  font-weight: 600;
  position: relative;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left, .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right {
  z-index: 101;
  background-color: var(--semi-color-bg-2);
  position: sticky;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left:before, .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right:before {
  content: "";
  z-index: -1;
  background-color: #0000;
  display: block;
  position: absolute;
  inset: 0;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last {
  border-right: 1px solid var(--semi-color-border);
  box-shadow: 3px 0 0 0 var(--semi-color-shadow);
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last.resizing {
  border-right: 2px solid var(--semi-color-primary);
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last.resizing .react-resizable-handle:hover {
  background-color: unset;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first {
  border-left: 1px solid var(--semi-color-border);
  box-shadow: -3px 0 0 0 var(--semi-color-shadow);
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first.resizing {
  border-right: 2px solid var(--semi-color-primary);
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first.resizing .react-resizable-handle:hover {
  background-color: unset;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first[x-type="column-scrollbar"] {
  box-shadow: none;
  border-left: #0000;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-column-selection, .semi-table-thead > .semi-table-row > .semi-table-row-head[colspan]:not([colspan="1"]) {
  text-align: center;
}

.semi-table-thead > .semi-table-row > .semi-table-row-head .semi-table-header-column {
  align-items: center;
  display: inline-flex;
}

.semi-table-thead > .semi-table-row .react-resizable {
  background-clip: padding-box;
  position: relative;
}

.semi-table-thead > .semi-table-row .resizing {
  border-right: 2px solid var(--semi-color-primary);
}

.semi-table-thead > .semi-table-row .resizing .react-resizable-handle:hover {
  background-color: unset;
}

.semi-table-thead > .semi-table-row .react-resizable-handle {
  width: 9px;
  height: calc(100% - 8px);
  background-color: var(--semi-color-border);
  cursor: col-resize;
  z-index: 0;
  position: absolute;
  bottom: 4px;
  right: -1px;
}

.semi-table-thead > .semi-table-row .react-resizable-handle:hover {
  background-color: var(--semi-color-primary);
}

.semi-table-tbody {
  display: table-row-group;
}

.semi-table-tbody > .semi-table-row {
  display: table-row;
}

.semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell {
  background-color: var(--semi-color-fill-0);
}

.semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-left, .semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-right {
  background-color: var(--semi-color-bg-2);
}

.semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-left:before, .semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-right:before {
  background-color: var(--semi-color-fill-0);
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  inset: 0;
}

.semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  word-wrap: break-word;
  word-break: break-all;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--semi-color-border);
  box-sizing: border-box;
  vertical-align: middle;
  padding: 16px;
  display: table-cell;
  position: relative;
}

.semi-table-tbody > .semi-table-row > .semi-table-row-cell.resizing {
  border-right: 2px solid var(--semi-color-primary);
}

.semi-table-tbody > .semi-table-row.semi-table-row-expand > .semi-table-row-cell {
  background-color: var(--semi-color-fill-0);
}

.semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left, .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right {
  z-index: 101;
  background-color: var(--semi-color-bg-2);
  position: sticky;
}

.semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last {
  border-right: 1px solid var(--semi-color-border);
  box-shadow: 3px 0 0 0 var(--semi-color-shadow);
}

.semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first {
  border-left: 1px solid var(--semi-color-border);
  box-shadow: -3px 0 0 0 var(--semi-color-shadow);
}

.semi-table-tbody > .semi-table-row-section {
  display: table-row;
}

.semi-table-tbody > .semi-table-row-section > .semi-table-row-cell {
  background-color: rgba(var(--semi-grey-0), 1);
  border-bottom: 1px solid var(--semi-color-border);
}

.semi-table-tbody > .semi-table-row-section > .semi-table-row-cell:not(.semi-table-column-selection) {
  padding: 10px 16px;
}

.semi-table-tbody > .semi-table-row-section .semi-table-section-inner {
  align-items: center;
  display: inline-flex;
}

.semi-table-virtualized .semi-table-tbody {
  display: block;
}

.semi-table-virtualized .semi-table-tbody > .semi-table-row {
  display: flex;
}

.semi-table-virtualized .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  word-wrap: unset;
  word-break: unset;
  white-space: nowrap;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
}

.semi-table-virtualized .semi-table-tbody > .semi-table-row-section > .semi-table-row-cell {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
}

.semi-table-virtualized .semi-table-tbody > .semi-table-row-expand > .semi-table-row-cell {
  overflow: unset;
  padding: 0;
}

.semi-table-footer {
  background-color: var(--semi-color-fill-0);
  margin: 0;
  padding: 16px;
  position: relative;
}

.semi-table .semi-table-selection-wrap {
  vertical-align: bottom;
  display: inline-flex;
}

.semi-table .semi-table-selection-disabled {
  cursor: not-allowed;
}

.semi-table .semi-table-selection-disabled > .semi-checkbox {
  pointer-events: none;
}

.semi-table .semi-table-column-hidden {
  display: none;
}

.semi-table .semi-table-column-selection {
  text-align: center;
}

.semi-table .semi-table-column-selection .semi-checkbox-inner-display .semi-icon {
  top: 0;
  left: 0;
}

.semi-table .semi-table-column-expand .semi-table-expand-icon {
  transform: translateY(2px);
}

.semi-table .semi-table-column-expand .semi-table-expand-icon:last-child {
  margin-right: 0;
}

.semi-table .semi-table-column-sorter {
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  margin-left: 4px;
  display: inline-block;
}

.semi-table .semi-table-column-sorter-up, .semi-table .semi-table-column-sorter-down {
  height: 0;
  color: var(--semi-color-text-2);
  display: block;
}

.semi-table .semi-table-column-sorter-up:hover .anticon, .semi-table .semi-table-column-sorter-down:hover .anticon {
  color: var(--semi-color-text-2);
}

.semi-table .semi-table-column-sorter-up svg, .semi-table .semi-table-column-sorter-down svg {
  width: 16px;
  height: 16px;
}

.semi-table .semi-table-column-sorter-up.on .semi-icon-caretup, .semi-table .semi-table-column-sorter-up.on .semi-icon-caretdown, .semi-table .semi-table-column-sorter-down.on .semi-icon-caretup, .semi-table .semi-table-column-sorter-down.on .semi-icon-caretdown {
  color: var(--semi-color-primary);
}

.semi-table .semi-table-column-filter {
  cursor: pointer;
  color: var(--semi-color-text-2);
  vertical-align: middle;
  margin-left: 4px;
  display: inline-flex;
}

.semi-table .semi-table-column-filter svg {
  width: 12px;
  height: 12px;
}

.semi-table .semi-table-column-filter.on {
  color: var(--semi-color-primary);
}

.semi-table-bordered .semi-table-title {
  border-top: 1px solid var(--semi-color-border);
  border-right: 1px solid var(--semi-color-border);
  border-left: 1px solid var(--semi-color-border);
  padding-left: 16px;
  padding-right: 16px;
}

.semi-table-bordered .semi-table-container {
  border: 1px solid var(--semi-color-border);
  border-bottom: 0;
  border-right: 0;
}

.semi-table-bordered .semi-table-header::-webkit-scrollbar {
  border-right: 1px solid var(--semi-color-border);
}

.semi-table-bordered .semi-table-footer {
  border-left: 1px solid var(--semi-color-border);
  border-right: 1px solid var(--semi-color-border);
  border-bottom: 1px solid var(--semi-color-border);
}

.semi-table-bordered .semi-table-thead > .semi-table-row > .semi-table-row-head .react-resizable-handle {
  background-color: #0000;
}

.semi-table-bordered .semi-table-thead > .semi-table-row > .semi-table-row-head, .semi-table-bordered .semi-table-tbody > .semi-table-row > .semi-table-row-cell, .semi-table-bordered .semi-table-placeholder {
  border-right: 1px solid var(--semi-color-border);
}

.semi-table-placeholder {
  z-index: 1;
  color: var(--semi-color-text-2);
  text-align: center;
  border-bottom: 1px solid var(--semi-color-border);
  background: none;
  border-radius: 0 0 4px 4px;
  padding: 16px 12px;
  font-size: 14px;
  position: relative;
}

.semi-table-fixed {
  table-layout: fixed;
  min-width: 100%;
}

.semi-table-fixed > .semi-table-tbody > .semi-table-row-expand > .semi-table-row-cell > .semi-table-expand-inner, .semi-table-fixed > .semi-table-tbody > .semi-table-row-section > .semi-table-row-cell > .semi-table-section-inner {
  height: 100%;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  position: sticky;
  left: 0;
  overflow: auto;
}

.semi-table-fixed-header table {
  table-layout: fixed;
}

.semi-table-scroll-position-left .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last, .semi-table-scroll-position-left .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-left-last, .semi-table-scroll-position-right .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first, .semi-table-scroll-position-right .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-right-first {
  box-shadow: none;
}

.semi-table-pagination-outer {
  color: var(--semi-color-text-2);
  min-height: 60px;
}

.semi-table-expand-icon {
  color: var(--semi-color-text-2);
  cursor: pointer;
  user-select: none;
  background: none;
  align-items: center;
  margin-right: 8px;
  display: inline-flex;
  position: relative;
}

.semi-table-expand-icon-cell {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.semi-rtl .semi-table, .semi-portal-rtl .semi-table {
  direction: rtl;
  text-align: right;
}

.semi-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head, .semi-portal-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head {
  text-align: right;
}

.semi-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last, .semi-portal-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last.resizing, .semi-portal-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last.resizing {
  border-left: 2px solid var(--semi-color-primary);
}

.semi-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first, .semi-portal-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first {
  border-left: 0;
  border-right: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first.resizing, .semi-portal-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first.resizing {
  border-left: 2px solid var(--semi-color-primary);
}

.semi-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first[x-type="column-scrollbar"], .semi-portal-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first[x-type="column-scrollbar"] {
  box-shadow: none;
  border-right: #0000;
}

.semi-rtl .semi-table-thead > .semi-table-row .resizing, .semi-portal-rtl .semi-table-thead > .semi-table-row .resizing {
  border-left: 2px solid var(--semi-color-primary);
}

.semi-rtl .semi-table-thead > .semi-table-row .react-resizable-handle, .semi-portal-rtl .semi-table-thead > .semi-table-row .react-resizable-handle {
  left: -1px;
  right: auto;
}

.semi-rtl .semi-table-tbody, .semi-portal-rtl .semi-table-tbody {
  display: table-row-group;
}

.semi-rtl .semi-table-tbody > .semi-table-row > .semi-table-row-cell.resizing, .semi-portal-rtl .semi-table-tbody > .semi-table-row > .semi-table-row-cell.resizing {
  border-right: 0;
  border-left: 2px solid var(--semi-color-primary);
}

.semi-rtl .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last, .semi-portal-rtl .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first, .semi-portal-rtl .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first {
  border-left: 0;
  border-right: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-table .semi-table-column-selection .semi-checkbox-inner-display .semi-icon, .semi-portal-rtl .semi-table .semi-table-column-selection .semi-checkbox-inner-display .semi-icon {
  left: auto;
  right: 0;
}

.semi-rtl .semi-table .semi-table-column-expand .semi-table-expand-icon, .semi-portal-rtl .semi-table .semi-table-column-expand .semi-table-expand-icon {
  transform: scaleX(-1)translateY(2px);
}

.semi-rtl .semi-table .semi-table-column-expand .semi-table-expand-icon:last-child, .semi-portal-rtl .semi-table .semi-table-column-expand .semi-table-expand-icon:last-child {
  margin-left: 0;
  margin-right: auto;
}

.semi-rtl .semi-table .semi-table-column-sorter, .semi-portal-rtl .semi-table .semi-table-column-sorter, .semi-rtl .semi-table .semi-table-column-filter, .semi-portal-rtl .semi-table .semi-table-column-filter {
  margin-left: auto;
  margin-right: 4px;
}

.semi-rtl .semi-table-bordered .semi-table-container, .semi-portal-rtl .semi-table-bordered .semi-table-container {
  border-left: 0;
  border-right: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-table-bordered .semi-table-thead > .semi-table-row > .semi-table-row-head, .semi-rtl .semi-table-bordered .semi-table-tbody > .semi-table-row > .semi-table-row-cell, .semi-portal-rtl .semi-table-bordered .semi-table-thead > .semi-table-row > .semi-table-row-head, .semi-portal-rtl .semi-table-bordered .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-table-bordered .semi-table-placeholder, .semi-portal-rtl .semi-table-bordered .semi-table-placeholder {
  border-left: 1px solid var(--semi-color-border);
  border-right: 0;
}

.semi-rtl .semi-table-bordered .semi-table-header::-webkit-scrollbar, .semi-portal-rtl .semi-table-bordered .semi-table-header::-webkit-scrollbar {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-table-fixed > .semi-table-tbody > .semi-table-row-expand > .semi-table-row-cell > .semi-table-expand-inner, .semi-rtl .semi-table-fixed > .semi-table-tbody > .semi-table-row-section > .semi-table-row-cell > .semi-table-section-inner, .semi-portal-rtl .semi-table-fixed > .semi-table-tbody > .semi-table-row-expand > .semi-table-row-cell > .semi-table-expand-inner, .semi-portal-rtl .semi-table-fixed > .semi-table-tbody > .semi-table-row-section > .semi-table-row-cell > .semi-table-section-inner {
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  left: auto;
  right: 0;
}

.semi-rtl .semi-table-expand-icon, .semi-portal-rtl .semi-table-expand-icon {
  margin-left: 8px;
  margin-right: auto;
  transform: scaleX(-1)translateY(2px);
}

.semi-checkbox {
  box-sizing: border-box;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.semi-checkbox input[type="checkbox"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-checkbox-addon {
  color: var(--semi-color-text-0);
  user-select: none;
  flex: 1;
  align-items: center;
  padding-left: 8px;
  line-height: 20px;
  display: flex;
}

.semi-checkbox:hover .semi-checkbox-inner-display {
  background: var(--semi-color-fill-0);
  box-shadow: inset 0 0 0 1px var(--semi-color-focus-border);
}

.semi-checkbox:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-hover);
  box-shadow: none;
}

.semi-checkbox:active .semi-checkbox-inner-display {
  background: var(--semi-color-fill-1);
}

.semi-checkbox:active .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-active);
  box-shadow: none;
}

.semi-checkbox.semi-checkbox-disabled:hover .semi-checkbox-inner-display, .semi-checkbox.semi-checkbox-disabled:active .semi-checkbox-inner-display {
  background: var(--semi-color-disabled-fill);
  box-shadow: inset 0 0 0 1px var(--semi-color-border);
}

.semi-checkbox.semi-checkbox-disabled:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display, .semi-checkbox.semi-checkbox-disabled:active .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-disabled);
  box-shadow: none;
}

.semi-checkbox-inner {
  width: 16px;
  height: 20px;
  user-select: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

.semi-checkbox-inner-display {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  box-shadow: inset 0 0 0 1px var(--semi-color-text-3);
  border-radius: var(--semi-border-radius-extra-small);
  background: none;
  margin: 0;
  position: relative;
}

.semi-checkbox-inner-display .semi-icon {
  font-size: 16px;
}

.semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary);
  color: var(--semi-color-white);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary);
  border-radius: var(--semi-border-radius-extra-small);
}

.semi-checkbox-inner-checked > .semi-checkbox-addon {
  color: var(--semi-color-text-0);
}

.semi-checkbox:hover .semi-checkbox-inner-display {
  background: var(--semi-color-fill-0);
}

.semi-checkbox:hover.semi-checkbox-indeterminate .semi-checkbox-inner-display {
  background: var(--semi-color-primary-hover);
  box-shadow: none;
  color: var(--semi-color-white);
}

.semi-checkbox:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-hover);
  border-color: var(--semi-color-primary-hover);
  color: var(--semi-color-white);
}

.semi-checkbox:hover.semi-checkbox-cardType.semi-checkbox-unChecked.semi-checkbox-cardType_unDisabled .semi-checkbox-inner-display {
  background: var(--semi-color-white);
}

.semi-checkbox:active .semi-checkbox-inner-display {
  background: var(--semi-color-fill-1);
}

.semi-checkbox:active.semi-checkbox-indeterminate .semi-checkbox-inner-display {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
  color: var(--semi-color-white);
  box-shadow: none;
}

.semi-checkbox:active .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
  color: var(--semi-color-white);
}

.semi-checkbox:active.semi-checkbox-cardType.semi-checkbox-unChecked.semi-checkbox-cardType_unDisabled .semi-checkbox-inner-display {
  background: var(--semi-color-white);
}

.semi-checkbox-cardType {
  background: none;
  border: 1px solid #0000;
  border-radius: 3px;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
}

.semi-checkbox-cardType .semi-checkbox-inner {
  flex-shrink: 0;
  margin-right: 8px;
  position: relative;
}

.semi-checkbox-cardType .semi-checkbox-inner-display {
  background: var(--semi-color-white);
}

.semi-checkbox-cardType .semi-checkbox-inner-pureCardType {
  opacity: 0;
  width: 0;
  margin-right: 0;
}

.semi-checkbox-cardType .semi-checkbox-addon {
  color: var(--semi-color-text-0);
  margin-left: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.semi-checkbox-cardType .semi-checkbox-extra {
  color: var(--semi-color-text-2);
  padding-left: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.semi-checkbox-cardType .semi-checkbox-extra.semi-checkbox-cardType_extra_noChildren {
  margin-top: 0;
}

.semi-checkbox-cardType:hover {
  background: var(--semi-color-fill-0);
}

.semi-checkbox-cardType:active {
  background: var(--semi-color-fill-1);
}

.semi-checkbox-cardType_checked {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary);
}

.semi-checkbox-cardType_checked:hover {
  background: var(--semi-color-primary-light-default);
  border-color: var(--semi-color-primary-hover);
}

.semi-checkbox-cardType_checked:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  box-shadow: none;
}

.semi-checkbox-cardType_checked:active {
  background: var(--semi-color-primary-light-default);
  border-color: var(--semi-color-primary-active);
}

.semi-checkbox-cardType_disabled:active, .semi-checkbox-cardType_disabled:hover {
  background: none;
}

.semi-checkbox-cardType_checked_disabled.semi-checkbox-cardType {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary-disabled);
}

.semi-checkbox-cardType_checked_disabled.semi-checkbox-cardType:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  box-shadow: none;
}

.semi-checkbox-indeterminate .semi-checkbox-inner-display, .semi-checkbox-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary);
  color: var(--semi-color-white);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary);
  border-radius: var(--semi-border-radius-extra-small);
}

.semi-checkbox-indeterminate .semi-checkbox-inner-display:hover, .semi-checkbox-checked .semi-checkbox-inner-display:hover {
  background: var(--semi-color-primary-hover);
  border-color: var(--semi-color-primary-hover);
  color: var(--semi-color-white);
}

.semi-checkbox-indeterminate .semi-checkbox-inner-display:active, .semi-checkbox-checked .semi-checkbox-inner-display:active {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
  color: var(--semi-color-white);
}

.semi-checkbox-indeterminate .semi-checkbox-inner-addon, .semi-checkbox-checked .semi-checkbox-inner-addon {
  color: var(--semi-color-text-0);
}

.semi-checkbox-disabled, .semi-checkbox-disabled .semi-checkbox-inner {
  cursor: not-allowed;
}

.semi-checkbox-disabled .semi-checkbox-inner-display {
  color: var(--semi-color-white);
  background: var(--semi-color-disabled-fill);
  box-shadow: inset 0 0 0 1px var(--semi-color-border);
}

.semi-checkbox-disabled .semi-checkbox-inner-display:hover {
  color: var(--semi-color-white);
  background: none;
}

.semi-checkbox-disabled .semi-checkbox-inner-checked {
  color: var(--semi-color-white);
}

.semi-checkbox-disabled .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  opacity: .75;
  background: var(--semi-color-primary-disabled);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary-disabled);
}

.semi-checkbox-disabled .semi-checkbox-inner-checked .semi-checkbox-inner-display:hover {
  color: var(--semi-color-white);
  background: var(--semi-color-primary-disabled);
}

.semi-checkbox-disabled .semi-checkbox-addon, .semi-checkbox-disabled .semi-checkbox-extra {
  color: var(--semi-color-disabled-text);
}

.semi-checkbox.semi-checkbox-disabled.semi-checkbox-indeterminate .semi-checkbox-inner-display {
  opacity: .75;
  background: var(--semi-color-primary-disabled);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary-disabled);
  color: var(--semi-color-white);
}

.semi-checkbox-extra {
  box-sizing: border-box;
  color: var(--semi-color-text-2);
  flex: 1 0 100%;
  margin-top: 4px;
  padding-left: 24px;
}

.semi-checkbox-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}

.semi-checkbox-focus-border {
  box-shadow: inset 0 0 0 1px var(--semi-color-focus-border);
}

.semi-checkboxGroup {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 14px;
}

.semi-checkboxGroup .semi-checkbox.semi-checkbox-vertical {
  margin-bottom: 16px;
}

.semi-checkboxGroup-horizontal .semi-checkbox {
  margin-right: 16px;
  display: inline-flex;
}

.semi-checkboxGroup-horizontal .semi-checkbox:last-of-type {
  margin-right: 0;
}

.semi-checkboxGroup-vertical .semi-checkbox {
  margin-bottom: 12px;
}

.semi-checkboxGroup-vertical .semi-checkbox:last-of-type {
  margin-bottom: 0;
}

.semi-checkboxGroup-vertical-cardType .semi-checkbox {
  margin-bottom: 16px;
}

.semi-rtl .semi-checkbox, .semi-portal-rtl .semi-checkbox {
  direction: rtl;
}

.semi-rtl .semi-checkbox input[type="checkbox"], .semi-portal-rtl .semi-checkbox input[type="checkbox"] {
  left: auto;
  right: 0;
}

.semi-rtl .semi-checkbox-addon, .semi-portal-rtl .semi-checkbox-addon {
  padding-left: 0;
  padding-right: 8px;
}

.semi-rtl .semi-checkbox-extra, .semi-portal-rtl .semi-checkbox-extra {
  padding-left: 0;
  padding-right: 24px;
}

.semi-rtl .semi-checkbox-cardType .semi-checkbox-inner, .semi-portal-rtl .semi-checkbox-cardType .semi-checkbox-inner {
  margin-left: 8px;
  margin-right: 0;
}

.semi-rtl .semi-checkbox-cardType .semi-checkbox-addon, .semi-portal-rtl .semi-checkbox-cardType .semi-checkbox-addon {
  margin-right: 0;
  padding-right: 0;
}

.semi-rtl .semi-checkbox-cardType .semi-checkbox-extra, .semi-portal-rtl .semi-checkbox-cardType .semi-checkbox-extra {
  padding-right: 0;
}

.semi-rtl .semi-checkboxGroup, .semi-portal-rtl .semi-checkboxGroup {
  direction: rtl;
}

.semi-rtl .semi-checkboxGroup-horizontal .semi-checkbox, .semi-portal-rtl .semi-checkboxGroup-horizontal .semi-checkbox {
  margin-left: 16px;
  margin-right: 0;
}

.semi-rtl .semi-checkboxGroup-horizontal .semi-checkbox:last-of-type, .semi-portal-rtl .semi-checkboxGroup-horizontal .semi-checkbox:last-of-type {
  margin-left: 0;
}

.semi-page {
  align-items: center;
  margin-block: 0;
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  display: flex;
}

.semi-page-small {
  color: var(--semi-color-text-2);
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.semi-page-item {
  min-width: 32px;
  cursor: pointer;
  user-select: none;
  height: 32px;
  color: var(--semi-color-text-0);
  border-radius: var(--semi-border-radius-small);
  text-align: center;
  border: 0 solid #0000;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
}

.semi-page-item:hover {
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-0);
  border-color: #0000;
}

.semi-page-item-rest-opening {
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-0);
}

.semi-page-item:active {
  background-color: var(--semi-color-fill-1);
  color: var(--semi-color-text-0);
  border-color: #0000;
}

.semi-page-item-active {
  color: var(--semi-color-primary);
  background-color: var(--semi-color-primary-light-default);
  border-color: #0000;
  font-weight: 600;
}

.semi-page-item-active:hover {
  color: var(--semi-color-primary);
  background-color: var(--semi-color-primary-light-default);
  border-color: #0000;
}

.semi-page-item-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
  background-color: #0000;
  border-color: #0000;
}

.semi-page-item-disabled:hover {
  background-color: #0000;
}

.semi-page-item-small {
  min-width: 44px;
  margin: 0;
}

.semi-page-total {
  color: var(--semi-color-text-2);
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.semi-page-prev, .semi-page-next {
  color: var(--semi-color-tertiary);
  cursor: pointer;
}

.semi-page-prev.semi-page-item-disabled, .semi-page-next.semi-page-item-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}

.semi-page-quickjump {
  color: var(--semi-color-text-0);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.semi-page-quickjump-input-number {
  max-width: 50px;
  margin-left: 4px;
  margin-right: 4px;
}

.semi-page-quickjump-disabled {
  color: var(--semi-color-disabled-text);
}

.semi-page .semi-select, .semi-select-dropdown {
  user-select: none;
}

.semi-page-rest-list {
  padding-top: 4px;
  padding-bottom: 4px;
}

.semi-page-rest-list > div {
  position: relative;
}

.semi-page-rest-item {
  height: 32px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  line-height: 32px;
  display: flex;
}

.semi-page-rest-item:hover {
  background-color: var(--semi-color-fill-0);
}

.semi-page-rest-item:active {
  background-color: var(--semi-color-fill-1);
}

.semi-rtl .semi-page, .semi-portal-rtl .semi-page {
  direction: rtl;
}

.semi-rtl .semi-page-item, .semi-portal-rtl .semi-page-item {
  margin-left: 4px;
  margin-right: 4px;
}

.semi-rtl .semi-page-prev, .semi-rtl .semi-page-next, .semi-portal-rtl .semi-page-prev, .semi-portal-rtl .semi-page-next {
  transform: scaleX(-1);
}

.semi-avatar {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.semi-avatar:focus-visible, .semi-avatar-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}

.semi-avatar-no-focus-visible:focus-visible {
  outline: none;
}

.semi-avatar .semi-avatar-label {
  align-items: center;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.semi-avatar-content {
  user-select: none;
}

.semi-avatar-extra-extra-small {
  width: 20px;
  height: 20px;
}

.semi-avatar-extra-extra-small .semi-avatar-content {
  transform-origin: center;
  transform: scale(.8);
}

.semi-avatar-extra-extra-small .semi-avatar-label {
  font-size: 10px;
  line-height: 15px;
}

.semi-avatar-extra-small {
  width: 24px;
  height: 24px;
}

.semi-avatar-extra-small .semi-avatar-content {
  transform-origin: center;
  transform: scale(.8);
}

.semi-avatar-extra-small .semi-avatar-label {
  font-size: 10px;
  line-height: 15px;
}

.semi-avatar-small {
  width: 32px;
  height: 32px;
}

.semi-avatar-small .semi-avatar-label {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.semi-avatar-default {
  width: 40px;
  height: 40px;
}

.semi-avatar-default .semi-avatar-label {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.semi-avatar-medium {
  width: 48px;
  height: 48px;
}

.semi-avatar-medium .semi-avatar-label {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.semi-avatar-large {
  width: 72px;
  height: 72px;
}

.semi-avatar-large .semi-avatar-label {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 32px;
  line-height: 44px;
}

.semi-avatar-extra-large {
  width: 128px;
  height: 128px;
}

.semi-avatar-extra-large .semi-avatar-label {
  font-size: 64px;
  line-height: 77px;
}

.semi-avatar-circle {
  border-radius: var(--semi-border-radius-circle);
}

.semi-avatar-image {
  background-color: #0000;
}

.semi-avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.semi-avatar-hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-avatar:hover {
  cursor: pointer;
}

.semi-avatar-group {
  display: inline-block;
}

.semi-avatar-group .semi-avatar {
  box-sizing: border-box;
}

.semi-avatar-group .semi-avatar:first-child {
  margin-left: 0;
}

.semi-avatar-group .semi-avatar-extra-large {
  border: 3px var(--semi-color-bg-1) solid;
  margin-left: -32px;
}

.semi-avatar-group .semi-avatar-large {
  border: 3px var(--semi-color-bg-1) solid;
  margin-left: -18px;
}

.semi-avatar-group .semi-avatar-medium, .semi-avatar-group .semi-avatar-default, .semi-avatar-group .semi-avatar-small {
  border: 2px var(--semi-color-bg-1) solid;
  margin-left: -12px;
}

.semi-avatar-group .semi-avatar-extra-small {
  border: 1px var(--semi-color-bg-1) solid;
  margin-left: -10px;
}

.semi-avatar-group .semi-avatar-extra-extra-small {
  border: 1px var(--semi-color-bg-1) solid;
  margin-left: -4px;
}

.semi-avatar-group .semi-avatar-item-start-0 {
  z-index: 100;
}

.semi-avatar-group .semi-avatar-item-end-0 {
  z-index: 80;
}

.semi-avatar-group .semi-avatar-item-start-1 {
  z-index: 99;
}

.semi-avatar-group .semi-avatar-item-end-1 {
  z-index: 81;
}

.semi-avatar-group .semi-avatar-item-start-2 {
  z-index: 98;
}

.semi-avatar-group .semi-avatar-item-end-2 {
  z-index: 82;
}

.semi-avatar-group .semi-avatar-item-start-3 {
  z-index: 97;
}

.semi-avatar-group .semi-avatar-item-end-3 {
  z-index: 83;
}

.semi-avatar-group .semi-avatar-item-start-4 {
  z-index: 96;
}

.semi-avatar-group .semi-avatar-item-end-4 {
  z-index: 84;
}

.semi-avatar-group .semi-avatar-item-start-5 {
  z-index: 95;
}

.semi-avatar-group .semi-avatar-item-end-5 {
  z-index: 85;
}

.semi-avatar-group .semi-avatar-item-start-6 {
  z-index: 94;
}

.semi-avatar-group .semi-avatar-item-end-6 {
  z-index: 86;
}

.semi-avatar-group .semi-avatar-item-start-7 {
  z-index: 93;
}

.semi-avatar-group .semi-avatar-item-end-7 {
  z-index: 87;
}

.semi-avatar-group .semi-avatar-item-start-8 {
  z-index: 92;
}

.semi-avatar-group .semi-avatar-item-end-8 {
  z-index: 88;
}

.semi-avatar-group .semi-avatar-item-start-9 {
  z-index: 91;
}

.semi-avatar-group .semi-avatar-item-end-9 {
  z-index: 89;
}

.semi-avatar-group .semi-avatar-item-start-10, .semi-avatar-group .semi-avatar-item-end-10 {
  z-index: 90;
}

.semi-avatar-group .semi-avatar-item-start-11 {
  z-index: 89;
}

.semi-avatar-group .semi-avatar-item-end-11 {
  z-index: 91;
}

.semi-avatar-group .semi-avatar-item-start-12 {
  z-index: 88;
}

.semi-avatar-group .semi-avatar-item-end-12 {
  z-index: 92;
}

.semi-avatar-group .semi-avatar-item-start-13 {
  z-index: 87;
}

.semi-avatar-group .semi-avatar-item-end-13 {
  z-index: 93;
}

.semi-avatar-group .semi-avatar-item-start-14 {
  z-index: 86;
}

.semi-avatar-group .semi-avatar-item-end-14 {
  z-index: 94;
}

.semi-avatar-group .semi-avatar-item-start-15 {
  z-index: 85;
}

.semi-avatar-group .semi-avatar-item-end-15 {
  z-index: 95;
}

.semi-avatar-group .semi-avatar-item-start-16 {
  z-index: 84;
}

.semi-avatar-group .semi-avatar-item-end-16 {
  z-index: 96;
}

.semi-avatar-group .semi-avatar-item-start-17 {
  z-index: 83;
}

.semi-avatar-group .semi-avatar-item-end-17 {
  z-index: 97;
}

.semi-avatar-group .semi-avatar-item-start-18 {
  z-index: 82;
}

.semi-avatar-group .semi-avatar-item-end-18 {
  z-index: 98;
}

.semi-avatar-group .semi-avatar-item-start-19 {
  z-index: 81;
}

.semi-avatar-group .semi-avatar-item-end-19 {
  z-index: 99;
}

.semi-avatar-group .semi-avatar-item-start-20 {
  z-index: 80;
}

.semi-avatar-group .semi-avatar-item-end-20 {
  z-index: 100;
}

.semi-avatar-group .semi-avatar-item-more {
  background-color: rgba(var(--semi-grey-5), 1);
}

.semi-avatar-amber {
  background-color: rgba(var(--semi-amber-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-blue {
  background-color: rgba(var(--semi-blue-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-cyan {
  background-color: rgba(var(--semi-cyan-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-green {
  background-color: rgba(var(--semi-green-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-grey {
  background-color: rgba(var(--semi-grey-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-indigo {
  background-color: rgba(var(--semi-indigo-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-light-blue {
  background-color: rgba(var(--semi-light-blue-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-light-green {
  background-color: rgba(var(--semi-light-green-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-lime {
  background-color: rgba(var(--semi-lime-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-orange {
  background-color: rgba(var(--semi-orange-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-pink {
  background-color: rgba(var(--semi-pink-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-purple {
  background-color: rgba(var(--semi-purple-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-red {
  background-color: rgba(var(--semi-red-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-teal {
  background-color: rgba(var(--semi-teal-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-violet {
  background-color: rgba(var(--semi-violet-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-yellow {
  background-color: rgba(var(--semi-yellow-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-rtl .semi-avatar, .semi-portal-rtl .semi-avatar {
  direction: rtl;
}

.semi-rtl .semi-avatar-extra-extra-small .semi-avatar-content, .semi-portal-rtl .semi-avatar-extra-extra-small .semi-avatar-content, .semi-rtl .semi-avatar-extra-small .semi-avatar-content, .semi-portal-rtl .semi-avatar-extra-small .semi-avatar-content {
  transform: scale(.8);
}

.semi-rtl .semi-avatar-hover, .semi-portal-rtl .semi-avatar-hover {
  left: auto;
  right: 0;
}

.semi-rtl .semi-avatar-group, .semi-portal-rtl .semi-avatar-group {
  direction: rtl;
}

.semi-rtl .semi-avatar-group .semi-avatar:first-child, .semi-portal-rtl .semi-avatar-group .semi-avatar:first-child {
  margin-left: auto;
  margin-right: 0;
}

.semi-rtl .semi-avatar-group .semi-avatar-extra-large, .semi-portal-rtl .semi-avatar-group .semi-avatar-extra-large {
  margin-left: auto;
  margin-right: -32px;
}

.semi-rtl .semi-avatar-group .semi-avatar-large, .semi-portal-rtl .semi-avatar-group .semi-avatar-large {
  margin-left: auto;
  margin-right: -18px;
}

.semi-rtl .semi-avatar-group .semi-avatar-medium, .semi-rtl .semi-avatar-group .semi-avatar-small, .semi-portal-rtl .semi-avatar-group .semi-avatar-medium, .semi-portal-rtl .semi-avatar-group .semi-avatar-small {
  margin-left: auto;
  margin-right: -12px;
}

.semi-rtl .semi-avatar-group .semi-avatar-extra-small, .semi-portal-rtl .semi-avatar-group .semi-avatar-extra-small {
  margin-left: auto;
  margin-right: -10px;
}

.semi-rtl .semi-avatar-group .semi-avatar-extra-extra-small, .semi-portal-rtl .semi-avatar-group .semi-avatar-extra-extra-small {
  margin-left: auto;
  margin-right: -4px;
}

.semi-tag {
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
  user-select: none;
  white-space: nowrap;
  vertical-align: bottom;
  background-color: #0000;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.semi-tag-default, .semi-tag-small {
  height: 20px;
  padding: 2px 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.semi-tag-default:focus-visible, .semi-tag-small:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}

.semi-tag-large {
  height: 24px;
  padding: 4px 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.semi-tag-large:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}

.semi-tag-invisible {
  display: none;
}

.semi-tag-content {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-tag-close {
  color: var(--semi-color-text-2);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  display: flex;
}

.semi-tag-closable {
  padding: 4px 4px 4px 8px;
}

.semi-tag-avatar-square .semi-avatar, .semi-tag-avatar-circle .semi-avatar {
  margin-right: 4px;
}

.semi-tag-avatar-square {
  padding: 0 4px 0 0;
}

.semi-tag-avatar-square .semi-avatar > img {
  background-color: var(--semi-color-default);
}

.semi-tag-avatar-circle {
  padding: 2px 4px 2px 2px;
}

.semi-tag-avatar-square.semi-tag-default .semi-avatar, .semi-tag-avatar-square.semi-tag-small .semi-avatar {
  width: 20px;
  height: 20px;
}

.semi-tag-avatar-square.semi-tag-large .semi-avatar {
  width: 24px;
  height: 24px;
}

.semi-tag-avatar-circle.semi-tag-small, .semi-tag-avatar-circle.semi-tag-default {
  border-radius: 11px;
}

.semi-tag-avatar-circle.semi-tag-small .semi-avatar, .semi-tag-avatar-circle.semi-tag-default .semi-avatar {
  width: 16px;
  height: 16px;
}

.semi-tag-avatar-circle.semi-tag-large {
  border-radius: 13px;
}

.semi-tag-avatar-circle.semi-tag-large .semi-avatar {
  width: 20px;
  height: 20px;
}

.semi-tag-group {
  height: auto;
  display: block;
}

.semi-tag-group .semi-tag {
  margin-bottom: 0;
  margin-right: 8px;
}

.semi-tag-group-max.semi-tag-group-small {
  height: 22px;
}

.semi-tag-group-max.semi-tag-group-large {
  height: 26px;
}

.semi-tag-rest-group-popover .semi-tag {
  margin-bottom: 0;
  margin-right: 8px;
}

.semi-tag-rest-group-popover .semi-tag:last-of-type {
  margin-right: 0;
}

.semi-tag-amber-ghost {
  border: 1px solid rgba(var(--semi-amber-4), 1);
  color: rgba(var(--semi-amber-5), 1);
  background-color: #0000;
}

.semi-tag-amber-solid {
  background-color: rgba(var(--semi-amber-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-amber-light {
  background-color: rgba(var(--semi-amber-5), .15);
  color: rgba(var(--semi-amber-8), 1);
}

.semi-tag-blue-ghost {
  border: 1px solid rgba(var(--semi-blue-4), 1);
  color: rgba(var(--semi-blue-5), 1);
  background-color: #0000;
}

.semi-tag-blue-solid {
  background-color: rgba(var(--semi-blue-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-blue-light {
  background-color: rgba(var(--semi-blue-5), .15);
  color: rgba(var(--semi-blue-8), 1);
}

.semi-tag-cyan-ghost {
  border: 1px solid rgba(var(--semi-cyan-4), 1);
  color: rgba(var(--semi-cyan-5), 1);
  background-color: #0000;
}

.semi-tag-cyan-solid {
  background-color: rgba(var(--semi-cyan-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-cyan-light {
  background-color: rgba(var(--semi-cyan-5), .15);
  color: rgba(var(--semi-cyan-8), 1);
}

.semi-tag-green-ghost {
  border: 1px solid rgba(var(--semi-green-4), 1);
  color: rgba(var(--semi-green-5), 1);
  background-color: #0000;
}

.semi-tag-green-solid {
  background-color: rgba(var(--semi-green-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-green-light {
  background-color: rgba(var(--semi-green-5), .15);
  color: rgba(var(--semi-green-8), 1);
}

.semi-tag-grey-ghost {
  border: 1px solid rgba(var(--semi-grey-4), 1);
  color: rgba(var(--semi-grey-5), 1);
  background-color: #0000;
}

.semi-tag-grey-solid {
  background-color: rgba(var(--semi-grey-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-grey-light {
  background-color: rgba(var(--semi-grey-5), .15);
  color: rgba(var(--semi-grey-8), 1);
}

.semi-tag-indigo-ghost {
  border: 1px solid rgba(var(--semi-indigo-4), 1);
  color: rgba(var(--semi-indigo-5), 1);
  background-color: #0000;
}

.semi-tag-indigo-solid {
  background-color: rgba(var(--semi-indigo-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-indigo-light {
  background-color: rgba(var(--semi-indigo-5), .15);
  color: rgba(var(--semi-indigo-8), 1);
}

.semi-tag-light-blue-ghost {
  border: 1px solid rgba(var(--semi-light-blue-4), 1);
  color: rgba(var(--semi-light-blue-5), 1);
  background-color: #0000;
}

.semi-tag-light-blue-solid {
  background-color: rgba(var(--semi-light-blue-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-light-blue-light {
  background-color: rgba(var(--semi-light-blue-5), .15);
  color: rgba(var(--semi-light-blue-8), 1);
}

.semi-tag-light-green-ghost {
  border: 1px solid rgba(var(--semi-light-green-4), 1);
  color: rgba(var(--semi-light-green-5), 1);
  background-color: #0000;
}

.semi-tag-light-green-solid {
  background-color: rgba(var(--semi-light-green-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-light-green-light {
  background-color: rgba(var(--semi-light-green-5), .15);
  color: rgba(var(--semi-light-green-8), 1);
}

.semi-tag-lime-ghost {
  border: 1px solid rgba(var(--semi-lime-4), 1);
  color: rgba(var(--semi-lime-5), 1);
  background-color: #0000;
}

.semi-tag-lime-solid {
  background-color: rgba(var(--semi-lime-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-lime-light {
  background-color: rgba(var(--semi-lime-5), .15);
  color: rgba(var(--semi-lime-8), 1);
}

.semi-tag-orange-ghost {
  border: 1px solid rgba(var(--semi-orange-4), 1);
  color: rgba(var(--semi-orange-5), 1);
  background-color: #0000;
}

.semi-tag-orange-solid {
  background-color: rgba(var(--semi-orange-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-orange-light {
  background-color: rgba(var(--semi-orange-5), .15);
  color: rgba(var(--semi-orange-8), 1);
}

.semi-tag-pink-ghost {
  border: 1px solid rgba(var(--semi-pink-4), 1);
  color: rgba(var(--semi-pink-5), 1);
  background-color: #0000;
}

.semi-tag-pink-solid {
  background-color: rgba(var(--semi-pink-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-pink-light {
  background-color: rgba(var(--semi-pink-5), .15);
  color: rgba(var(--semi-pink-8), 1);
}

.semi-tag-purple-ghost {
  border: 1px solid rgba(var(--semi-purple-4), 1);
  color: rgba(var(--semi-purple-5), 1);
  background-color: #0000;
}

.semi-tag-purple-solid {
  background-color: rgba(var(--semi-purple-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-purple-light {
  background-color: rgba(var(--semi-purple-5), .15);
  color: rgba(var(--semi-purple-8), 1);
}

.semi-tag-red-ghost {
  border: 1px solid rgba(var(--semi-red-4), 1);
  color: rgba(var(--semi-red-5), 1);
  background-color: #0000;
}

.semi-tag-red-solid {
  background-color: rgba(var(--semi-red-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-red-light {
  background-color: rgba(var(--semi-red-5), .15);
  color: rgba(var(--semi-red-8), 1);
}

.semi-tag-teal-ghost {
  border: 1px solid rgba(var(--semi-teal-4), 1);
  color: rgba(var(--semi-teal-5), 1);
  background-color: #0000;
}

.semi-tag-teal-solid {
  background-color: rgba(var(--semi-teal-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-teal-light {
  background-color: rgba(var(--semi-teal-5), .15);
  color: rgba(var(--semi-teal-8), 1);
}

.semi-tag-violet-ghost {
  border: 1px solid rgba(var(--semi-violet-4), 1);
  color: rgba(var(--semi-violet-5), 1);
  background-color: #0000;
}

.semi-tag-violet-solid {
  background-color: rgba(var(--semi-violet-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-violet-light {
  background-color: rgba(var(--semi-violet-5), .15);
  color: rgba(var(--semi-violet-8), 1);
}

.semi-tag-yellow-ghost {
  border: 1px solid rgba(var(--semi-yellow-4), 1);
  color: rgba(var(--semi-yellow-5), 1);
  background-color: #0000;
}

.semi-tag-yellow-solid {
  background-color: rgba(var(--semi-yellow-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-yellow-light {
  background-color: rgba(var(--semi-yellow-5), .15);
  color: rgba(var(--semi-yellow-8), 1);
}

.semi-tag-white-ghost, .semi-tag-white-solid, .semi-tag-white-light {
  background-color: var(--semi-color-bg-4);
  border: 1px solid rgba(var(--semi-grey-2), .7);
  color: var(--semi-color-text-0);
}

.semi-tag-white-ghost .semi-tag-close, .semi-tag-white-light .semi-tag-close, .semi-tag-white-solid .semi-tag-close {
  color: var(--semi-color-text-2);
}

.semi-tag-avatar-square, .semi-tag-avatar-circle {
  background-color: var(--semi-color-bg-4);
  border: 1px solid var(--semi-color-border);
  color: var(--semi-color-text-0);
}

.semi-tag-solid .semi-tag-close {
  color: var(--semi-color-white);
}

.semi-rtl .semi-tag, .semi-portal-rtl .semi-tag {
  direction: rtl;
}

.semi-rtl .semi-tag-close, .semi-portal-rtl .semi-tag-close {
  padding-left: auto;
  padding-right: 4px;
}

.semi-rtl .semi-tag-closable, .semi-portal-rtl .semi-tag-closable {
  padding: 4px 8px 4px 4px;
}

.semi-rtl .semi-tag-avatar-square .semi-avatar, .semi-rtl .semi-tag-avatar-circle .semi-avatar, .semi-portal-rtl .semi-tag-avatar-square .semi-avatar, .semi-portal-rtl .semi-tag-avatar-circle .semi-avatar {
  margin-left: 4px;
  margin-right: auto;
}

.semi-rtl .semi-tag-avatar-square, .semi-portal-rtl .semi-tag-avatar-square {
  padding-left: 4px;
  padding-right: auto;
}

.semi-rtl .semi-tag-avatar-circle, .semi-portal-rtl .semi-tag-avatar-circle {
  padding: 2px 2px 2px 4px;
}

.semi-rtl .semi-tag-group, .semi-portal-rtl .semi-tag-group {
  direction: rtl;
}

.semi-rtl .semi-tag-group .semi-tag, .semi-portal-rtl .semi-tag-group .semi-tag {
  margin-left: 8px;
  margin-right: auto;
}

.semi-rtl .semi-tag-rest-group-popover, .semi-portal-rtl .semi-tag-rest-group-popover {
  direction: rtl;
}

.semi-rtl .semi-tag-rest-group-popover .semi-tag, .semi-portal-rtl .semi-tag-rest-group-popover .semi-tag {
  margin-left: 8px;
  margin-right: 0;
}

.semi-rtl .semi-tag-rest-group-popover .semi-tag:last-of-type, .semi-portal-rtl .semi-tag-rest-group-popover .semi-tag:last-of-type {
  margin-left: 0;
  margin-right: auto;
}

@keyframes semi-tooltip-zoomIn {
  from {
    opacity: 0;
    transform: scale(.8);
  }

  50% {
    opacity: 1;
  }
}

@keyframes semi-tooltip-bounceIn {
  from {
    opacity: 0;
    transform: scale(.6);
  }

  70% {
    opacity: 1;
    transform: scale(1.01);
  }

  to {
    opacity: 1;
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: scale(1);
  }
}

@keyframes semi-tooltip-zoomOut {
  from {
    opacity: 1;
  }

  60% {
    opacity: 0;
    transform: scale(.8);
  }

  to {
    opacity: 0;
  }
}

.semi-tooltip-wrapper {
  background-color: rgba(var(--semi-grey-7), 1);
  color: var(--semi-color-bg-0);
  border-radius: var(--semi-border-radius-medium);
  visibility: hidden;
  max-width: 240px;
  padding: 8px 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  top: 0;
  left: 0;
}

.semi-tooltip-wrapper-show {
  visibility: visible;
}

.semi-tooltip-trigger {
  width: auto;
  height: auto;
  display: inline-block;
}

.semi-tooltip-with-arrow {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-tooltip-wrapper .semi-tooltip-icon-arrow {
  height: 7px;
  width: 24px;
  color: rgba(var(--semi-grey-7), 1);
  position: absolute;
}

.semi-tooltip-wrapper[x-placement="top"] .semi-tooltip-icon-arrow {
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.semi-tooltip-wrapper[x-placement="top"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="top"] .semi-tooltip-with-arrow {
  min-width: 36px;
}

.semi-tooltip-wrapper[x-placement="topLeft"] .semi-tooltip-icon-arrow {
  bottom: -6px;
  left: 6px;
}

.semi-tooltip-wrapper[x-placement="topLeft"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="topLeft"] .semi-tooltip-with-arrow {
  min-width: 36px;
}

.semi-tooltip-wrapper[x-placement="topRight"] .semi-tooltip-icon-arrow {
  bottom: -6px;
  right: 6px;
}

.semi-tooltip-wrapper[x-placement="topRight"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="topRight"] .semi-tooltip-with-arrow {
  min-width: 36px;
}

.semi-tooltip-wrapper[x-placement="leftTop"] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  top: 5px;
  right: -6px;
}

.semi-tooltip-wrapper[x-placement="leftTop"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="leftTop"] .semi-tooltip-with-arrow {
  min-height: 34px;
}

.semi-tooltip-wrapper[x-placement="left"] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  top: 50%;
  right: -6px;
  transform: translateY(-50%);
}

.semi-tooltip-wrapper[x-placement="left"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="left"] .semi-tooltip-with-arrow {
  min-height: 34px;
}

.semi-tooltip-wrapper[x-placement="leftBottom"] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  bottom: 5px;
  right: -6px;
}

.semi-tooltip-wrapper[x-placement="leftBottom"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="leftBottom"] .semi-tooltip-with-arrow {
  min-height: 34px;
}

.semi-tooltip-wrapper[x-placement="rightTop"] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  top: 5px;
  left: -6px;
  transform: rotate(180deg);
}

.semi-tooltip-wrapper[x-placement="rightTop"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="rightTop"] .semi-tooltip-with-arrow {
  min-height: 34px;
}

.semi-tooltip-wrapper[x-placement="right"] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  top: 50%;
  left: -6px;
  transform: translateY(-50%)rotate(180deg);
}

.semi-tooltip-wrapper[x-placement="right"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="right"] .semi-tooltip-with-arrow {
  min-height: 34px;
}

.semi-tooltip-wrapper[x-placement="rightBottom"] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  bottom: 5px;
  left: -6px;
  transform: rotate(180deg);
}

.semi-tooltip-wrapper[x-placement="rightBottom"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="rightBottom"] .semi-tooltip-with-arrow {
  min-height: 34px;
}

.semi-tooltip-wrapper[x-placement="bottomLeft"] .semi-tooltip-icon-arrow {
  top: -6px;
  left: 6px;
  transform: rotate(180deg);
}

.semi-tooltip-wrapper[x-placement="bottomLeft"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="bottomLeft"] .semi-tooltip-with-arrow {
  min-width: 36px;
}

.semi-tooltip-wrapper[x-placement="bottom"] .semi-tooltip-icon-arrow {
  top: -6px;
  left: 50%;
  transform: translateX(-50%)rotate(180deg);
}

.semi-tooltip-wrapper[x-placement="bottom"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="bottom"] .semi-tooltip-with-arrow {
  min-width: 36px;
}

.semi-tooltip-wrapper[x-placement="bottomRight"] .semi-tooltip-icon-arrow {
  top: -6px;
  right: 6px;
  transform: rotate(180deg);
}

.semi-tooltip-wrapper[x-placement="bottomRight"].semi-tooltip-with-arrow, .semi-tooltip-wrapper[x-placement="bottomRight"] .semi-tooltip-with-arrow {
  min-width: 36px;
}

.semi-rtl .semi-tooltip-wrapper, .semi-portal-rtl .semi-tooltip-wrapper {
  direction: rtl;
  padding-left: 12px;
  padding-right: 12px;
  left: auto;
  right: 0;
}

.semi-portal {
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-portal-inner {
  min-width: fit-content;
  background-color: #0000;
  position: absolute;
}

.semi-popover-wrapper {
  background-color: var(--semi-color-bg-3);
  box-shadow: var(--semi-shadow-elevated);
  z-index: 1030;
  border-radius: var(--semi-border-radius-medium);
  visibility: hidden;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.semi-popover-wrapper-show {
  visibility: visible;
}

.semi-popover-trigger {
  width: auto;
  height: auto;
  display: inline-block;
}

.semi-popover-title {
  border-bottom: 1px solid var(--semi-color-border);
  padding: 8px;
}

.semi-popover-confirm {
  position: absolute;
}

.semi-popover-with-arrow {
  box-sizing: border-box;
  padding: 12px;
}

.semi-popover-wrapper .semi-popover-icon-arrow {
  height: 8px;
  width: 24px;
  color: unset;
  position: absolute;
}

.semi-popover-wrapper[x-placement="top"] .semi-popover-icon-arrow {
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.semi-popover-wrapper[x-placement="top"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="top"] .semi-popover-with-arrow {
  min-width: 36px;
}

.semi-popover-wrapper[x-placement="topLeft"] .semi-popover-icon-arrow {
  bottom: -7px;
  left: 6px;
}

.semi-popover-wrapper[x-placement="topLeft"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="topLeft"] .semi-popover-with-arrow {
  min-width: 36px;
}

.semi-popover-wrapper[x-placement="topRight"] .semi-popover-icon-arrow {
  bottom: -7px;
  right: 6px;
}

.semi-popover-wrapper[x-placement="topRight"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="topRight"] .semi-popover-with-arrow {
  min-width: 36px;
}

.semi-popover-wrapper[x-placement="leftTop"] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  top: 6px;
  right: -7px;
}

.semi-popover-wrapper[x-placement="leftTop"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="leftTop"] .semi-popover-with-arrow {
  min-height: 36px;
}

.semi-popover-wrapper[x-placement="left"] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  top: 50%;
  right: -7px;
  transform: translateY(-50%);
}

.semi-popover-wrapper[x-placement="left"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="left"] .semi-popover-with-arrow {
  min-height: 36px;
}

.semi-popover-wrapper[x-placement="leftBottom"] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  bottom: 6px;
  right: -7px;
}

.semi-popover-wrapper[x-placement="leftBottom"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="leftBottom"] .semi-popover-with-arrow {
  min-height: 36px;
}

.semi-popover-wrapper[x-placement="rightTop"] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  top: 6px;
  left: -7px;
  transform: rotate(180deg);
}

.semi-popover-wrapper[x-placement="rightTop"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="rightTop"] .semi-popover-with-arrow {
  min-height: 36px;
}

.semi-popover-wrapper[x-placement="right"] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  top: 50%;
  left: -7px;
  transform: translateY(-50%)rotate(180deg);
}

.semi-popover-wrapper[x-placement="right"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="right"] .semi-popover-with-arrow {
  min-height: 36px;
}

.semi-popover-wrapper[x-placement="rightBottom"] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  bottom: 6px;
  left: -7px;
  transform: rotate(180deg);
}

.semi-popover-wrapper[x-placement="rightBottom"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="rightBottom"] .semi-popover-with-arrow {
  min-height: 36px;
}

.semi-popover-wrapper[x-placement="bottomLeft"] .semi-popover-icon-arrow {
  top: -7px;
  left: 6px;
  transform: rotate(180deg);
}

.semi-popover-wrapper[x-placement="bottomLeft"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="bottomLeft"] .semi-popover-with-arrow {
  min-width: 36px;
}

.semi-popover-wrapper[x-placement="bottom"] .semi-popover-icon-arrow {
  top: -7px;
  left: 50%;
  transform: translateX(-50%)rotate(180deg);
}

.semi-popover-wrapper[x-placement="bottom"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="bottom"] .semi-popover-with-arrow {
  min-width: 36px;
}

.semi-popover-wrapper[x-placement="bottomRight"] .semi-popover-icon-arrow {
  top: -7px;
  right: 6px;
  transform: rotate(180deg);
}

.semi-popover-wrapper[x-placement="bottomRight"].semi-popover-with-arrow, .semi-popover-wrapper[x-placement="bottomRight"] .semi-popover-with-arrow {
  min-width: 36px;
}

.semi-popover.semi-popover-rtl {
  direction: rtl;
}

@keyframes semi-input-active {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(.97);
  }
}

@keyframes semi-input-inactive {
  from {
    transform: scale(.97);
  }

  to {
    transform: scale(1);
  }
}

.semi-input-wrapper {
  vertical-align: middle;
  box-shadow: none;
  background-color: var(--semi-color-fill-0);
  border-radius: var(--semi-border-radius-small);
  width: 100%;
  cursor: text;
  box-sizing: border-box;
  color: var(--semi-color-text-0);
  border: 1px solid #0000;
  outline: none;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  position: relative;
}

.semi-input-wrapper-default {
  height: 32px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 30px;
}

.semi-input-wrapper-small {
  height: 24px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
}

.semi-input-wrapper-large {
  height: 40px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 38px;
}

.semi-input-wrapper:hover {
  background-color: var(--semi-color-fill-1);
  border-color: #0000;
}

.semi-input-wrapper-focus {
  background-color: var(--semi-color-fill-0);
  border: var(--semi-color-focus-border) solid 1px;
}

.semi-input-wrapper-focus:hover, .semi-input-wrapper-focus:active {
  background-color: var(--semi-color-fill-0);
  border-color: var(--semi-color-focus-border);
}

.semi-input-wrapper.semi-input-readonly {
  cursor: default;
}

.semi-input-wrapper-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}

.semi-input-wrapper-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}

.semi-input-wrapper-error.semi-input-wrapper-focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}

.semi-input-wrapper-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}

.semi-input-wrapper-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}

.semi-input-wrapper-warning.semi-input-wrapper-focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}

.semi-input-wrapper__with-prefix {
  align-items: center;
  display: inline-flex;
}

.semi-input-wrapper__with-prefix .semi-input {
  padding-left: 0;
}

.semi-input-wrapper__with-suffix {
  align-items: center;
  display: inline-flex;
}

.semi-input-wrapper__with-suffix .semi-input {
  padding-right: 0;
}

.semi-input-wrapper-clearable, .semi-input-wrapper-modebtn {
  align-items: center;
  display: inline-flex;
}

.semi-input-wrapper-hidden {
  border: none;
}

.semi-input-wrapper .semi-icon {
  color: var(--semi-color-text-2);
}

.semi-input-wrapper .semi-input-clearbtn, .semi-input-wrapper .semi-input-modebtn {
  color: var(--semi-color-primary-hover);
}

.semi-input-wrapper .semi-input-clearbtn > svg, .semi-input-wrapper .semi-input-modebtn > svg {
  pointer-events: none;
}

.semi-input-wrapper .semi-input-clearbtn:hover, .semi-input-wrapper .semi-input-modebtn:hover {
  cursor: pointer;
}

.semi-input-wrapper .semi-input-clearbtn:hover .semi-icon, .semi-input-wrapper .semi-input-modebtn:hover .semi-icon {
  color: var(--semi-color-primary-hover);
}

.semi-input-wrapper .semi-input-clearbtn:focus-visible, .semi-input-wrapper .semi-input-modebtn:focus-visible {
  border-radius: var(--semi-border-radius-small);
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -1px;
}

.semi-input-wrapper__with-suffix-icon.semi-input-wrapper-clearable:not(.semi-input-wrapper__with-suffix-hidden) .semi-input-clearbtn {
  min-width: 24px;
  justify-content: flex-end;
}

.semi-input-wrapper-modebtn.semi-input-wrapper-clearable .semi-input-clearbtn {
  min-width: 16px;
  justify-content: center;
}

.semi-input-wrapper.semi-input-wrapper__with-append-only .semi-input {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}

.semi-input-wrapper.semi-input-wrapper__with-append-only .semi-input:not(:last-child) {
  border-right-style: none;
  border-radius: 0;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend-only .semi-input {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend-only .semi-input:not(:last-child) {
  border-right-style: none;
  border-radius: 0;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend, .semi-input-wrapper.semi-input-wrapper__with-append {
  background-color: #0000;
  align-items: center;
  display: inline-flex;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend:hover, .semi-input-wrapper.semi-input-wrapper__with-append:hover {
  background-color: #0000;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-focus, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-focus {
  background-color: #0000;
  border: 1px solid #0000;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input {
  background-color: var(--semi-color-fill-0);
  border: 1px solid #0000;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:hover, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:hover, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:hover ~ .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:hover ~ .semi-input-modebtn {
  background-color: var(--semi-color-fill-1);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus {
  border: 1px var(--semi-color-focus-border) solid;
  background-color: var(--semi-color-fill-0);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus.semi-input-sibling-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus.semi-input-sibling-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus.semi-input-sibling-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus.semi-input-sibling-modebtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus.semi-input-sibling-modebtn + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus.semi-input-sibling-modebtn + .semi-input-clearbtn {
  border-right-style: none;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus ~ .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus ~ .semi-input-modebtn {
  box-sizing: border-box;
  background-color: var(--semi-color-fill-0);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus + .semi-input-clearbtn {
  border: 1px var(--semi-color-focus-border) solid;
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
  border-left-style: none;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus + .semi-input-clearbtn:not(:last-child), .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus + .semi-input-clearbtn:not(:last-child) {
  border-radius: 0;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus ~ .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus ~ .semi-input-modebtn {
  border: 1px var(--semi-color-focus-border) solid;
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
  border-left-style: none;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus ~ .semi-input-modebtn:not(:last-child), .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus ~ .semi-input-modebtn:not(:last-child) {
  border-radius: 0;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn:hover {
  background-color: var(--semi-color-fill-0);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn:hover:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error {
  border-color: #0000;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:hover + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:hover + .semi-input-modebtn {
  background-color: var(--semi-color-danger-light-hover);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:focus + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:focus + .semi-input-modebtn {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn:hover {
  background-color: var(--semi-color-danger-light-default);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn:hover:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning {
  border-color: #0000;
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:hover + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:hover + .semi-input-modebtn {
  background-color: var(--semi-color-warning-light-hover);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:focus + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:focus + .semi-input-modebtn {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn:hover {
  background-color: var(--semi-color-warning-light-default);
}

.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn:hover:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}

.semi-input-wrapper-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-fill);
  -webkit-text-fill-color: var(--semi-color-disabled-text);
}

.semi-input-wrapper-disabled:hover {
  background-color: var(--semi-color-disabled-fill);
}

.semi-input-wrapper-disabled .semi-input-append, .semi-input-wrapper-disabled .semi-input-prepend, .semi-input-wrapper-disabled .semi-input-suffix, .semi-input-wrapper-disabled .semi-input-prefix, .semi-input-wrapper-disabled .semi-icon {
  color: var(--semi-color-disabled-text);
}

.semi-input {
  width: 100%;
  color: inherit;
  box-sizing: border-box;
  background-color: #0000;
  border: none;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
}

.semi-input::placeholder {
  color: var(--semi-color-text-2);
}

.semi-input-large {
  height: 38px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 38px;
}

.semi-input-small {
  height: 22px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.semi-input-default {
  height: 30px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 30px;
}

.semi-input-disabled {
  cursor: not-allowed;
  color: inherit;
}

.semi-input-inset-label {
  color: var(--semi-color-text-2);
  white-space: nowrap;
  flex-shrink: 0;
  margin-right: 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.semi-input-prefix, .semi-input-suffix {
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-input-prefix-text, .semi-input-suffix-text {
  color: var(--semi-color-text-2);
  white-space: nowrap;
  margin: 0 12px;
  font-weight: 600;
}

.semi-input-prefix-icon, .semi-input-suffix-icon {
  color: var(--semi-color-text-2);
  margin: 0 8px;
}

.semi-input-suffix {
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-input-clearbtn, .semi-input-modebtn {
  height: 100%;
  min-width: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-input-clearbtn + .semi-input-suffix + .semi-input-suffix-text, .semi-input-clearbtn + .semi-input-suffix + .semi-input-suffix-icon {
  margin-left: 0;
}

.semi-input-suffix-hidden {
  display: none;
}

.semi-input-prepend, .semi-input-append {
  height: 100%;
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  align-items: center;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.semi-input-prepend-icon, .semi-input-prepend-text, .semi-input-append-icon, .semi-input-append-text {
  padding: 0 12px;
}

.semi-input-append {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
  border-left: 1px solid #0000;
}

.semi-input-prepend {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
  border-right: 1px solid #0000;
}

.semi-input-disabled::placeholder {
  color: var(--semi-color-disabled-text);
}

.semi-input-group {
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  display: inline-flex;
}

.semi-input-group .semi-select, .semi-input-group .semi-cascader, .semi-input-group .semi-tree-select, .semi-input-group > .semi-input-wrapper {
  border-radius: 0;
}

.semi-input-group .semi-select:first-child, .semi-input-group .semi-cascader:first-child, .semi-input-group .semi-tree-select:first-child, .semi-input-group > .semi-input-wrapper:first-child {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}

.semi-input-group .semi-select:last-child, .semi-input-group .semi-cascader:last-child, .semi-input-group .semi-tree-select:last-child, .semi-input-group > .semi-input-wrapper:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}

.semi-input-group .semi-select:not(:last-child), .semi-input-group .semi-cascader:not(:last-child), .semi-input-group .semi-tree-select:not(:last-child), .semi-input-group > .semi-input-wrapper:not(:last-child) {
  position: relative;
}

.semi-input-group .semi-select:not(:last-child):after, .semi-input-group .semi-cascader:not(:last-child):after, .semi-input-group .semi-tree-select:not(:last-child):after, .semi-input-group > .semi-input-wrapper:not(:last-child):after {
  content: "";
  background-color: var(--semi-color-border);
  width: 1px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: -1px;
}

.semi-input-group .semi-input-number, .semi-input-group .semi-datepicker, .semi-input-group .semi-autocomplete, .semi-input-group .semi-input-number .semi-datepicker-range-input, .semi-input-group .semi-datepicker .semi-datepicker-range-input, .semi-input-group .semi-autocomplete .semi-datepicker-range-input {
  border-radius: 0;
}

.semi-input-group .semi-input-number:first-child .semi-input-wrapper, .semi-input-group .semi-input-number:first-child .semi-datepicker-range-input, .semi-input-group .semi-datepicker:first-child .semi-input-wrapper, .semi-input-group .semi-datepicker:first-child .semi-datepicker-range-input, .semi-input-group .semi-autocomplete:first-child .semi-input-wrapper, .semi-input-group .semi-autocomplete:first-child .semi-datepicker-range-input {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}

.semi-input-group .semi-input-number:last-child .semi-input-wrapper, .semi-input-group .semi-input-number:last-child .semi-datepicker-range-input, .semi-input-group .semi-datepicker:last-child .semi-input-wrapper, .semi-input-group .semi-datepicker:last-child .semi-datepicker-range-input, .semi-input-group .semi-autocomplete:last-child .semi-input-wrapper, .semi-input-group .semi-autocomplete:last-child .semi-datepicker-range-input {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}

.semi-input-group .semi-input-number:not(:last-child), .semi-input-group .semi-datepicker:not(:last-child), .semi-input-group .semi-autocomplete:not(:last-child) {
  position: relative;
}

.semi-input-group .semi-input-number:not(:last-child):after, .semi-input-group .semi-datepicker:not(:last-child):after, .semi-input-group .semi-autocomplete:not(:last-child):after {
  content: "";
  background-color: var(--semi-color-border);
  width: 1px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: -1px;
}

.semi-input-group-wrapper-with-top-label {
  margin-top: 16px;
  margin-bottom: 16px;
}

.semi-input-group-wrapper-with-top-label .semi-input-group {
  display: flex;
}

.semi-input-group-wrapper-with-top-label .semi-input-group .semi-form-field {
  margin-top: 0;
  margin-bottom: 0;
}

.semi-rtl .semi-input-wrapper, .semi-portal-rtl .semi-input-wrapper {
  direction: rtl;
}

.semi-rtl .semi-input-wrapper__with-prefix .semi-input, .semi-portal-rtl .semi-input-wrapper__with-prefix .semi-input {
  padding-left: auto;
  padding-right: 0;
}

.semi-rtl .semi-input-wrapper__with-suffix .semi-input, .semi-portal-rtl .semi-input-wrapper__with-suffix .semi-input {
  padding-left: 0;
  padding-right: auto;
}

.semi-rtl .semi-input, .semi-portal-rtl .semi-input {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-input-inset-label, .semi-portal-rtl .semi-input-inset-label {
  margin-left: 12px;
  margin-right: auto;
}

.semi-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-text, .semi-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-text, .semi-portal-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-text, .semi-portal-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-text, .semi-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-icon, .semi-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-icon, .semi-portal-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-icon, .semi-portal-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-icon {
  margin-left: auto;
  margin-right: 0;
}

.semi-rtl .semi-input-append, .semi-portal-rtl .semi-input-append {
  border-left: 0;
  border-right: 1px solid #0000;
}

.semi-rtl .semi-input-prepend, .semi-portal-rtl .semi-input-prepend {
  border-left: 1px solid #0000;
  border-right: 0;
}

.semi-rtl .semi-input-group .semi-select:not(:last-child):after, .semi-rtl .semi-input-group .semi-cascader:not(:last-child):after, .semi-rtl .semi-input-group .semi-tree-select:not(:last-child):after, .semi-rtl .semi-input-group > .semi-input-wrapper:not(:last-child):after, .semi-portal-rtl .semi-input-group .semi-select:not(:last-child):after, .semi-portal-rtl .semi-input-group .semi-cascader:not(:last-child):after, .semi-portal-rtl .semi-input-group .semi-tree-select:not(:last-child):after, .semi-portal-rtl .semi-input-group > .semi-input-wrapper:not(:last-child):after, .semi-rtl .semi-input-group .semi-input-number:not(:last-child):after, .semi-portal-rtl .semi-input-group .semi-input-number:not(:last-child):after {
  left: -1px;
  right: auto;
}

.semi-rtl .semi-input-textarea-wrapper, .semi-portal-rtl .semi-input-textarea-wrapper {
  direction: rtl;
}

.semi-rtl .semi-input-textarea-counter, .semi-portal-rtl .semi-input-textarea-counter {
  text-align: left;
}

.semi-rtl .semi-input-textarea-showClear, .semi-portal-rtl .semi-input-textarea-showClear {
  padding-left: 36px;
  padding-right: 0;
}

.semi-select-option {
  word-break: break-all;
  color: var(--semi-color-text-0);
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 0;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.semi-select-option-icon {
  width: 12px;
  color: #0000;
  visibility: hidden;
  place-content: center;
  margin-right: 8px;
  display: flex;
}

.semi-select-option-text {
  white-space: pre;
  flex-wrap: wrap;
  display: flex;
}

.semi-select-option-keyword {
  color: var(--semi-color-primary);
  background-color: inherit;
  font-weight: 600;
}

.semi-select-option:active {
  background-color: var(--semi-color-fill-1);
}

.semi-select-option-empty {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  justify-content: center;
}

.semi-select-option-empty:hover, .semi-select-option-empty:active {
  background-color: inherit;
}

.semi-select-option-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}

.semi-select-option-disabled:hover {
  background-color: var(--semi-color-fill-0);
}

.semi-select-option-selected {
  font-weight: 600;
}

.semi-select-option-selected .semi-select-option-icon {
  visibility: visible;
  color: var(--semi-color-text-2);
}

.semi-select-option-focused {
  background-color: var(--semi-color-fill-0);
}

.semi-select-option:first-of-type {
  margin-top: 4px;
}

.semi-select-option:last-of-type {
  margin-bottom: 4px;
}

.semi-select {
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
  height: 32px;
  background-color: var(--semi-color-fill-0);
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #0000;
  outline: none;
  font-weight: 400;
  display: inline-flex;
  position: relative;
}

.semi-select:hover {
  background-color: var(--semi-color-fill-1);
  border: 1px solid #0000;
}

.semi-select:active {
  background-color: var(--semi-color-fill-2);
  border: 1px solid var(--semi-color-focus-border);
}

.semi-select:focus {
  border: 1px solid var(--semi-color-focus-border);
  outline: 0;
}

.semi-select-small {
  height: 24px;
  line-height: 24px;
}

.semi-select-large {
  min-height: 40px;
  line-height: 40px;
}

.semi-select-large .semi-select-selection {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
}

.semi-select-open, .semi-select-focus {
  border: 1px solid var(--semi-color-focus-border);
  outline: 0;
}

.semi-select-open:hover, .semi-select-focus:hover {
  background-color: var(--semi-color-fill-0);
  border: 1px solid var(--semi-color-focus-border);
}

.semi-select-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}

.semi-select-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}

.semi-select-warning:focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}

.semi-select-warning:active {
  background-color: var(--semi-color-warning-light-active);
  border-color: var(--semi-color-warning-light-active);
}

.semi-select-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}

.semi-select-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}

.semi-select-error:focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}

.semi-select-error:active {
  background-color: var(--semi-color-danger-light-active);
  border-color: var(--semi-color-danger-light-active);
}

.semi-select-disabled {
  cursor: not-allowed;
  background-color: var(--semi-color-disabled-fill);
}

.semi-select-disabled:hover {
  background-color: var(--semi-color-disabled-fill);
}

.semi-select-disabled:focus {
  border: 1px solid var(--semi-color-focus-border);
}

.semi-select-disabled .semi-select-selection, .semi-select-disabled .semi-select-selection-placeholder {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}

.semi-select-disabled .semi-select-arrow, .semi-select-disabled .semi-select-prefix, .semi-select-disabled .semi-select-suffix {
  color: var(--semi-color-disabled-text);
}

.semi-select-disabled .semi-tag {
  color: var(--semi-color-disabled-text);
  background-color: #0000;
}

.semi-select-selection {
  height: 100%;
  cursor: pointer;
  color: var(--semi-color-text-0);
  flex-grow: 1;
  align-items: center;
  margin-left: 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  overflow: hidden;
}

.semi-select-selection-text {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.semi-select-selection-text-inactive {
  opacity: .4;
  display: flex;
}

.semi-select-selection-text-hide {
  display: none;
}

.semi-select-selection-placeholder {
  color: var(--semi-color-text-2);
}

.semi-select-selection .semi-tag {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 4px;
}

.semi-select-selection .semi-tag:nth-of-type(1) {
  margin-left: 0;
}

.semi-select-selection .semi-tag-group {
  height: inherit;
}

.semi-select-selection .semi-tag-group .semi-tag {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 4px;
}

.semi-select-content-wrapper {
  white-space: nowrap;
  height: 100%;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.semi-select-multiple {
  height: auto;
}

.semi-select-multiple .semi-select-selection {
  margin-left: 4px;
}

.semi-select-multiple .semi-select-content-wrapper {
  width: 100%;
  min-height: 30px;
  flex-wrap: wrap;
}

.semi-select-multiple .semi-select-content-wrapper-empty {
  margin-left: 8px;
}

.semi-select-multiple .semi-select-content-wrapper .semi-tag-group {
  align-items: center;
  display: flex;
}

.semi-select-multiple .semi-select-content-wrapper-one-line {
  flex-wrap: nowrap;
}

.semi-select-multiple .semi-select-content-wrapper-one-line .semi-tag-group {
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  overflow: hidden;
}

.semi-select-multiple .semi-select-inline-label-wrapper {
  flex-shrink: 0;
}

.semi-select-multiple.semi-select-large .semi-select-content-wrapper {
  min-height: 38px;
}

.semi-select-multiple.semi-select-small .semi-select-content-wrapper {
  min-height: 22px;
}

.semi-select-arrow {
  width: 32px;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-select-arrow-empty {
  width: 12px;
  display: flex;
}

.semi-select-prefix, .semi-select-suffix {
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-select-prefix-text, .semi-select-suffix-text {
  margin: 0 12px;
}

.semi-select-prefix-icon, .semi-select-suffix-icon {
  color: var(--semi-color-text-2);
  margin: 0 8px;
}

.semi-select-suffix {
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-select-clear {
  width: 32px;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-select-clear:hover {
  color: var(--semi-color-primary);
}

.semi-select-inset-label-wrapper {
  display: inline;
}

.semi-select-inset-label {
  color: var(--semi-color-text-2);
  white-space: nowrap;
  flex-shrink: 0;
  margin-right: 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.semi-select-create-tips {
  color: var(--semi-color-text-2);
  margin-right: 4px;
}

.semi-select-with-prefix .semi-select-selection {
  margin-left: 0;
}

.semi-select-single.semi-select-filterable .semi-select-content-wrapper {
  height: 100%;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.semi-select-single.semi-select-filterable .semi-input-wrapper {
  height: 100%;
  width: 100%;
  background-color: #0000;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-select-single.semi-select-filterable .semi-input-wrapper-focus {
  border: none;
}

.semi-select-single.semi-select-filterable .semi-input {
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.semi-select-multiple.semi-select-filterable .semi-select-content-wrapper {
  height: 100%;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.semi-select-multiple.semi-select-filterable .semi-select-content-wrapper-empty .semi-input-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-select-multiple.semi-select-filterable .semi-input-wrapper {
  height: 100%;
  width: 100%;
  background-color: #0000;
  border: none;
}

.semi-select-multiple.semi-select-filterable .semi-input-wrapper-focus {
  border: none;
}

.semi-select-multiple.semi-select-filterable .semi-input {
  padding-left: 0;
  padding-right: 0;
}

.semi-select-option-list {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.semi-select-option-list-chosen .semi-select-option-icon {
  display: flex;
}

.semi-select-group {
  color: var(--semi-color-text-2);
  cursor: default;
  margin-top: 4px;
  padding: 12px 16px 4px 32px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.semi-select-group:not(:nth-of-type(1)) {
  border-top: 1px solid var(--semi-color-border);
}

.semi-select-loading-wrapper {
  cursor: not-allowed;
  padding: 8px 16px;
}

.semi-rtl .semi-select, .semi-portal-rtl .semi-select {
  direction: rtl;
}

.semi-rtl .semi-select-selection, .semi-portal-rtl .semi-select-selection {
  margin-left: 0;
  margin-right: 12px;
}

.semi-rtl .semi-select-selection .semi-tag:nth-of-type(1), .semi-portal-rtl .semi-select-selection .semi-tag:nth-of-type(1) {
  margin-right: 0;
}

.semi-rtl .semi-select-selection .semi-tag-group .semi-tag, .semi-portal-rtl .semi-select-selection .semi-tag-group .semi-tag {
  margin-left: 4px;
  margin-right: 0;
}

.semi-rtl .semi-select-multiple .semi-select-selection, .semi-portal-rtl .semi-select-multiple .semi-select-selection {
  margin-left: 0;
  margin-right: 4px;
}

.semi-rtl .semi-select-multiple .semi-select-content-wrapper-empty, .semi-portal-rtl .semi-select-multiple .semi-select-content-wrapper-empty {
  margin-left: 0;
  margin-right: 8px;
}

.semi-rtl .semi-select-inset-label, .semi-portal-rtl .semi-select-inset-label {
  margin-left: 12px;
}

.semi-rtl .semi-select-create-tips, .semi-portal-rtl .semi-select-create-tips {
  margin-left: 4px;
  margin-right: 0;
}

.semi-rtl .semi-select-with-prefix .semi-select-selection, .semi-portal-rtl .semi-select-with-prefix .semi-select-selection {
  margin-left: auto;
  margin-right: 0;
}

.semi-rtl .semi-select-single.semi-select-filterable .semi-input-wrapper, .semi-portal-rtl .semi-select-single.semi-select-filterable .semi-input-wrapper, .semi-rtl .semi-select-multiple.semi-select-filterable .semi-select-content-wrapper-empty .semi-input-wrapper, .semi-portal-rtl .semi-select-multiple.semi-select-filterable .semi-select-content-wrapper-empty .semi-input-wrapper {
  left: auto;
  right: 0;
}

.semi-rtl .semi-select-group, .semi-portal-rtl .semi-select-group {
  padding-left: 32px;
  padding-right: 16px;
}

.semi-rtl .semi-select-option-icon, .semi-portal-rtl .semi-select-option-icon {
  margin-left: 8px;
  margin-right: 0;
}

.semi-input-number {
  box-sizing: border-box;
  align-items: center;
  display: inline-flex;
}

.semi-input-number-suffix-btns {
  border: 1px solid var(--semi-color-border);
  border-radius: var(--semi-border-radius-small);
  background-color: var(--semi-color-bg-2);
  box-sizing: border-box;
  flex-direction: column;
  margin-left: 4px;
  display: inline-flex;
}

.semi-input-number-suffix-btns > .semi-input-number-button {
  height: 50%;
  width: 14px;
  user-select: none;
  color: var(--semi-color-text-2);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: inline-flex;
}

.semi-input-number-suffix-btns > .semi-input-number-button-up:not(.semi-input-number-button-up-not-allowed):hover, .semi-input-number-suffix-btns > .semi-input-number-button-down:not(.semi-input-number-button-down-not-allowed):hover {
  cursor: pointer;
  background-color: var(--semi-color-fill-0);
}

.semi-input-number-suffix-btns > .semi-input-number-button-up:not(.semi-input-number-button-up-not-allowed):active, .semi-input-number-suffix-btns > .semi-input-number-button-down:not(.semi-input-number-button-down-not-allowed):active {
  cursor: pointer;
  background-color: var(--semi-color-fill-1);
}

.semi-input-number-suffix-btns > .semi-input-number-button-up.semi-input-number-button-up-disabled, .semi-input-number-suffix-btns > .semi-input-number-button-down.semi-input-number-button-down-disabled {
  background-color: var(--semi-color-disabled-fill);
  color: var(--semi-color-disabled-text);
}

.semi-input-number-suffix-btns > .semi-input-number-button-up.semi-input-number-button-up-not-allowed, .semi-input-number-suffix-btns > .semi-input-number-button-down.semi-input-number-button-down-not-allowed {
  cursor: not-allowed;
}

.semi-input-number-suffix-btns-inner-hover {
  border-color: var(--semi-color-fill-2);
}

.semi-input-number-suffix-btns-inner {
  margin-left: 8px;
}

.semi-input-number .semi-input-clearbtn + .semi-input-suffix {
  margin-left: -4px;
}

.semi-input-number .semi-input-clearbtn + .semi-input-suffix .semi-input-number-suffix-btns-inner {
  margin-left: 0;
}

.semi-input-number-size-default .semi-input-number-suffix-btns {
  height: 32px;
}

.semi-input-number-size-default .semi-input-number-suffix-btns-inner {
  height: 30px;
}

.semi-input-number-size-large .semi-input-number-suffix-btns {
  height: 40px;
}

.semi-input-number-size-large .semi-input-number-suffix-btns-inner {
  height: 38px;
}

.semi-input-number-size-small .semi-input-number-suffix-btns {
  height: 24px;
}

.semi-input-number-size-small .semi-input-number-suffix-btns-inner {
  height: 22px;
}

.semi-rtl .semi-input-number, .semi-portal-rtl .semi-input-number {
  direction: rtl;
}

.semi-rtl .semi-input-number-suffix-btns, .semi-portal-rtl .semi-input-number-suffix-btns {
  margin-left: auto;
  margin-right: 4px;
}

.semi-rtl .semi-input-number-suffix-btns-inner, .semi-portal-rtl .semi-input-number-suffix-btns-inner {
  margin-left: auto;
  margin-right: 8px;
}

.semi-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix, .semi-portal-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix {
  margin-left: auto;
  margin-right: -4px;
}

.semi-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix .semi-input-number-suffix-btns-inner, .semi-portal-rtl .semi-input-number .semi-input-clearbtn + .semi-input-suffix .semi-input-number-suffix-btns-inner {
  margin-left: auto;
  margin-right: 0;
}

.semi-dropdown {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.semi-dropdown-wrapper {
  box-shadow: var(--semi-shadow-elevated);
  z-index: 1050;
  border-radius: var(--semi-border-radius-medium);
  background: var(--semi-color-bg-3);
  visibility: hidden;
  position: relative;
}

.semi-dropdown-wrapper-show {
  visibility: visible;
}

.semi-dropdown-trigger {
  display: inline-block;
}

.semi-dropdown-menu {
  margin: 0;
  padding: 4px 0;
  list-style: none;
}

.semi-dropdown-title {
  color: var(--semi-color-text-2);
  cursor: default;
  padding: 8px 16px 4px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.semi-dropdown-title-withTick {
  padding-left: 31px;
}

.semi-dropdown-item {
  color: var(--semi-color-text-0);
  max-width: 280px;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.semi-dropdown-item:not(.semi-dropdown-item-active):hover {
  background-color: var(--semi-color-fill-0);
  cursor: pointer;
}

.semi-dropdown-item:not(.semi-dropdown-item-active):active {
  background-color: var(--semi-color-fill-1);
}

.semi-dropdown-item:focus-visible {
  background-color: var(--semi-color-fill-0);
  outline: 0;
}

.semi-dropdown-item-icon {
  align-items: center;
  margin-right: 8px;
  display: inline-flex;
}

.semi-dropdown-item-danger {
  color: var(--semi-color-danger);
}

.semi-dropdown-item-secondary {
  color: var(--semi-color-secondary);
}

.semi-dropdown-item-warning {
  color: var(--semi-color-warning);
}

.semi-dropdown-item-tertiary {
  color: var(--semi-color-tertiary);
}

.semi-dropdown-item-primary {
  color: var(--semi-color-primary);
}

.semi-dropdown-item-withTick {
  padding-left: 12px;
}

.semi-dropdown-item > .semi-icon {
  flex-shrink: 0;
  margin-right: 9px;
  font-size: 12px;
}

.semi-dropdown-item-active {
  font-weight: 600;
}

.semi-dropdown-item.semi-dropdown-item-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}

.semi-dropdown-item.semi-dropdown-item-disabled:hover, .semi-dropdown-item.semi-dropdown-item-disabled:active {
  cursor: not-allowed;
  background-color: #0000;
}

.semi-dropdown-divider {
  height: 1px;
  width: 100%;
  min-width: 100%;
  clear: both;
  background: var(--semi-color-border);
  margin: 4px 0;
  display: block;
}

.semi-rtl .semi-dropdown-wrapper, .semi-portal-rtl .semi-dropdown-wrapper {
  direction: rtl;
}

.semi-rtl .semi-dropdown-title-withTick, .semi-portal-rtl .semi-dropdown-title-withTick {
  padding-left: 0;
  padding-right: 31px;
}

.semi-rtl .semi-dropdown-item-withTick, .semi-portal-rtl .semi-dropdown-item-withTick {
  padding-left: auto;
  padding-right: 12px;
}

.semi-rtl .semi-dropdown-item > .semi-icon, .semi-portal-rtl .semi-dropdown-item > .semi-icon {
  margin-left: 9px;
  margin-right: 0;
}

.semi-radio {
  box-sizing: border-box;
  min-height: 20px;
  min-width: 16px;
  cursor: pointer;
  vertical-align: bottom;
  text-align: left;
  flex-wrap: wrap;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  position: relative;
}

.semi-radio.semi-radio-vertical {
  display: block;
}

.semi-radio input[type="checkbox"], .semi-radio input[type="radio"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-radio:hover .semi-radio-inner-display {
  background: var(--semi-color-fill-0);
  border: solid 1px var(--semi-color-focus-border);
}

.semi-radio:hover.semi-radio-cardRadioGroup .semi-radio-inner-display {
  background: var(--semi-color-white);
}

.semi-radio:hover .semi-radio-inner-checked .semi-radio-inner-display {
  background: var(--semi-color-primary-hover);
  border-color: var(--semi-color-primary-hover);
}

.semi-radio:active .semi-radio-inner-display {
  background: var(--semi-color-fill-1);
}

.semi-radio:active.semi-radio-cardRadioGroup .semi-radio-inner-display {
  background: var(--semi-color-white);
}

.semi-radio:active .semi-radio-inner-checked .semi-radio-inner-display {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
}

.semi-radio-buttonRadioComponent {
  background: var(--semi-color-fill-0);
  border-radius: var(--semi-border-radius-small);
  padding: 4px;
}

.semi-radio-buttonRadioGroup {
  border-radius: var(--semi-border-radius-small);
  padding: 4px;
  line-height: 16px;
}

.semi-radio-buttonRadioGroup:not(:last-child) {
  padding-right: 0;
}

.semi-radio-buttonRadioGroup-small {
  padding: 2px 4px;
  line-height: 16px;
}

.semi-radio-buttonRadioGroup-large {
  padding: 4px;
  line-height: 20px;
}

.semi-radio-cardRadioGroup {
  border-radius: var(--semi-border-radius-small);
  background: none;
  border: 1px solid #0000;
  flex-wrap: nowrap;
  padding: 12px 16px;
}

.semi-radio-cardRadioGroup .semi-radio-inner {
  flex-shrink: 0;
  margin-right: 8px;
  position: relative;
}

.semi-radio-cardRadioGroup .semi-radio-inner-display {
  background: var(--semi-color-white);
}

.semi-radio-cardRadioGroup .semi-radio-addon {
  color: var(--semi-color-text-0);
  margin-left: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.semi-radio-cardRadioGroup .semi-radio-extra {
  color: var(--semi-color-text-2);
  padding-left: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.semi-radio-cardRadioGroup:active {
  background: var(--semi-color-fill-1);
}

.semi-radio-cardRadioGroup_checked {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary);
}

.semi-radio-cardRadioGroup_checked:hover {
  border: 1px solid var(--semi-color-primary-hover);
}

.semi-radio-cardRadioGroup_checked:hover .semi-radio-inner-checked .semi-radio-inner-display {
  border-color: var(--semi-color-primary-hover);
}

.semi-radio-cardRadioGroup_checked:active {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary-active);
}

.semi-radio-cardRadioGroup_checked:active .semi-radio-inner-checked .semi-radio-inner-display {
  border-color: var(--semi-color-primary-active);
}

.semi-radio-cardRadioGroup_checked:active .semi-radio-inner-checked:hover .semi-radio-inner-display {
  background: var(--semi-color-primary-active);
}

.semi-radio-cardRadioGroup_hover {
  background: var(--semi-color-fill-0);
}

.semi-radio-cardRadioGroup_disabled:active {
  background: none;
}

.semi-radio-cardRadioGroup_checked_disabled.semi-radio-cardRadioGroup {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary-disabled);
}

.semi-radio-cardRadioGroup_checked_disabled.semi-radio-cardRadioGroup .semi-radio-inner-checked .semi-radio-inner-display, .semi-radio-cardRadioGroup_checked_disabled.semi-radio-cardRadioGroup:hover .semi-radio-inner-checked .semi-radio-inner-display {
  border-color: var(--semi-color-primary-disabled);
}

.semi-radio.semi-radio-disabled:hover .semi-radio-inner-display, .semi-radio.semi-radio-disabled:active .semi-radio-inner-display {
  background: var(--semi-color-disabled-fill);
  border: solid 1px var(--semi-color-border);
}

.semi-radio.semi-radio-disabled:hover .semi-radio-inner-checked .semi-radio-inner-display, .semi-radio.semi-radio-disabled:active .semi-radio-inner-checked .semi-radio-inner-display {
  background: var(--semi-color-primary-disabled);
  border-color: var(--semi-color-primary-disabled);
}

.semi-radio-inner {
  width: 16px;
  height: 16px;
  vertical-align: sub;
  user-select: none;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}

.semi-radio-inner-display {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: solid 1px var(--semi-color-text-3);
  background: none;
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-radio-inner-display .semi-icon {
  width: 100%;
  height: 100%;
  font-size: 14px;
  position: absolute;
}

.semi-radio:hover .semi-radio-inner-display {
  background: var(--semi-color-fill-0);
}

.semi-radio:active .semi-radio-inner-display {
  background: var(--semi-color-fill-1);
}

.semi-radio-addon {
  user-select: none;
  color: var(--semi-color-text-0);
  align-items: center;
  margin-left: 16px;
  padding-left: 8px;
  display: inline-flex;
}

.semi-radio-addon-buttonRadio {
  text-align: center;
  border-radius: var(--semi-border-radius-small);
  color: var(--semi-color-text-1);
  margin-left: 0;
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 600;
}

.semi-radio-addon-buttonRadio-hover {
  background: var(--semi-color-fill-1);
  font-weight: 600;
}

.semi-radio-addon-buttonRadio-checked {
  background: var(--semi-color-bg-3);
  color: var(--semi-color-primary);
  font-weight: 600;
}

.semi-radio-addon-buttonRadio-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}

.semi-radio-addon-buttonRadio-small {
  padding: 2px 16px;
  font-size: 12px;
}

.semi-radio-addon-buttonRadio-large {
  padding: 6px 24px;
  font-size: 14px;
}

.semi-radio .semi-radio-inner-checked:hover .semi-radio-inner-display {
  background: var(--semi-color-primary-hover);
}

.semi-radio .semi-radio-inner-checked:active .semi-radio-inner-display {
  background: var(--semi-color-primary-active);
}

.semi-radio .semi-radio-inner-checked .semi-radio-inner-display {
  border: solid 1px var(--semi-color-primary);
  background: var(--semi-color-primary);
  color: rgba(var(--semi-white), 1);
  border-radius: 16px;
}

.semi-radio .semi-radio-inner-checked > .semi-radio-addon {
  color: var(--semi-color-text-0);
}

.semi-radio .semi-radio-inner-buttonRadio, .semi-radio .semi-radio-inner-pureCardRadio {
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.semi-radio-disabled, .semi-radio-disabled:hover, .semi-radio-disabled .semi-radio-inner {
  cursor: not-allowed;
}

.semi-radio-disabled .semi-radio-inner-display {
  opacity: .75;
  background: var(--semi-color-disabled-fill);
  border-color: var(--semi-color-border);
}

.semi-radio-disabled .semi-radio-inner-display:hover {
  background: none;
}

.semi-radio-disabled .semi-radio-inner-checked .semi-radio-inner-display, .semi-radio-disabled .semi-radio-inner-checked .semi-radio-inner-display:hover {
  background: var(--semi-color-primary-disabled);
  border-color: var(--semi-color-primary-disabled);
}

.semi-radio-disabled .semi-radio-addon, .semi-radio-disabled .semi-radio-extra {
  color: var(--semi-color-disabled-text);
}

.semi-radio-extra {
  color: var(--semi-color-text-2);
  box-sizing: border-box;
  flex: 1 0 100%;
  padding-left: 24px;
}

.semi-radio-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}

.semi-radio-focus-border {
  border: solid 1px var(--semi-color-focus-border);
}

.semi-radioGroup {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.semi-radioGroup-vertical .semi-radio:last-of-type {
  margin-bottom: 0;
}

.semi-radioGroup-vertical-default .semi-radio {
  margin-bottom: 12px;
  display: block;
}

.semi-radioGroup-vertical-card .semi-radio {
  margin-bottom: 16px;
  display: flex;
}

.semi-radioGroup-horizontal .semi-radio {
  margin-right: 16px;
}

.semi-radioGroup-horizontal .semi-radio:last-of-type {
  margin-right: 0;
}

.semi-radioGroup-horizontal-default {
  display: inline-block;
}

.semi-radioGroup-buttonRadio {
  background: var(--semi-color-fill-0);
  border-radius: var(--semi-border-radius-small);
  vertical-align: middle;
  display: inline-block;
}

.semi-rtl .semi-radio, .semi-portal-rtl .semi-radio {
  direction: rtl;
}

.semi-rtl .semi-radio input[type="checkbox"], .semi-rtl .semi-radio input[type="radio"], .semi-portal-rtl .semi-radio input[type="checkbox"], .semi-portal-rtl .semi-radio input[type="radio"] {
  left: auto;
  right: 0;
}

.semi-rtl .semi-radio-buttonRadioGroup:not(:last-child), .semi-portal-rtl .semi-radio-buttonRadioGroup:not(:last-child) {
  padding-left: 0;
}

.semi-rtl .semi-radio-inner, .semi-portal-rtl .semi-radio-inner, .semi-rtl .semi-radio-inner-display, .semi-portal-rtl .semi-radio-inner-display {
  left: auto;
  right: 0;
}

.semi-rtl .semi-radio-addon, .semi-portal-rtl .semi-radio-addon {
  margin-left: 0;
  margin-right: 16px;
  padding-left: 0;
  padding-right: 8px;
}

.semi-rtl .semi-radio-addon-buttonRadio, .semi-portal-rtl .semi-radio-addon-buttonRadio {
  margin-right: 0;
}

.semi-rtl .semi-radio-extra, .semi-portal-rtl .semi-radio-extra {
  padding-left: 0;
  padding-right: 24px;
}

.semi-rtl-isCardRadioGroup .semi-radio-inner, .semi-portal-rtl-isCardRadioGroup .semi-radio-inner {
  margin-left: 8px;
  margin-right: 0;
}

.semi-rtl-isCardRadioGroup .semi-radio-addon, .semi-portal-rtl-isCardRadioGroup .semi-radio-addon {
  margin-right: 0;
  padding-right: 0;
}

.semi-rtl-isCardRadioGroup .semi-radio-extra, .semi-portal-rtl-isCardRadioGroup .semi-radio-extra {
  padding-right: 0;
}

.semi-rtl .semi-radioGroup, .semi-portal-rtl .semi-radioGroup {
  direction: rtl;
}

.semi-rtl .semi-radioGroup-horizontal .semi-radio, .semi-portal-rtl .semi-radioGroup-horizontal .semi-radio {
  margin-left: 16px;
  margin-right: 0;
}

.semi-rtl .semi-radioGroup-horizontal .semi-radio:last-of-type, .semi-portal-rtl .semi-radioGroup-horizontal .semi-radio:last-of-type {
  margin-left: 0;
}

.semi-tabs {
  box-sizing: border-box;
  position: relative;
}

.semi-tabs-left {
  flex-direction: row;
  display: flex;
}

.semi-tabs-bar {
  white-space: nowrap;
  outline: none;
  position: relative;
}

.semi-tabs-bar-left {
  flex-direction: column;
  display: flex;
}

.semi-tabs-bar-extra {
  padding: 0 5px;
}

.semi-tabs-bar .semi-tabs-tab {
  cursor: pointer;
  box-sizing: border-box;
  float: left;
  color: var(--semi-color-text-2);
  user-select: none;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  position: relative;
}

.semi-tabs-bar .semi-tabs-tab .semi-icon {
  color: var(--semi-color-text-2);
  margin-right: 8px;
  position: relative;
  top: 3px;
}

.semi-tabs-bar .semi-tabs-tab .semi-icon-close {
  color: var(--semi-color-text-2);
  cursor: pointer;
  margin-left: 10px;
  margin-right: 0;
  font-size: 14px;
}

.semi-tabs-bar .semi-tabs-tab:hover, .semi-tabs-bar .semi-tabs-tab:hover .semi-icon, .semi-tabs-bar .semi-tabs-tab:active, .semi-tabs-bar .semi-tabs-tab:active .semi-icon {
  color: var(--semi-color-text-0);
}

.semi-tabs-bar .semi-tabs-tab-active, .semi-tabs-bar .semi-tabs-tab-active:hover {
  cursor: default;
  color: var(--semi-color-text-0);
  font-weight: 600;
}

.semi-tabs-bar .semi-tabs-tab-active .semi-icon, .semi-tabs-bar .semi-tabs-tab-active:hover .semi-icon {
  color: var(--semi-color-primary);
}

.semi-tabs-bar .semi-tabs-tab-active .semi-icon-close, .semi-tabs-bar .semi-tabs-tab-active:hover .semi-icon-close {
  color: var(--semi-color-text-2);
}

.semi-tabs-bar .semi-tabs-tab-active .semi-icon-close:hover {
  color: var(--semi-color-text-1);
}

.semi-tabs-bar .semi-tabs-tab-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}

.semi-tabs-bar .semi-tabs-tab-disabled:hover {
  color: var(--semi-color-disabled-text);
  border-bottom: none;
}

.semi-tabs-bar-collapse, .semi-tabs-bar-collapse .semi-tabs-bar-overflow-list {
  align-items: center;
  display: flex;
}

.semi-tabs-bar-collapse .semi-overflow-list {
  flex: 1;
}

.semi-tabs-bar-collapse .semi-overflow-list .semi-overflow-list-scroll-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.semi-tabs-bar-collapse .semi-overflow-list .semi-overflow-list-scroll-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.semi-tabs-bar-collapse .semi-overflow-list .semi-overflow-list-scroll-wrapper:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}

.semi-tabs-bar-collapse .semi-tabs-bar-arrow-start {
  margin-right: 4px;
}

.semi-tabs-bar-collapse .semi-tabs-bar-arrow-end {
  margin-left: 4px;
}

.semi-tabs-bar-dropdown {
  max-height: 300px;
  overflow-y: auto;
}

.semi-tabs-bar:after {
  content: "";
  height: 0;
  clear: both;
  display: block;
}

.semi-tabs-bar-line.semi-tabs-bar-top {
  border-bottom: 1px solid var(--semi-color-border);
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab {
  border-bottom: 2px solid #0000;
  padding: 16px 4px 14px;
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:nth-of-type(1) {
  padding-left: 0;
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:hover {
  border-bottom: 2px solid var(--semi-color-fill-0);
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -1px;
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:active {
  border-bottom: 2px solid var(--semi-color-fill-1);
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: 24px;
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-small {
  padding: 8px 4px 6px;
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-medium {
  padding: 12px 4px 10px;
}

.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-active, .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-active:hover {
  border-bottom: 2px solid var(--semi-color-primary);
}

.semi-tabs-bar-line.semi-tabs-bar-left {
  border-right: 1px solid var(--semi-color-border);
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab {
  border-left: 2px solid #0000;
  padding: 12px;
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-left: 2px solid var(--semi-color-fill-0);
  background-color: var(--semi-color-fill-0);
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:active {
  border-left: 2px solid var(--semi-color-fill-1);
  background-color: var(--semi-color-fill-1);
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-small {
  padding: 6px;
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-medium {
  padding: 10px;
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active {
  background-color: var(--semi-color-primary-light-default);
}

.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active, .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active:hover {
  border-left: 2px solid var(--semi-color-primary);
  background-color: var(--semi-color-primary-light-default);
}

.semi-tabs-bar-line .semi-tabs-bar-extra {
  height: 50px;
  line-height: 50px;
}

.semi-tabs-bar-line .semi-tabs-bar-line-extra-small {
  height: 36px;
  line-height: 36px;
}

.semi-tabs-bar-card.semi-tabs-bar-top:before {
  border-bottom: 1px solid var(--semi-color-border);
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab {
  border-radius: var(--semi-border-radius-small) var(--semi-border-radius-small) 0 0;
  border: 1px solid #0000;
  border-bottom: none;
}

.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:hover {
  border-bottom: none;
}

.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: 8px;
}

.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab-active, .semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab-active:hover {
  border: 1px solid var(--semi-color-border);
  border-bottom: 1px solid var(--semi-color-bg-1);
  background: none;
  padding: 8px 12px 7px;
}

.semi-tabs-bar-card.semi-tabs-bar-left {
  border-right: 1px solid var(--semi-color-border);
}

.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
  border: 1px solid #0000;
  border-right: none;
}

.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-right: none;
}

.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:not(:last-of-type) {
  margin-bottom: 8px;
}

.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:after {
  content: " ";
  width: 1px;
  background: var(--semi-color-bg-1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
}

.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active, .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:hover {
  border: 1px solid var(--semi-color-border);
  background: none;
  border-right: none;
  padding: 8px 12px;
}

.semi-tabs-bar-card .semi-tabs-tab {
  padding: 8px 12px;
}

.semi-tabs-bar-card .semi-tabs-tab:hover {
  background: var(--semi-color-fill-0);
}

.semi-tabs-bar-card .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}

.semi-tabs-bar-card .semi-tabs-tab:active {
  background: var(--semi-color-fill-1);
}

.semi-tabs-bar-button {
  border: none;
}

.semi-tabs-bar-button.semi-tabs-bar-left .semi-tabs-tab:not(:last-of-type) {
  margin-bottom: 8px;
}

.semi-tabs-bar-button.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: 8px;
}

.semi-tabs-bar-button .semi-tabs-tab {
  border-radius: var(--semi-border-radius-small);
  color: var(--semi-color-text-2);
  border: none;
  padding: 8px 12px;
}

.semi-tabs-bar-button .semi-tabs-tab:hover {
  background-color: var(--semi-color-fill-0);
  border: none;
}

.semi-tabs-bar-button .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}

.semi-tabs-bar-button .semi-tabs-tab:active {
  background-color: var(--semi-color-fill-1);
}

.semi-tabs-bar-button .semi-tabs-tab-active, .semi-tabs-bar-button .semi-tabs-tab-active:hover {
  color: var(--semi-color-primary);
  background-color: var(--semi-color-primary-light-default);
  border: none;
}

.semi-tabs-content {
  width: 100%;
  padding: 5px 0;
}

.semi-tabs-content-left {
  height: 100%;
  padding: 0 5px;
}

.semi-tabs-pane {
  width: 100%;
  color: var(--semi-color-text-0);
  overflow: hidden;
}

.semi-tabs-pane:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}

.semi-tabs-pane-inactive, .semi-tabs-content-no-animated .semi-tabs-pane-inactive {
  display: none;
}

.semi-rtl .semi-tabs, .semi-portal-rtl .semi-tabs {
  direction: rtl;
}

.semi-rtl .semi-tabs-bar .semi-tabs-tab, .semi-portal-rtl .semi-tabs-bar .semi-tabs-tab {
  float: right;
}

.semi-rtl .semi-tabs-bar .semi-tabs-tab .semi-icon, .semi-portal-rtl .semi-tabs-bar .semi-tabs-tab .semi-icon {
  margin-left: 8px;
  margin-right: 0;
}

.semi-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-start, .semi-portal-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-start {
  margin-left: 4px;
  margin-right: 0;
}

.semi-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-end, .semi-portal-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-end {
  margin-left: 0;
  margin-right: 4px;
}

.semi-rtl .semi-tabs-bar-collapse .semi-icon-chevron_right, .semi-rtl .semi-tabs-bar-collapse .semi-icon-chevron_left, .semi-portal-rtl .semi-tabs-bar-collapse .semi-icon-chevron_right, .semi-portal-rtl .semi-tabs-bar-collapse .semi-icon-chevron_left {
  transform: scaleX(-1);
}

.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type), .semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-left: 24px;
  margin-right: 0;
}

.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left, .semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab, .semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab {
  border-left: 0;
  border-right: 2px solid #0000;
}

.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:hover, .semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-left: 0;
  border-right: 2px solid var(--semi-color-fill-0);
}

.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:active, .semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:active {
  border-left: 0;
  border-right: 2px solid var(--semi-color-fill-1);
}

.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active, .semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active:hover, .semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active, .semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active:hover {
  border-left: 0;
  border-right: 2px solid var(--semi-color-primary);
}

.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type), .semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-left: 0;
  margin-right: 8px;
}

.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left, .semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}

.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab, .semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab {
  border: 1px solid #0000;
  border-left: none;
}

.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:hover, .semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-left: none;
}

.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:after, .semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:after {
  left: -1px;
  right: auto;
}

.semi-rtl .semi-tabs-bar-button.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type), .semi-portal-rtl .semi-tabs-bar-button.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-left: 8px;
  margin-right: auto;
}

.semi-overflow-list {
  min-width: 0;
  flex-wrap: nowrap;
  display: flex;
}

.semi-overflow-list-spacer {
  width: 1px;
  flex-shrink: 1;
}

.semi-overflow-list-scroll-wrapper {
  flex-wrap: nowrap;
  flex: 1;
  display: flex;
  overflow-x: scroll;
}

.semi-rtl .semi-overflow-list, .semi-portal-rtl .semi-overflow-list {
  direction: rtl;
}

.semi-button-split {
  display: inline-block;
}

.semi-button-split .semi-button {
  border-radius: 0;
  margin-right: 1px;
}

.semi-button-split :first-child {
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}

.semi-button-split :last-child {
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
  margin-right: unset;
}

.semi-button-split:hover .semi-button-borderless:active {
  background-color: var(--semi-color-fill-1);
}

.semi-button {
  box-shadow: none;
  height: 32px;
  cursor: pointer;
  user-select: none;
  border-radius: var(--semi-border-radius-small);
  vertical-align: middle;
  white-space: nowrap;
  border: 0 solid #0000;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 6px 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: inline-flex;
}

.semi-button.semi-button-primary:focus-visible, .semi-button.semi-button-secondary:focus-visible, .semi-button.semi-button-tertiary:focus-visible, .semi-button.semi-button-warning:focus-visible, .semi-button.semi-button-danger:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}

.semi-button-danger {
  background-color: var(--semi-color-danger);
  color: rgba(var(--semi-white), 1);
}

.semi-button-danger:hover {
  background-color: var(--semi-color-danger-hover);
}

.semi-button-danger:active {
  background-color: var(--semi-color-danger-active);
}

.semi-button-danger.semi-button-light, .semi-button-danger.semi-button-borderless {
  color: var(--semi-color-danger);
}

.semi-button-danger:not(.semi-button-borderless):not(.semi-button-light):focus-visible {
  outline: 2px solid var(--semi-color-danger-light-active);
}

.semi-button-warning {
  background-color: var(--semi-color-warning);
  color: rgba(var(--semi-white), 1);
}

.semi-button-warning:hover {
  background-color: var(--semi-color-warning-hover);
}

.semi-button-warning:active {
  background-color: var(--semi-color-warning-active);
}

.semi-button-warning.semi-button-light, .semi-button-warning.semi-button-borderless {
  color: var(--semi-color-warning);
}

.semi-button-warning:not(.semi-button-borderless):not(.semi-button-light):focus-visible {
  outline: 2px solid var(--semi-color-warning-light-active);
}

.semi-button-tertiary {
  background-color: var(--semi-color-tertiary);
  color: rgba(var(--semi-white), 1);
}

.semi-button-tertiary:hover {
  background-color: var(--semi-color-tertiary-hover);
}

.semi-button-tertiary:active {
  background-color: var(--semi-color-tertiary-active);
}

.semi-button-tertiary.semi-button-light, .semi-button-tertiary.semi-button-borderless {
  color: var(--semi-color-text-1);
}

.semi-button-primary {
  background-color: var(--semi-color-primary);
  color: rgba(var(--semi-white), 1);
}

.semi-button-primary:not(.semi-button-borderless):not(.semi-button-light):hover {
  background-color: var(--semi-color-primary-hover);
}

.semi-button-primary:not(.semi-button-borderless):not(.semi-button-light):active {
  background-color: var(--semi-color-primary-active);
}

.semi-button-primary.semi-button-light, .semi-button-primary.semi-button-borderless {
  color: var(--semi-color-primary);
}

.semi-button-secondary {
  background-color: var(--semi-color-secondary);
  outline-color: var(--semi-color-secondary);
  color: rgba(var(--semi-white), 1);
}

.semi-button-secondary:hover {
  background-color: var(--semi-color-secondary-hover);
}

.semi-button-secondary:active {
  background-color: var(--semi-color-secondary-active);
}

.semi-button-secondary.semi-button-light, .semi-button-secondary.semi-button-borderless {
  color: var(--semi-color-secondary);
}

.semi-button-disabled {
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-bg);
  cursor: not-allowed;
}

.semi-button-disabled:not(.semi-button-borderless):not(.semi-button-light):hover {
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-bg);
}

.semi-button-disabled.semi-button-light, .semi-button-disabled.semi-button-borderless {
  color: var(--semi-color-disabled-text);
}

.semi-button-borderless {
  background-color: #0000;
  border: 0 solid #0000;
}

.semi-button-borderless:not(.semi-button-disabled):hover {
  background-color: var(--semi-color-fill-0);
  border: 0 solid #0000;
}

.semi-button-borderless:not(.semi-button-disabled):active {
  background-color: var(--semi-color-fill-1);
  border: 0 solid #0000;
}

.semi-button-light {
  background-color: var(--semi-color-fill-0);
  border: 0 solid #0000;
}

.semi-button-light:not(.semi-button-disabled):hover {
  background-color: var(--semi-color-fill-1);
  border: 0 solid #0000;
}

.semi-button-light:not(.semi-button-disabled):active {
  background-color: var(--semi-color-fill-2);
  border: 0 solid #0000;
}

.semi-button-size-small {
  height: 24px;
  padding: 2px 12px;
}

.semi-button-size-large {
  height: 40px;
  padding: 10px 16px;
}

.semi-button-block {
  width: 100%;
}

.semi-button-group > .semi-button {
  border-radius: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.semi-button-group > .semi-button .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-button-group > .semi-button-size-large .semi-button-content {
  padding-left: 16px;
  padding-right: 16px;
}

.semi-button-group > .semi-button-size-small .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-button-group > .semi-button.semi-button-with-icon-only {
  padding-left: 0;
  padding-right: 0;
}

.semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content {
  padding-left: 8px;
  padding-right: 8px;
}

.semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content {
  padding-left: 4px;
  padding-right: 4px;
}

.semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-button-group > .semi-button:first-child {
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}

.semi-button-group > .semi-button:not(:last-child) .semi-button-content {
  border-right: 1px var(--semi-color-border) solid;
}

.semi-button-group > .semi-button:last-child {
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
}

.semi-rtl .semi-button, .semi-portal-rtl .semi-button {
  direction: rtl;
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-size-small, .semi-portal-rtl .semi-button-size-small {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-size-large, .semi-portal-rtl .semi-button-size-large {
  padding-left: 16px;
  padding-right: 16px;
}

.semi-rtl .semi-button-group, .semi-portal-rtl .semi-button-group {
  direction: rtl;
}

.semi-rtl .semi-button-group > .semi-button, .semi-portal-rtl .semi-button-group > .semi-button {
  padding-left: 0;
  padding-right: 0;
}

.semi-rtl .semi-button-group > .semi-button .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-group > .semi-button-size-large .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button-size-large .semi-button-content {
  padding-left: 16px;
  padding-right: 16px;
}

.semi-rtl .semi-button-group > .semi-button-size-small .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button-size-small .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only {
  padding-left: 0;
  padding-right: 0;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content {
  padding-left: 8px;
  padding-right: 8px;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content {
  padding-left: 4px;
  padding-right: 4px;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-group > .semi-button:first-child, .semi-portal-rtl .semi-button-group > .semi-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
}

.semi-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content {
  border-left: 1px var(--semi-color-border) solid;
  border-right: 0;
}

.semi-rtl .semi-button-group > .semi-button:last-child, .semi-portal-rtl .semi-button-group > .semi-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}

.semi-rtl .semi-button.semi-button-with-icon-only, .semi-portal-rtl .semi-button.semi-button-with-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}

.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small, .semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small {
  padding-left: 4px;
  padding-right: 4px;
}

.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large, .semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-content-left, .semi-portal-rtl .semi-button-content-left {
  margin-left: 8px;
  margin-right: 0;
}

.semi-rtl .semi-button-content-right, .semi-portal-rtl .semi-button-content-right {
  margin-left: 0;
  margin-right: 8px;
}

@keyframes semi-animation-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.semi-button.semi-button-with-icon {
  align-items: center;
  display: inline-flex;
}

.semi-button.semi-button-with-icon .semi-button-content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-button.semi-button-loading {
  pointer-events: none;
  cursor: not-allowed;
}

.semi-button.semi-button-loading .semi-button-content > svg {
  width: 16px;
  height: 16px;
  animation: semi-animation-rotate .6s linear infinite;
}

.semi-button.semi-button-with-icon-only {
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.semi-button.semi-button-with-icon-only.semi-button-size-small {
  padding: 4px;
}

.semi-button.semi-button-with-icon-only.semi-button-size-large {
  padding: 12px;
}

.semi-button-content-left {
  margin-right: 8px;
}

.semi-button-content-right {
  margin-left: 8px;
}

.semi-rtl .semi-button, .semi-portal-rtl .semi-button {
  direction: rtl;
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-size-small, .semi-portal-rtl .semi-button-size-small {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-size-large, .semi-portal-rtl .semi-button-size-large {
  padding-left: 16px;
  padding-right: 16px;
}

.semi-rtl .semi-button-group, .semi-portal-rtl .semi-button-group {
  direction: rtl;
}

.semi-rtl .semi-button-group > .semi-button, .semi-portal-rtl .semi-button-group > .semi-button {
  padding-left: 0;
  padding-right: 0;
}

.semi-rtl .semi-button-group > .semi-button .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-group > .semi-button-size-large .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button-size-large .semi-button-content {
  padding-left: 16px;
  padding-right: 16px;
}

.semi-rtl .semi-button-group > .semi-button-size-small .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button-size-small .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only {
  padding-left: 0;
  padding-right: 0;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content {
  padding-left: 8px;
  padding-right: 8px;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content {
  padding-left: 4px;
  padding-right: 4px;
}

.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-group > .semi-button:first-child, .semi-portal-rtl .semi-button-group > .semi-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
}

.semi-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content, .semi-portal-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content {
  border-left: 1px var(--semi-color-border) solid;
  border-right: 0;
}

.semi-rtl .semi-button-group > .semi-button:last-child, .semi-portal-rtl .semi-button-group > .semi-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}

.semi-rtl .semi-button.semi-button-with-icon-only, .semi-portal-rtl .semi-button.semi-button-with-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}

.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small, .semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small {
  padding-left: 4px;
  padding-right: 4px;
}

.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large, .semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large {
  padding-left: 12px;
  padding-right: 12px;
}

.semi-rtl .semi-button-content-left, .semi-portal-rtl .semi-button-content-left {
  margin-left: 8px;
  margin-right: 0;
}

.semi-rtl .semi-button-content-right, .semi-portal-rtl .semi-button-content-right {
  margin-left: 0;
  margin-right: 8px;
}

.accordion_942ede .semi-collapse-item {
  border: none;
  margin: 0 0 1rem;
}

.accordion_942ede .semi-collapse-header {
  min-height: 4rem;
  background-color: #fff;
  border: none;
  margin: 0;
  padding: .8rem 1rem;
  font-family: HKGroteskBold;
  font-size: 1.2rem;
  line-height: 1.3;
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

.accordion_942ede .semi-collapse-header[aria-expanded="false"] {
  border-radius: 12px;
}

.accordion_942ede .semi-collapse-header[aria-expanded="true"] {
  border-radius: 12px 12px 0 0;
}

.accordion_942ede .semi-collapse-content {
  background: #fff;
  border-radius: 0 0 12px 12px;
  padding: 1rem;
  font-family: HKGroteskRegular;
  font-size: 12px;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .accordion_942ede .semi-collapse-header {
    padding: .8rem 2rem;
  }

  .accordion_942ede .semi-collapse-content {
    padding: 0 2rem 2rem;
    font-size: 20px;
  }
}

.D-Nkmq_press-wrap {
  padding: 1rem 0;
}

.D-Nkmq_my-breadcrumb {
  color: #16042f;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
}

.D-Nkmq_my-breadcrumb a {
  color: #16042f;
}

.D-Nkmq_my-breadcrumb span {
  background: url("arrow_r@2x.573327d6.png") 0 / auto 80% no-repeat;
  margin-left: .3rem;
  padding-left: 18px;
}

.D-Nkmq_press-content {
  margin-bottom: 80px;
  padding-top: 50px;
}

.D-Nkmq_press-content h1 {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #00cfbb;
  background-image: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
  margin-bottom: 16px;
  font-family: HKGroteskBold;
  font-size: 40px;
  font-weight: bold;
  display: inline-block;
}

.D-Nkmq_press-content h2 {
  color: #585063;
  margin-bottom: 40px;
  font-size: 20px;
}

.D-Nkmq_press-content .D-Nkmq_img-logo {
  width: 100%;
  text-align: center;
  height: 315px;
  background-color: #f9f8fc;
  border-radius: .5rem;
  margin-bottom: 10px;
  display: block;
}

.D-Nkmq_press-content .D-Nkmq_img-logo .D-Nkmq_img-w {
  height: 250px;
  width: 100%;
  display: inline-flex;
}

.D-Nkmq_press-content .D-Nkmq_img-logo .D-Nkmq_img-w img {
  max-width: 80%;
  margin: auto;
}

.D-Nkmq_press-content .D-Nkmq_img-logo div:last-child {
  text-align-last: center;
  color: #16042f;
  width: 100%;
  height: 65px;
  text-align: center;
  border-radius: 0 0 6px 6px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  transition: all .3s;
  display: flex;
}

.D-Nkmq_press-content .D-Nkmq_img-logo div:last-child .D-Nkmq_icon-download {
  width: 24px;
  height: 24px;
  color: #16042f;
  margin-left: 14px;
  transition: all .3s;
}

.D-Nkmq_press-content .D-Nkmq_img-logo:hover {
  box-shadow: 8px 8px 24px #e2dbf0, -8px -8px 24px #fff;
}

.D-Nkmq_press-content .D-Nkmq_img-logo:hover div:last-child {
  color: #fff;
  background: linear-gradient(270deg, #541eac, #9426cf, #26cfb8);
}

.D-Nkmq_press-content .D-Nkmq_img-logo:hover div:last-child .D-Nkmq_icon-download {
  color: #fff;
}

.D-Nkmq_row {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.D-Nkmq_col-md-4 {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .D-Nkmq_col-md-4 {
    width: 33.33%;
    padding: 0 1rem;
  }

  .D-Nkmq_row {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }
}

/*# sourceMappingURL=index.ea57d11a.css.map */
