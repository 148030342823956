/* shadow */
/* sizing */
/* spacing */
.semi-collapse-item {
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 8px;
  padding: 8px;
  border-radius: var(--semi-border-radius-small);
  outline: none;
  cursor: pointer;
  color: var(--semi-color-text-0);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-collapse-header-right {
  display: flex;
  align-items: center;
}
.semi-collapse-header-right span {
  display: flex;
  padding-right: 8px;
}
.semi-collapse-header-right span:last-child {
  padding-right: 0;
}
.semi-collapse-header-icon {
  width: 16px;
  height: 16px;
  color: var(--semi-color-text-2);
}
.semi-collapse-header-iconLeft {
  justify-content: flex-start;
}
.semi-collapse-header-iconLeft .semi-collapse-header-icon {
  margin-right: 8px;
}
.semi-collapse-header-iconDisabled {
  color: var(--semi-color-disabled-text);
}
.semi-collapse-header:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-collapse-header:active {
  background-color: var(--semi-color-fill-1);
}
.semi-collapse-content {
  padding: 4px 16px 8px 16px;
  color: var(--semi-color-text-1);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-collapse-content p {
  margin: 0;
}

.semi-rtl .semi-collapse,
.semi-portal-rtl .semi-collapse {
  direction: rtl;
}
.semi-rtl .semi-collapse-header-right span,
.semi-portal-rtl .semi-collapse-header-right span {
  display: flex;
  padding-right: 0;
  padding-left: 8px;
}
.semi-rtl .semi-collapse-header-right span:last-child,
.semi-portal-rtl .semi-collapse-header-right span:last-child {
  padding-right: 0;
  padding-left: 0;
}