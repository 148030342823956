/* shadow */
/* sizing */
/* spacing */
@keyframes semi-animation-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.semi-button.semi-button-with-icon {
  display: inline-flex;
  align-items: center;
}
.semi-button.semi-button-with-icon .semi-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.semi-button.semi-button-loading {
  pointer-events: none;
  cursor: not-allowed;
}
.semi-button.semi-button-loading .semi-button-content > svg {
  width: 16px;
  height: 16px;
  animation: 0.6s linear infinite semi-animation-rotate;
}
.semi-button.semi-button-with-icon-only {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  align-items: center;
}
.semi-button.semi-button-with-icon-only.semi-button-size-small {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.semi-button.semi-button-with-icon-only.semi-button-size-large {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.semi-button-content-left {
  margin-right: 8px;
}
.semi-button-content-right {
  margin-left: 8px;
}

.semi-rtl .semi-button,
.semi-portal-rtl .semi-button {
  direction: rtl;
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-size-small,
.semi-portal-rtl .semi-button-size-small {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-size-large,
.semi-portal-rtl .semi-button-size-large {
  padding-left: 16px;
  padding-right: 16px;
}
.semi-rtl .semi-button-group,
.semi-portal-rtl .semi-button-group {
  direction: rtl;
}
.semi-rtl .semi-button-group > .semi-button,
.semi-portal-rtl .semi-button-group > .semi-button {
  padding-left: 0;
  padding-right: 0;
}
.semi-rtl .semi-button-group > .semi-button .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-group > .semi-button-size-large .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button-size-large .semi-button-content {
  padding-left: 16px;
  padding-right: 16px;
}
.semi-rtl .semi-button-group > .semi-button-size-small .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button-size-small .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only {
  padding-left: 0;
  padding-right: 0;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content {
  padding-left: 8px;
  padding-right: 8px;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content {
  padding-left: 4px;
  padding-right: 4px;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-group > .semi-button:first-child,
.semi-portal-rtl .semi-button-group > .semi-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
}
.semi-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content {
  border-left: 1px var(--semi-color-border) solid;
  border-right: 0;
}
.semi-rtl .semi-button-group > .semi-button:last-child,
.semi-portal-rtl .semi-button-group > .semi-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}
.semi-rtl .semi-button.semi-button-with-icon-only,
.semi-portal-rtl .semi-button.semi-button-with-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}
.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small,
.semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small {
  padding-left: 4px;
  padding-right: 4px;
}
.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large,
.semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-content-left,
.semi-portal-rtl .semi-button-content-left {
  margin-left: 8px;
  margin-right: 0;
}
.semi-rtl .semi-button-content-right,
.semi-portal-rtl .semi-button-content-right {
  margin-right: 8px;
  margin-left: 0;
}