html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
a, a:hover{
  text-decoration: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}



@font-face {
  font-family: HKGroteskBold;
  src: url("../common/fonts/HKGrotesk-Bold.otf");
}
@font-face {
  font-family: HKGroteskRegular;
  src: url("../common/fonts/HKGrotesk-Regular.otf");
}
html,body,#root{
  height: 100%;
}
body {
  margin: 0;
  font-family:HKGroteskRegular, -apple-system, 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto !important;
  background: #F1EEF7;
  
}
a{
  color: #16042F;
}

.text-center{
  text-align: center;
}
.container{
  margin:0 auto;
  max-width: 1260px;
  padding: 0 1rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #05193d;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 0
}

::-webkit-scrollbar-thumb {
  background-color     : #05183C;
  -webkit-border-radius: 4px;
  -moz-border-radius   : 4px;
  border-radius        : 0
}

.pageWrap{
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.box-title{
  font-family: HKGroteskBold;
  text-align: center;
  color: #16042F;
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1rem;
}


.ic-btn{
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  border-radius: 40px;
  font-weight: bold;
  color: #16042F;
  font-size: 20px;
  width: 300px;
  height: 60px;
  padding: 1px;
  box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
  transition: all .3s;
}
.ic-btn:active{
  background-image: linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
}
.ic-btn-inner{
  background-color: #FCFBFE;
  border-radius: 40px;
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mask{
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  left: 0;
  top: 0;
  transition:all .3s;
  display: none;
}
.mask.show{
  display: block;
}

.nav-item-link{
  margin-right: 2rem;
  transition: all .3s;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
}

@media screen and (min-width:769px) {
  .box-title{
    text-align: left;
    font-size: 3.75rem;
  }
  .ic-btn{
    height: 80px;
  }
  .ic-btn:hover{
    background-image: linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
  }
}