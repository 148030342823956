/* shadow */
/* sizing */
/* spacing */
.semi-checkbox {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
}
.semi-checkbox input[type=checkbox] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
}
.semi-checkbox-addon {
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 8px;
  color: var(--semi-color-text-0);
  line-height: 20px;
  user-select: none;
}
.semi-checkbox:hover .semi-checkbox-inner-display {
  background: var(--semi-color-fill-0);
  box-shadow: inset 0 0 0 1px var(--semi-color-focus-border);
}
.semi-checkbox:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-hover);
  box-shadow: none;
}
.semi-checkbox:active .semi-checkbox-inner-display {
  background: var(--semi-color-fill-1);
}
.semi-checkbox:active .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-active);
  box-shadow: none;
}
.semi-checkbox.semi-checkbox-disabled:hover .semi-checkbox-inner-display, .semi-checkbox.semi-checkbox-disabled:active .semi-checkbox-inner-display {
  background: var(--semi-color-disabled-fill);
  box-shadow: inset 0 0 0 1px var(--semi-color-border);
}
.semi-checkbox.semi-checkbox-disabled:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display, .semi-checkbox.semi-checkbox-disabled:active .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-disabled);
  box-shadow: none;
}
.semi-checkbox-inner {
  position: relative;
  display: flex;
  align-items: center;
  width: 16px;
  height: 20px;
  user-select: none;
  cursor: pointer;
}
.semi-checkbox-inner-display {
  box-sizing: border-box;
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0;
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--semi-color-text-3);
  border-radius: var(--semi-border-radius-extra-small);
}
.semi-checkbox-inner-display .semi-icon {
  font-size: 16px;
}
.semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary);
  color: var(--semi-color-white);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary);
  border-radius: var(--semi-border-radius-extra-small);
}
.semi-checkbox-inner-checked > .semi-checkbox-addon {
  color: var(--semi-color-text-0);
}
.semi-checkbox:hover .semi-checkbox-inner-display {
  background: var(--semi-color-fill-0);
}
.semi-checkbox:hover.semi-checkbox-indeterminate .semi-checkbox-inner-display {
  background: var(--semi-color-primary-hover);
  box-shadow: none;
  color: var(--semi-color-white);
}
.semi-checkbox:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-hover);
  border-color: var(--semi-color-primary-hover);
  color: var(--semi-color-white);
}
.semi-checkbox:hover.semi-checkbox-cardType.semi-checkbox-unChecked.semi-checkbox-cardType_unDisabled .semi-checkbox-inner-display {
  background: var(--semi-color-white);
}
.semi-checkbox:active .semi-checkbox-inner-display {
  background: var(--semi-color-fill-1);
}
.semi-checkbox:active.semi-checkbox-indeterminate .semi-checkbox-inner-display {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
  color: var(--semi-color-white);
  box-shadow: none;
}
.semi-checkbox:active .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
  color: var(--semi-color-white);
}
.semi-checkbox:active.semi-checkbox-cardType.semi-checkbox-unChecked.semi-checkbox-cardType_unDisabled .semi-checkbox-inner-display {
  background: var(--semi-color-white);
}
.semi-checkbox-cardType {
  flex-wrap: nowrap;
  align-items: flex-start;
  border-radius: 3px;
  padding: 12px 16px;
  background: transparent;
  border: 1px solid transparent;
}
.semi-checkbox-cardType .semi-checkbox-inner {
  position: relative;
  margin-right: 8px;
  flex-shrink: 0;
}
.semi-checkbox-cardType .semi-checkbox-inner-display {
  background: var(--semi-color-white);
}
.semi-checkbox-cardType .semi-checkbox-inner-pureCardType {
  opacity: 0;
  width: 0;
  margin-right: 0;
}
.semi-checkbox-cardType .semi-checkbox-addon {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--semi-color-text-0);
  margin-left: 0;
  padding-left: 0;
}
.semi-checkbox-cardType .semi-checkbox-extra {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--semi-color-text-2);
  padding-left: 0;
}
.semi-checkbox-cardType .semi-checkbox-extra.semi-checkbox-cardType_extra_noChildren {
  margin-top: 0;
}
.semi-checkbox-cardType:hover {
  background: var(--semi-color-fill-0);
}
.semi-checkbox-cardType:active {
  background: var(--semi-color-fill-1);
}
.semi-checkbox-cardType_checked {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary);
}
.semi-checkbox-cardType_checked:hover {
  background: var(--semi-color-primary-light-default);
  border-color: var(--semi-color-primary-hover);
}
.semi-checkbox-cardType_checked:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  box-shadow: none;
}
.semi-checkbox-cardType_checked:active {
  background: var(--semi-color-primary-light-default);
  border-color: var(--semi-color-primary-active);
}
.semi-checkbox-cardType_disabled:active {
  background: transparent;
}
.semi-checkbox-cardType_disabled:hover {
  background: transparent;
}
.semi-checkbox-cardType_checked_disabled.semi-checkbox-cardType {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary-disabled);
}
.semi-checkbox-cardType_checked_disabled.semi-checkbox-cardType:hover .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  box-shadow: none;
}
.semi-checkbox-indeterminate .semi-checkbox-inner-display, .semi-checkbox-checked .semi-checkbox-inner-display {
  background: var(--semi-color-primary);
  color: var(--semi-color-white);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary);
  border-radius: var(--semi-border-radius-extra-small);
}
.semi-checkbox-indeterminate .semi-checkbox-inner-display:hover, .semi-checkbox-checked .semi-checkbox-inner-display:hover {
  background: var(--semi-color-primary-hover);
  border-color: var(--semi-color-primary-hover);
  color: var(--semi-color-white);
}
.semi-checkbox-indeterminate .semi-checkbox-inner-display:active, .semi-checkbox-checked .semi-checkbox-inner-display:active {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
  color: var(--semi-color-white);
}
.semi-checkbox-indeterminate .semi-checkbox-inner-addon, .semi-checkbox-checked .semi-checkbox-inner-addon {
  color: var(--semi-color-text-0);
}
.semi-checkbox-disabled {
  cursor: not-allowed;
}
.semi-checkbox-disabled .semi-checkbox-inner {
  cursor: not-allowed;
}
.semi-checkbox-disabled .semi-checkbox-inner-display {
  color: var(--semi-color-white);
  background: var(--semi-color-disabled-fill);
  box-shadow: inset 0 0 0 1px var(--semi-color-border);
}
.semi-checkbox-disabled .semi-checkbox-inner-display:hover {
  color: var(--semi-color-white);
  background: transparent;
}
.semi-checkbox-disabled .semi-checkbox-inner-checked {
  color: var(--semi-color-white);
}
.semi-checkbox-disabled .semi-checkbox-inner-checked .semi-checkbox-inner-display {
  opacity: 0.75;
  background: var(--semi-color-primary-disabled);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary-disabled);
}
.semi-checkbox-disabled .semi-checkbox-inner-checked .semi-checkbox-inner-display:hover {
  color: var(--semi-color-white);
  background: var(--semi-color-primary-disabled);
}
.semi-checkbox-disabled .semi-checkbox-addon {
  color: var(--semi-color-disabled-text);
}
.semi-checkbox-disabled .semi-checkbox-extra {
  color: var(--semi-color-disabled-text);
}
.semi-checkbox.semi-checkbox-disabled.semi-checkbox-indeterminate .semi-checkbox-inner-display {
  opacity: 0.75;
  background: var(--semi-color-primary-disabled);
  box-shadow: inset 0 0 0 1px var(--semi-color-primary-disabled);
  color: var(--semi-color-white);
}
.semi-checkbox-extra {
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 100%;
  box-sizing: border-box;
  padding-left: 24px;
  color: var(--semi-color-text-2);
  margin-top: 4px;
}
.semi-checkbox-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-checkbox-focus-border {
  box-shadow: inset 0 0 0 1px var(--semi-color-focus-border);
}

.semi-checkboxGroup {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 14px;
}
.semi-checkboxGroup .semi-checkbox.semi-checkbox-vertical {
  margin-bottom: 16px;
}
.semi-checkboxGroup-horizontal .semi-checkbox {
  margin-right: 16px;
  display: inline-flex;
}
.semi-checkboxGroup-horizontal .semi-checkbox:last-of-type {
  margin-right: 0;
}
.semi-checkboxGroup-vertical .semi-checkbox {
  margin-bottom: 12px;
}
.semi-checkboxGroup-vertical .semi-checkbox:last-of-type {
  margin-bottom: 0;
}
.semi-checkboxGroup-vertical-cardType .semi-checkbox {
  margin-bottom: 16px;
}

.semi-rtl .semi-checkbox,
.semi-portal-rtl .semi-checkbox {
  direction: rtl;
}
.semi-rtl .semi-checkbox input[type=checkbox],
.semi-portal-rtl .semi-checkbox input[type=checkbox] {
  left: auto;
  right: 0;
}
.semi-rtl .semi-checkbox-addon,
.semi-portal-rtl .semi-checkbox-addon {
  padding-left: 0;
  padding-right: 8px;
}
.semi-rtl .semi-checkbox-extra,
.semi-portal-rtl .semi-checkbox-extra {
  padding-left: 0;
  padding-right: 24px;
}
.semi-rtl .semi-checkbox-cardType .semi-checkbox-inner,
.semi-portal-rtl .semi-checkbox-cardType .semi-checkbox-inner {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-checkbox-cardType .semi-checkbox-addon,
.semi-portal-rtl .semi-checkbox-cardType .semi-checkbox-addon {
  margin-right: 0;
  padding-right: 0;
}
.semi-rtl .semi-checkbox-cardType .semi-checkbox-extra,
.semi-portal-rtl .semi-checkbox-cardType .semi-checkbox-extra {
  padding-right: 0;
}
.semi-rtl .semi-checkboxGroup,
.semi-portal-rtl .semi-checkboxGroup {
  direction: rtl;
}
.semi-rtl .semi-checkboxGroup-horizontal .semi-checkbox,
.semi-portal-rtl .semi-checkboxGroup-horizontal .semi-checkbox {
  margin-right: 0;
  margin-left: 16px;
}
.semi-rtl .semi-checkboxGroup-horizontal .semi-checkbox:last-of-type,
.semi-portal-rtl .semi-checkboxGroup-horizontal .semi-checkbox:last-of-type {
  margin-left: 0;
}