/* shadow */
/* sizing */
/* spacing */
.semi-tabs {
  box-sizing: border-box;
  position: relative;
}
.semi-tabs-left {
  display: flex;
  flex-direction: row;
}
.semi-tabs-bar {
  position: relative;
  white-space: nowrap;
  outline: none;
}
.semi-tabs-bar-left {
  display: flex;
  flex-direction: column;
}
.semi-tabs-bar-extra {
  padding: 0px 5px;
}
.semi-tabs-bar .semi-tabs-tab {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  font-weight: 400;
  color: var(--semi-color-text-2);
  user-select: none;
}
.semi-tabs-bar .semi-tabs-tab .semi-icon {
  position: relative;
  margin-right: 8px;
  top: 3px;
  color: var(--semi-color-text-2);
}
.semi-tabs-bar .semi-tabs-tab .semi-icon-close {
  margin-right: 0;
  font-size: 14px;
  color: var(--semi-color-text-2);
  margin-left: 10px;
  cursor: pointer;
}
.semi-tabs-bar .semi-tabs-tab:hover {
  color: var(--semi-color-text-0);
}
.semi-tabs-bar .semi-tabs-tab:hover .semi-icon {
  color: var(--semi-color-text-0);
}
.semi-tabs-bar .semi-tabs-tab:active {
  color: var(--semi-color-text-0);
}
.semi-tabs-bar .semi-tabs-tab:active .semi-icon {
  color: var(--semi-color-text-0);
}
.semi-tabs-bar .semi-tabs-tab-active, .semi-tabs-bar .semi-tabs-tab-active:hover {
  cursor: default;
  font-weight: 600;
  color: var(--semi-color-text-0);
}
.semi-tabs-bar .semi-tabs-tab-active .semi-icon, .semi-tabs-bar .semi-tabs-tab-active:hover .semi-icon {
  color: var(--semi-color-primary);
}
.semi-tabs-bar .semi-tabs-tab-active .semi-icon-close, .semi-tabs-bar .semi-tabs-tab-active:hover .semi-icon-close {
  color: var(--semi-color-text-2);
}
.semi-tabs-bar .semi-tabs-tab-active .semi-icon-close:hover {
  color: var(--semi-color-text-1);
}
.semi-tabs-bar .semi-tabs-tab-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}
.semi-tabs-bar .semi-tabs-tab-disabled:hover {
  color: var(--semi-color-disabled-text);
  border-bottom: none;
}
.semi-tabs-bar-collapse,
.semi-tabs-bar-collapse .semi-tabs-bar-overflow-list {
  display: flex;
  align-items: center;
}
.semi-tabs-bar-collapse .semi-overflow-list {
  flex: 1;
}
.semi-tabs-bar-collapse .semi-overflow-list .semi-overflow-list-scroll-wrapper {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.semi-tabs-bar-collapse .semi-overflow-list .semi-overflow-list-scroll-wrapper::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
  width: 0;
  height: 0;
}
.semi-tabs-bar-collapse .semi-overflow-list .semi-overflow-list-scroll-wrapper:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}
.semi-tabs-bar-collapse .semi-tabs-bar-arrow-start {
  margin-right: 4px;
}
.semi-tabs-bar-collapse .semi-tabs-bar-arrow-end {
  margin-left: 4px;
}
.semi-tabs-bar-dropdown {
  max-height: 300px;
  overflow-y: auto;
}
.semi-tabs-bar:after {
  content: "";
  height: 0;
  display: block;
  clear: both;
}
.semi-tabs-bar-line.semi-tabs-bar-top {
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab {
  padding: 16px 4px 14px 4px;
  border-bottom: 2px solid transparent;
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:nth-of-type(1) {
  padding-left: 0;
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:hover {
  border-bottom: 2px solid var(--semi-color-fill-0);
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -1px;
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:active {
  border-bottom: 2px solid var(--semi-color-fill-1);
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: 24px;
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-small {
  padding: 8px 4px 6px 4px;
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-medium {
  padding: 12px 4px 10px 4px;
}
.semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-active, .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab-active:hover {
  border-bottom: 2px solid var(--semi-color-primary);
}
.semi-tabs-bar-line.semi-tabs-bar-left {
  border-right: 1px solid var(--semi-color-border);
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab {
  padding: 12px;
  border-left: 2px solid transparent;
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-left: 2px solid var(--semi-color-fill-0);
  background-color: var(--semi-color-fill-0);
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:active {
  border-left: 2px solid var(--semi-color-fill-1);
  background-color: var(--semi-color-fill-1);
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-small {
  padding: 6px;
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-medium {
  padding: 10px;
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active, .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active:hover {
  border-left: 2px solid var(--semi-color-primary);
  background-color: var(--semi-color-primary-light-default);
}
.semi-tabs-bar-line .semi-tabs-bar-extra {
  height: 50px;
  line-height: 50px;
}
.semi-tabs-bar-line .semi-tabs-bar-line-extra-small {
  height: 36px;
  line-height: 36px;
}
.semi-tabs-bar-card.semi-tabs-bar-top::before {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid var(--semi-color-border);
  content: "";
}
.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab {
  border: 1px solid transparent;
  border-bottom: none;
  border-radius: var(--semi-border-radius-small) var(--semi-border-radius-small) 0 0;
}
.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:hover {
  border-bottom: none;
}
.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: 8px;
}
.semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab-active, .semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab-active:hover {
  padding: 8px 12px 7px 12px;
  border: 1px solid var(--semi-color-border);
  border-bottom: 1px solid var(--semi-color-bg-1);
  background: transparent;
}
.semi-tabs-bar-card.semi-tabs-bar-left {
  border-right: 1px solid var(--semi-color-border);
}
.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab {
  border: 1px solid transparent;
  border-right: none;
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-right: none;
}
.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:not(:last-of-type) {
  margin-bottom: 8px;
}
.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:after {
  content: " ";
  width: 1px;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  background: var(--semi-color-bg-1);
}
.semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active, .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:hover {
  padding: 8px 12px;
  border: 1px solid var(--semi-color-border);
  border-right: none;
  background: transparent;
}
.semi-tabs-bar-card .semi-tabs-tab {
  padding: 8px 12px;
}
.semi-tabs-bar-card .semi-tabs-tab:hover {
  background: var(--semi-color-fill-0);
}
.semi-tabs-bar-card .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}
.semi-tabs-bar-card .semi-tabs-tab:active {
  background: var(--semi-color-fill-1);
}
.semi-tabs-bar-button {
  border: none;
}
.semi-tabs-bar-button.semi-tabs-bar-left .semi-tabs-tab:not(:last-of-type) {
  margin-bottom: 8px;
}
.semi-tabs-bar-button.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: 8px;
}
.semi-tabs-bar-button .semi-tabs-tab {
  padding: 8px 12px;
  border-radius: var(--semi-border-radius-small);
  color: var(--semi-color-text-2);
  border: none;
}
.semi-tabs-bar-button .semi-tabs-tab:hover {
  border: none;
  background-color: var(--semi-color-fill-0);
}
.semi-tabs-bar-button .semi-tabs-tab:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}
.semi-tabs-bar-button .semi-tabs-tab:active {
  background-color: var(--semi-color-fill-1);
}
.semi-tabs-bar-button .semi-tabs-tab-active, .semi-tabs-bar-button .semi-tabs-tab-active:hover {
  color: var(--semi-color-primary);
  border: none;
  background-color: var(--semi-color-primary-light-default);
}
.semi-tabs-content {
  width: 100%;
  padding: 5px 0;
}
.semi-tabs-content-left {
  height: 100%;
  padding: 0 5px;
}
.semi-tabs-pane {
  width: 100%;
  overflow: hidden;
  color: var(--semi-color-text-0);
}
.semi-tabs-pane:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-tabs-pane-inactive, .semi-tabs-content-no-animated .semi-tabs-pane-inactive {
  display: none;
}

.semi-rtl .semi-tabs,
.semi-portal-rtl .semi-tabs {
  direction: rtl;
}
.semi-rtl .semi-tabs-bar .semi-tabs-tab,
.semi-portal-rtl .semi-tabs-bar .semi-tabs-tab {
  float: right;
}
.semi-rtl .semi-tabs-bar .semi-tabs-tab .semi-icon,
.semi-portal-rtl .semi-tabs-bar .semi-tabs-tab .semi-icon {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-start,
.semi-portal-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-start {
  margin-right: 0;
  margin-left: 4px;
}
.semi-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-end,
.semi-portal-rtl .semi-tabs-bar-collapse .semi-tabs-bar-arrow-end {
  margin-left: 0;
  margin-right: 4px;
}
.semi-rtl .semi-tabs-bar-collapse .semi-icon-chevron_right,
.semi-rtl .semi-tabs-bar-collapse .semi-icon-chevron_left,
.semi-portal-rtl .semi-tabs-bar-collapse .semi-icon-chevron_right,
.semi-portal-rtl .semi-tabs-bar-collapse .semi-icon-chevron_left {
  transform: scaleX(-1);
}
.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type),
.semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: 0;
  margin-left: 24px;
}
.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left,
.semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab,
.semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab {
  border-left: 0;
  border-right: 2px solid transparent;
}
.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:hover,
.semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-left: 0;
  border-right: 2px solid var(--semi-color-fill-0);
}
.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:active,
.semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab:active {
  border-left: 0;
  border-right: 2px solid var(--semi-color-fill-1);
}
.semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active, .semi-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active:hover,
.semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active,
.semi-portal-rtl .semi-tabs-bar-line.semi-tabs-bar-left .semi-tabs-tab-active:hover {
  border-left: 0;
  border-right: 2px solid var(--semi-color-primary);
}
.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type),
.semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-left: 0;
  margin-right: 8px;
}
.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left,
.semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab,
.semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab {
  border: 1px solid transparent;
  border-left: none;
}
.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:hover,
.semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab:hover {
  border-left: none;
}
.semi-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:after,
.semi-portal-rtl .semi-tabs-bar-card.semi-tabs-bar-left .semi-tabs-tab-active:after {
  right: auto;
  left: -1px;
}
.semi-rtl .semi-tabs-bar-button.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type),
.semi-portal-rtl .semi-tabs-bar-button.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type) {
  margin-right: auto;
  margin-left: 8px;
}