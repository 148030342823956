/* shadow */
/* sizing */
/* spacing */
@keyframes semi-tooltip-zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  50% {
    opacity: 1;
  }
}
@keyframes semi-tooltip-bounceIn {
  from {
    opacity: 0;
    transform: scale(0.6, 0.6);
  }
  70% {
    opacity: 1;
    transform: scale(1.01, 1.01);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
@keyframes semi-tooltip-zoomOut {
  from {
    opacity: 1;
  }
  60% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
  }
}
.semi-tooltip-wrapper {
  position: relative;
  background-color: rgba(var(--semi-grey-7), 1);
  color: var(--semi-color-bg-0);
  border-radius: var(--semi-border-radius-medium);
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-size: 14px;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  visibility: hidden;
  max-width: 240px;
}
.semi-tooltip-wrapper-show {
  visibility: visible;
}
.semi-tooltip-trigger {
  display: inline-block;
  width: auto;
  height: auto;
}
.semi-tooltip-with-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.semi-tooltip-wrapper .semi-tooltip-icon-arrow {
  height: 7px;
  width: 24px;
  position: absolute;
  color: rgba(var(--semi-grey-7), 1);
}
.semi-tooltip-wrapper[x-placement=top] .semi-tooltip-icon-arrow {
  left: 50%;
  transform: translateX(-50%);
  bottom: -6px;
}
.semi-tooltip-wrapper[x-placement=top].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=top] .semi-tooltip-with-arrow {
  min-width: 36px;
}
.semi-tooltip-wrapper[x-placement=topLeft] .semi-tooltip-icon-arrow {
  bottom: -6px;
  left: 6px;
}
.semi-tooltip-wrapper[x-placement=topLeft].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=topLeft] .semi-tooltip-with-arrow {
  min-width: 36px;
}
.semi-tooltip-wrapper[x-placement=topRight] .semi-tooltip-icon-arrow {
  bottom: -6px;
  right: 6px;
}
.semi-tooltip-wrapper[x-placement=topRight].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=topRight] .semi-tooltip-with-arrow {
  min-width: 36px;
}
.semi-tooltip-wrapper[x-placement=leftTop] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  right: -6px;
  top: 5px;
}
.semi-tooltip-wrapper[x-placement=leftTop].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=leftTop] .semi-tooltip-with-arrow {
  min-height: 34px;
}
.semi-tooltip-wrapper[x-placement=left] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
}
.semi-tooltip-wrapper[x-placement=left].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=left] .semi-tooltip-with-arrow {
  min-height: 34px;
}
.semi-tooltip-wrapper[x-placement=leftBottom] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  right: -6px;
  bottom: 5px;
}
.semi-tooltip-wrapper[x-placement=leftBottom].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=leftBottom] .semi-tooltip-with-arrow {
  min-height: 34px;
}
.semi-tooltip-wrapper[x-placement=rightTop] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  left: -6px;
  top: 5px;
  transform: rotate(180deg);
}
.semi-tooltip-wrapper[x-placement=rightTop].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=rightTop] .semi-tooltip-with-arrow {
  min-height: 34px;
}
.semi-tooltip-wrapper[x-placement=right] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  left: -6px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
.semi-tooltip-wrapper[x-placement=right].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=right] .semi-tooltip-with-arrow {
  min-height: 34px;
}
.semi-tooltip-wrapper[x-placement=rightBottom] .semi-tooltip-icon-arrow {
  width: 7px;
  height: 24px;
  left: -6px;
  bottom: 5px;
  transform: rotate(180deg);
}
.semi-tooltip-wrapper[x-placement=rightBottom].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=rightBottom] .semi-tooltip-with-arrow {
  min-height: 34px;
}
.semi-tooltip-wrapper[x-placement=bottomLeft] .semi-tooltip-icon-arrow {
  top: -6px;
  left: 6px;
  transform: rotate(180deg);
}
.semi-tooltip-wrapper[x-placement=bottomLeft].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=bottomLeft] .semi-tooltip-with-arrow {
  min-width: 36px;
}
.semi-tooltip-wrapper[x-placement=bottom] .semi-tooltip-icon-arrow {
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
.semi-tooltip-wrapper[x-placement=bottom].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=bottom] .semi-tooltip-with-arrow {
  min-width: 36px;
}
.semi-tooltip-wrapper[x-placement=bottomRight] .semi-tooltip-icon-arrow {
  right: 6px;
  top: -6px;
  transform: rotate(180deg);
}
.semi-tooltip-wrapper[x-placement=bottomRight].semi-tooltip-with-arrow,
.semi-tooltip-wrapper[x-placement=bottomRight] .semi-tooltip-with-arrow {
  min-width: 36px;
}

.semi-rtl .semi-tooltip-wrapper,
.semi-portal-rtl .semi-tooltip-wrapper {
  direction: rtl;
  padding-right: 12px;
  padding-left: 12px;
  left: auto;
  right: 0;
}