/* shadow */
/* sizing */
/* spacing */
.semi-avatar {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
}
.semi-avatar:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-avatar-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-avatar-no-focus-visible:focus-visible {
  outline: none;
}
.semi-avatar .semi-avatar-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}
.semi-avatar-content {
  user-select: none;
}
.semi-avatar-extra-extra-small {
  width: 20px;
  height: 20px;
}
.semi-avatar-extra-extra-small .semi-avatar-content {
  transform-origin: center;
  transform: scale(0.8);
}
.semi-avatar-extra-extra-small .semi-avatar-label {
  font-size: 10px;
  line-height: 15px;
}
.semi-avatar-extra-small {
  width: 24px;
  height: 24px;
}
.semi-avatar-extra-small .semi-avatar-content {
  transform-origin: center;
  transform: scale(0.8);
}
.semi-avatar-extra-small .semi-avatar-label {
  font-size: 10px;
  line-height: 15px;
}
.semi-avatar-small {
  width: 32px;
  height: 32px;
}
.semi-avatar-small .semi-avatar-label {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-avatar-default {
  width: 40px;
  height: 40px;
}
.semi-avatar-default .semi-avatar-label {
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-avatar-medium {
  width: 48px;
  height: 48px;
}
.semi-avatar-medium .semi-avatar-label {
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-avatar-large {
  width: 72px;
  height: 72px;
}
.semi-avatar-large .semi-avatar-label {
  font-size: 32px;
  line-height: 44px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-avatar-extra-large {
  width: 128px;
  height: 128px;
}
.semi-avatar-extra-large .semi-avatar-label {
  font-size: 64px;
  line-height: 77px;
}
.semi-avatar-circle {
  border-radius: var(--semi-border-radius-circle);
}
.semi-avatar-image {
  background-color: transparent;
}
.semi-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.semi-avatar-hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.semi-avatar:hover {
  cursor: pointer;
}

.semi-avatar-group {
  display: inline-block;
}
.semi-avatar-group .semi-avatar {
  box-sizing: border-box;
}
.semi-avatar-group .semi-avatar:first-child {
  margin-left: 0;
}
.semi-avatar-group .semi-avatar-extra-large {
  border: 3px var(--semi-color-bg-1) solid;
  margin-left: -32px;
}
.semi-avatar-group .semi-avatar-large {
  border: 3px var(--semi-color-bg-1) solid;
  margin-left: -18px;
}
.semi-avatar-group .semi-avatar-medium {
  border: 2px var(--semi-color-bg-1) solid;
  margin-left: -12px;
}
.semi-avatar-group .semi-avatar-default {
  border: 2px var(--semi-color-bg-1) solid;
  margin-left: -12px;
}
.semi-avatar-group .semi-avatar-small {
  border: 2px var(--semi-color-bg-1) solid;
  margin-left: -12px;
}
.semi-avatar-group .semi-avatar-extra-small {
  border: 1px var(--semi-color-bg-1) solid;
  margin-left: -10px;
}
.semi-avatar-group .semi-avatar-extra-extra-small {
  border: 1px var(--semi-color-bg-1) solid;
  margin-left: -4px;
}
.semi-avatar-group .semi-avatar-item-start-0 {
  z-index: 100;
}
.semi-avatar-group .semi-avatar-item-end-0 {
  z-index: 80;
}
.semi-avatar-group .semi-avatar-item-start-1 {
  z-index: 99;
}
.semi-avatar-group .semi-avatar-item-end-1 {
  z-index: 81;
}
.semi-avatar-group .semi-avatar-item-start-2 {
  z-index: 98;
}
.semi-avatar-group .semi-avatar-item-end-2 {
  z-index: 82;
}
.semi-avatar-group .semi-avatar-item-start-3 {
  z-index: 97;
}
.semi-avatar-group .semi-avatar-item-end-3 {
  z-index: 83;
}
.semi-avatar-group .semi-avatar-item-start-4 {
  z-index: 96;
}
.semi-avatar-group .semi-avatar-item-end-4 {
  z-index: 84;
}
.semi-avatar-group .semi-avatar-item-start-5 {
  z-index: 95;
}
.semi-avatar-group .semi-avatar-item-end-5 {
  z-index: 85;
}
.semi-avatar-group .semi-avatar-item-start-6 {
  z-index: 94;
}
.semi-avatar-group .semi-avatar-item-end-6 {
  z-index: 86;
}
.semi-avatar-group .semi-avatar-item-start-7 {
  z-index: 93;
}
.semi-avatar-group .semi-avatar-item-end-7 {
  z-index: 87;
}
.semi-avatar-group .semi-avatar-item-start-8 {
  z-index: 92;
}
.semi-avatar-group .semi-avatar-item-end-8 {
  z-index: 88;
}
.semi-avatar-group .semi-avatar-item-start-9 {
  z-index: 91;
}
.semi-avatar-group .semi-avatar-item-end-9 {
  z-index: 89;
}
.semi-avatar-group .semi-avatar-item-start-10 {
  z-index: 90;
}
.semi-avatar-group .semi-avatar-item-end-10 {
  z-index: 90;
}
.semi-avatar-group .semi-avatar-item-start-11 {
  z-index: 89;
}
.semi-avatar-group .semi-avatar-item-end-11 {
  z-index: 91;
}
.semi-avatar-group .semi-avatar-item-start-12 {
  z-index: 88;
}
.semi-avatar-group .semi-avatar-item-end-12 {
  z-index: 92;
}
.semi-avatar-group .semi-avatar-item-start-13 {
  z-index: 87;
}
.semi-avatar-group .semi-avatar-item-end-13 {
  z-index: 93;
}
.semi-avatar-group .semi-avatar-item-start-14 {
  z-index: 86;
}
.semi-avatar-group .semi-avatar-item-end-14 {
  z-index: 94;
}
.semi-avatar-group .semi-avatar-item-start-15 {
  z-index: 85;
}
.semi-avatar-group .semi-avatar-item-end-15 {
  z-index: 95;
}
.semi-avatar-group .semi-avatar-item-start-16 {
  z-index: 84;
}
.semi-avatar-group .semi-avatar-item-end-16 {
  z-index: 96;
}
.semi-avatar-group .semi-avatar-item-start-17 {
  z-index: 83;
}
.semi-avatar-group .semi-avatar-item-end-17 {
  z-index: 97;
}
.semi-avatar-group .semi-avatar-item-start-18 {
  z-index: 82;
}
.semi-avatar-group .semi-avatar-item-end-18 {
  z-index: 98;
}
.semi-avatar-group .semi-avatar-item-start-19 {
  z-index: 81;
}
.semi-avatar-group .semi-avatar-item-end-19 {
  z-index: 99;
}
.semi-avatar-group .semi-avatar-item-start-20 {
  z-index: 80;
}
.semi-avatar-group .semi-avatar-item-end-20 {
  z-index: 100;
}
.semi-avatar-group .semi-avatar-item-more {
  background-color: rgba(var(--semi-grey-5), 1);
}

.semi-avatar-amber {
  background-color: rgba(var(--semi-amber-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-blue {
  background-color: rgba(var(--semi-blue-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-cyan {
  background-color: rgba(var(--semi-cyan-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-green {
  background-color: rgba(var(--semi-green-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-grey {
  background-color: rgba(var(--semi-grey-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-indigo {
  background-color: rgba(var(--semi-indigo-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-light-blue {
  background-color: rgba(var(--semi-light-blue-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-light-green {
  background-color: rgba(var(--semi-light-green-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-lime {
  background-color: rgba(var(--semi-lime-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-orange {
  background-color: rgba(var(--semi-orange-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-pink {
  background-color: rgba(var(--semi-pink-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-purple {
  background-color: rgba(var(--semi-purple-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-red {
  background-color: rgba(var(--semi-red-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-teal {
  background-color: rgba(var(--semi-teal-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-violet {
  background-color: rgba(var(--semi-violet-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-avatar-yellow {
  background-color: rgba(var(--semi-yellow-3), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-rtl .semi-avatar,
.semi-portal-rtl .semi-avatar {
  direction: rtl;
}
.semi-rtl .semi-avatar-extra-extra-small .semi-avatar-content,
.semi-portal-rtl .semi-avatar-extra-extra-small .semi-avatar-content {
  transform: scale(0.8);
}
.semi-rtl .semi-avatar-extra-small .semi-avatar-content,
.semi-portal-rtl .semi-avatar-extra-small .semi-avatar-content {
  transform: scale(0.8);
}
.semi-rtl .semi-avatar-hover,
.semi-portal-rtl .semi-avatar-hover {
  left: auto;
  right: 0;
}
.semi-rtl .semi-avatar-group,
.semi-portal-rtl .semi-avatar-group {
  direction: rtl;
}
.semi-rtl .semi-avatar-group .semi-avatar:first-child,
.semi-portal-rtl .semi-avatar-group .semi-avatar:first-child {
  margin-left: auto;
  margin-right: 0;
}
.semi-rtl .semi-avatar-group .semi-avatar-extra-large,
.semi-portal-rtl .semi-avatar-group .semi-avatar-extra-large {
  margin-left: auto;
  margin-right: -32px;
}
.semi-rtl .semi-avatar-group .semi-avatar-large,
.semi-portal-rtl .semi-avatar-group .semi-avatar-large {
  margin-left: auto;
  margin-right: -18px;
}
.semi-rtl .semi-avatar-group .semi-avatar-medium,
.semi-rtl .semi-avatar-group .semi-avatar-small,
.semi-portal-rtl .semi-avatar-group .semi-avatar-medium,
.semi-portal-rtl .semi-avatar-group .semi-avatar-small {
  margin-left: auto;
  margin-right: -12px;
}
.semi-rtl .semi-avatar-group .semi-avatar-extra-small,
.semi-portal-rtl .semi-avatar-group .semi-avatar-extra-small {
  margin-left: auto;
  margin-right: -10px;
}
.semi-rtl .semi-avatar-group .semi-avatar-extra-extra-small,
.semi-portal-rtl .semi-avatar-group .semi-avatar-extra-extra-small {
  margin-left: auto;
  margin-right: -4px;
}