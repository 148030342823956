/* shadow */
/* sizing */
/* spacing */
.semi-list {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.semi-list-header, .semi-list-footer {
  padding: 12px 24px;
}
.semi-list-empty {
  padding: 12px 0px;
  color: var(--semi-color-text-2);
  width: 100%;
  text-align: center;
}
.semi-list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 24px;
  margin: 0;
  list-style: none;
}
.semi-list-item-body {
  flex: 1;
  display: flex;
}
.semi-list-item-body-header {
  margin-right: 20px;
}
.semi-list-item-body-flex-start {
  align-items: flex-start;
}
.semi-list-item-body-flex-end {
  align-items: flex-end;
}
.semi-list-item-body-center {
  align-items: center;
}
.semi-list-item-body-stretch {
  align-items: stretch;
}
.semi-list-item-body-baseline {
  align-items: baseline;
}
.semi-list-item-extra {
  flex: 0 0 auto;
  margin-left: 40px;
}
.semi-list-split .semi-list-item {
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-list-split .semi-list-item:last-child {
  border-bottom: none;
}
.semi-list-split .semi-list-header {
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-list-split .semi-list-footer {
  border-top: 1px solid var(--semi-color-border);
}
.semi-list-small .semi-list-item, .semi-list-small .semi-list-header, .semi-list-small .semi-list-footer {
  padding: 8px 16px;
}
.semi-list-large .semi-list-item, .semi-list-large .semi-list-header, .semi-list-large .semi-list-footer {
  padding: 16px 24px;
}
.semi-list.semi-list-grid .semi-list-item {
  padding: 0;
}
.semi-list.semi-list-bordered {
  border: 1px solid var(--semi-color-border);
}
.semi-list.semi-list-flex .semi-list-items {
  display: flex;
}
.semi-list.semi-list-flex.semi-list-split .semi-list-item {
  border-bottom: none;
  border-right: 1px solid var(--semi-color-border);
}
.semi-list.semi-list-flex.semi-list-split .semi-list-item:last-child {
  border-right: none;
}

.semi-rtl .semi-list,
.semi-portal-rtl .semi-list {
  direction: rtl;
}
.semi-rtl .semi-list-item,
.semi-portal-rtl .semi-list-item {
  direction: rtl;
}
.semi-rtl .semi-list-item-body-header,
.semi-portal-rtl .semi-list-item-body-header {
  margin-right: auto;
  margin-left: 20px;
}
.semi-rtl .semi-list-item-extra,
.semi-portal-rtl .semi-list-item-extra {
  flex: 0 0 auto;
  margin-left: 0;
  margin-right: 40px;
}
.semi-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item,
.semi-portal-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item {
  border-bottom: none;
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item:last-child,
.semi-portal-rtl .semi-list.semi-list-flex.semi-list-split .semi-list-item:last-child {
  border-left: none;
}