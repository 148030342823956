/* shadow */
/* sizing */
/* spacing */
.semi-button-split {
  display: inline-block;
}
.semi-button-split .semi-button {
  border-radius: 0;
  margin-right: 1px;
}
.semi-button-split :first-child {
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}
.semi-button-split :last-child {
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
  margin-right: unset;
}
.semi-button-split:hover .semi-button-borderless:active {
  background-color: var(--semi-color-fill-1);
}

.semi-button {
  box-shadow: none;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  user-select: none;
  border: 0 transparent solid;
  border-radius: var(--semi-border-radius-small);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 600;
  outline: none;
  vertical-align: middle;
  white-space: nowrap;
}
.semi-button.semi-button-primary:focus-visible, .semi-button.semi-button-secondary:focus-visible, .semi-button.semi-button-tertiary:focus-visible, .semi-button.semi-button-warning:focus-visible, .semi-button.semi-button-danger:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-button-danger {
  background-color: var(--semi-color-danger);
  color: rgba(var(--semi-white), 1);
}
.semi-button-danger:hover {
  background-color: var(--semi-color-danger-hover);
}
.semi-button-danger:active {
  background-color: var(--semi-color-danger-active);
}
.semi-button-danger.semi-button-light, .semi-button-danger.semi-button-borderless {
  color: var(--semi-color-danger);
}
.semi-button-danger:not(.semi-button-borderless):not(.semi-button-light):focus-visible {
  outline: 2px solid var(--semi-color-danger-light-active);
}
.semi-button-warning {
  background-color: var(--semi-color-warning);
  color: rgba(var(--semi-white), 1);
}
.semi-button-warning:hover {
  background-color: var(--semi-color-warning-hover);
}
.semi-button-warning:active {
  background-color: var(--semi-color-warning-active);
}
.semi-button-warning.semi-button-light, .semi-button-warning.semi-button-borderless {
  color: var(--semi-color-warning);
}
.semi-button-warning:not(.semi-button-borderless):not(.semi-button-light):focus-visible {
  outline: 2px solid var(--semi-color-warning-light-active);
}
.semi-button-tertiary {
  background-color: var(--semi-color-tertiary);
  color: rgba(var(--semi-white), 1);
}
.semi-button-tertiary:hover {
  background-color: var(--semi-color-tertiary-hover);
}
.semi-button-tertiary:active {
  background-color: var(--semi-color-tertiary-active);
}
.semi-button-tertiary.semi-button-light, .semi-button-tertiary.semi-button-borderless {
  color: var(--semi-color-text-1);
}
.semi-button-primary {
  background-color: var(--semi-color-primary);
  color: rgba(var(--semi-white), 1);
}
.semi-button-primary:not(.semi-button-borderless):not(.semi-button-light):hover {
  background-color: var(--semi-color-primary-hover);
}
.semi-button-primary:not(.semi-button-borderless):not(.semi-button-light):active {
  background-color: var(--semi-color-primary-active);
}
.semi-button-primary.semi-button-light, .semi-button-primary.semi-button-borderless {
  color: var(--semi-color-primary);
}
.semi-button-secondary {
  background-color: var(--semi-color-secondary);
  outline-color: var(--semi-color-secondary);
  color: rgba(var(--semi-white), 1);
}
.semi-button-secondary:hover {
  background-color: var(--semi-color-secondary-hover);
}
.semi-button-secondary:active {
  background-color: var(--semi-color-secondary-active);
}
.semi-button-secondary.semi-button-light, .semi-button-secondary.semi-button-borderless {
  color: var(--semi-color-secondary);
}
.semi-button-disabled {
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-bg);
  cursor: not-allowed;
}
.semi-button-disabled:not(.semi-button-borderless):not(.semi-button-light):hover {
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-bg);
}
.semi-button-disabled.semi-button-light, .semi-button-disabled.semi-button-borderless {
  color: var(--semi-color-disabled-text);
}
.semi-button-borderless {
  background-color: transparent;
  border: 0 transparent solid;
}
.semi-button-borderless:not(.semi-button-disabled):hover {
  background-color: var(--semi-color-fill-0);
  border: 0 transparent solid;
}
.semi-button-borderless:not(.semi-button-disabled):active {
  background-color: var(--semi-color-fill-1);
  border: 0 transparent solid;
}
.semi-button-light {
  background-color: var(--semi-color-fill-0);
  border: 0 transparent solid;
}
.semi-button-light:not(.semi-button-disabled):hover {
  background-color: var(--semi-color-fill-1);
  border: 0 transparent solid;
}
.semi-button-light:not(.semi-button-disabled):active {
  background-color: var(--semi-color-fill-2);
  border: 0 transparent solid;
}
.semi-button-size-small {
  height: 24px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
}
.semi-button-size-large {
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
}
.semi-button-block {
  width: 100%;
}
.semi-button-group > .semi-button {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.semi-button-group > .semi-button .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-button-group > .semi-button-size-large .semi-button-content {
  padding-left: 16px;
  padding-right: 16px;
}
.semi-button-group > .semi-button-size-small .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-button-group > .semi-button.semi-button-with-icon-only {
  padding-left: 0;
  padding-right: 0;
}
.semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content {
  padding-left: 8px;
  padding-right: 8px;
}
.semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content {
  padding-left: 4px;
  padding-right: 4px;
}
.semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-button-group > .semi-button:first-child {
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}
.semi-button-group > .semi-button:not(:last-child) .semi-button-content {
  border-right: 1px var(--semi-color-border) solid;
}
.semi-button-group > .semi-button:last-child {
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
}

.semi-rtl .semi-button,
.semi-portal-rtl .semi-button {
  direction: rtl;
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-size-small,
.semi-portal-rtl .semi-button-size-small {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-size-large,
.semi-portal-rtl .semi-button-size-large {
  padding-left: 16px;
  padding-right: 16px;
}
.semi-rtl .semi-button-group,
.semi-portal-rtl .semi-button-group {
  direction: rtl;
}
.semi-rtl .semi-button-group > .semi-button,
.semi-portal-rtl .semi-button-group > .semi-button {
  padding-left: 0;
  padding-right: 0;
}
.semi-rtl .semi-button-group > .semi-button .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-group > .semi-button-size-large .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button-size-large .semi-button-content {
  padding-left: 16px;
  padding-right: 16px;
}
.semi-rtl .semi-button-group > .semi-button-size-small .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button-size-small .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only {
  padding-left: 0;
  padding-right: 0;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only .semi-button-content {
  padding-left: 8px;
  padding-right: 8px;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-small .semi-button-content {
  padding-left: 4px;
  padding-right: 4px;
}
.semi-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button.semi-button-with-icon-only.semi-button-size-large .semi-button-content {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-group > .semi-button:first-child,
.semi-portal-rtl .semi-button-group > .semi-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--semi-border-radius-small);
  border-bottom-right-radius: var(--semi-border-radius-small);
}
.semi-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content,
.semi-portal-rtl .semi-button-group > .semi-button:not(:last-child) .semi-button-content {
  border-left: 1px var(--semi-color-border) solid;
  border-right: 0;
}
.semi-rtl .semi-button-group > .semi-button:last-child,
.semi-portal-rtl .semi-button-group > .semi-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--semi-border-radius-small);
  border-bottom-left-radius: var(--semi-border-radius-small);
}
.semi-rtl .semi-button.semi-button-with-icon-only,
.semi-portal-rtl .semi-button.semi-button-with-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}
.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small,
.semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-small {
  padding-left: 4px;
  padding-right: 4px;
}
.semi-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large,
.semi-portal-rtl .semi-button.semi-button-with-icon-only.semi-button-size-large {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-button-content-left,
.semi-portal-rtl .semi-button-content-left {
  margin-left: 8px;
  margin-right: 0;
}
.semi-rtl .semi-button-content-right,
.semi-portal-rtl .semi-button-content-right {
  margin-right: 8px;
  margin-left: 0;
}